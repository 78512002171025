'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing DeletePromptView');
    },

    events: {
        "click": "clicked",
        "click #allowedButton": "userClickedAllowed",
        "click #cancelButton": "userClickedCancel"
    },

    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#deletePrompt-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        $(function () {
            //Handling 'enter' & ESC keypress
            $(self.el).keydown(function (e) {
                if (e.keyCode == 13) {
                    self.hitEnterKey(e);
                    return false;
                }
            });
            $(self.el).keyup(function (e) {
                if (e.keyCode == 27) {
                    self.hitEscKey(e);
                    return false;
                }
            });
            self.$('#allowedButton').focus();
        });
        return this;
    },


    /////////////////////////////////////
    // User input
    /////////////////////////////////////
    hitEnterKey: function (e) {
        this.userClickedAllowed(e);
    },

    hitEscKey: function (e) {
        this.userClickedCancel(e);
    },

    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    },

    userClickedAllowed: function (e) {
        e.preventDefault();
        this.model.allowDeletion();
        Common.appView.closeDialog();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    userClickedCancel: function (e) {
        e.preventDefault();
        Common.appView.closeDialog();
    },

    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});