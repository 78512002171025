'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    //Model: BuildingAccount
    initialize: function () {
        console.log('Initializing AddBuildingAccountView');

        Backbone.Validation.bind(this);
        this.listenTo(this.model, 'change:message', this.serverMessage);
        this.listenTo(this.model, 'change:id', this.changedAccountId);
        Common.inputView = this;
    },

    events: {
        "click": "clicked",
        "click #save": "clickedSave",
        "click #cancel": "clickedCancel",
        "click #selectUser": "clickedSelectUser",

        //Validation
        "change input": "inputChanged",
        "focus input": "inputFocus",
        "blur input": "inputBlur",

        //AccountType dropdown
        "click #accountTypeMenu": "openAccountTypeMenu",
        "click .accountTypeItem": "userChangedAccountType"
    },

    //Cache the template function for a single item
    template: _.template($('#addBuildingAccount-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.renderAccountTypeDropdown();
        });
        return this;
    },

    renderAccountTypeDropdown: function () {
        var accountTypeStr = Common.Accounts_Labels[this.model.get('accountTypeId')];
        this.$("#accountTypeLabel").html(accountTypeStr);
    },

    /////////////////////////////////////
    // Validation
    /////////////////////////////////////
    inputChanged: function (eventName, args) {
        eventName.preventDefault();
        eventName.stopPropagation();
        eventName.stopImmediatePropagation();

        // Validate one attribute
        // The 'errorsMessage' returned is a string
        var attr = eventName.currentTarget.name;
        var value = eventName.currentTarget.value;
        var view = Common.inputView;
        view.$('#accountExistError').addClass('hidden');
        view.fillModelWithDataFromView();

        var error = view.model.preValidate(attr, value);

        if (error) {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            $group.addClass('has-error');
            $group.find('.help-block').html(error).removeClass('hidden');
        }
        else {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            $group.removeClass('has-error');
            $group.find('.help-block').html('').addClass('hidden');
        }
    },

    inputFocus: function (eventName, args) {
        eventName.preventDefault();
        eventName.stopPropagation();
        eventName.stopImmediatePropagation();

        //Skip validation if we enter a blank element, unless it has errors, OR its the 'repeatpassword' field
        var attr = eventName.currentTarget.name;
        var value = eventName.currentTarget.value;
        var view = Common.inputView;

        if (value == "") {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            if (!$group.hasClass('has-error')) {
                if (attr != "repeatPassword") {
                    return;
                }
            }
        }

        //Subscribe to 'input' change events to update validation live
        var id = "#" + eventName.currentTarget.id;
        $(id).on('input', this.inputChanged);
    },

    inputBlur: function (eventName, args) {
        eventName.preventDefault();
        eventName.stopPropagation();

        var id = "#" + eventName.currentTarget.id;
        $(id).off('input', this.inputChanged);

        //Always run validaton when we leave a field
        this.inputChanged(eventName, args);
    },

    fillModelWithDataFromView: function () {
        var form = $("form:first-of-type");
        var data = form.serializeObject();
        this.model.set(data);
    },

    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(".dropdown").fadeOut(100);
    },

    clickedSelectUser: function (e) {
        e.preventDefault();
        e.stopPropagation();

        //Validate
        $('#serverError').text("").hide();// Hide any servers on a new submit
        if (!this.model.isValid(true)) {
            return;
        }

        //Set properties in model
        this.model.set('email', this.$('#username').val());
        this.model.set('username', this.$('#username').val());
        this.model.set('addToBuilding', true);

        //Save / create / add
        this.model.create();
    },

    clickedSave: function (e) {
        e.preventDefault();
        e.stopPropagation();

        //Validate
        $('#serverError').text("").hide();// Hide any servers on a new submit
        if (!this.model.isValid(true)) {
            return;
        }

        //Set properties in model
        this.model.set('email', this.$('#username').val());
        this.model.set('username', this.$('#username').val());
        this.model.set('password', this.$('#password').val());
        this.model.set('repeatPassword', this.$('#repeatPassword').val());
        this.model.set('firstname', this.$('#firstname').val());
        this.model.set('lastname', this.$('#lastname').val());

        //Save / create / add
        this.model.create();
    },

    clickedCancel: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        e.stopPropagation();

        this.destroy();
        Common.appView.closeDialog();
    },


    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    serverMessage: function () {
        if (this.model.account && this.model.account.get('message') !== '') {
            this.model.account.buildName();
            this.$('#accountExistError').removeClass('hidden');
            this.$('#selectUser').html("Add: " + this.model.account.get('name'));
        }
    },

    changedAccountId: function () {
        this.destroy();
        Common.appView.closeDialog();
    },
    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openAccountTypeMenu: function (e) {
        if (this.$('#accountTypeMenuDropdown:visible').length === 0) {
            $(".dropdown").fadeOut(100);
            this.$("#accountTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    userChangedAccountType: function (e) {
        var accountTypeId = e.currentTarget.getAttribute('name');
        this.model.set('accountTypeId', accountTypeId);
        this.renderAccountTypeDropdown();
        $(".dropdown").fadeOut(100);
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});