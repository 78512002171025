'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Log = require('../models/log');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing LogManagerView');

        //Setup highlights of log lines
        this.listenTo(Common.SearchLogMatches, 'change:isSelected', this.changeMatchIsSelected);
        this.listenTo(Common.SearchLogMatches, 'changedPageInfo', this.changedPageInfo);

        //Set menu
        Common.MainMenuEntries.setSelected('log');

        //Setup rendering to log details
        if (options.logId) {
            this.logId = options.logId;
        }
    },


    events: {
        //Filtering
        "focus #idFilter": "idFilterFocus",
        "focus #datetimeFilter": "datetimeFilterFocus",
        "focus #commentFilter": "commentFilterFocus",
        "click #clearFilter": "clickedClearFilter",

        //Adding new log
        "click #addComment": "clickedAddComment",
        "click #clearComment": "clickedCancelComment",

        //LogType dropdown
        "click .logTypeMenu": "openLogTypeMenu",
        "click .logTypeItem": "userChangedLogType",

        //LogTypeFilter dropdown
        "click #logTypeFilterMenu": "openLogTypeFilterMenu",
        "click .logTypeFilterItem": "userChangedLogTypeFilter",

        //Model: user input --> update mmodel
        "blur #Comment": "inputChangedComment",

        //Pagenation
        "click #nextPage": "clickedNextPage",
        "click #prevPage": "clickedPrevPage",
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#logManager-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        var self = this;
        $(function () {
            $('#pageTitle').html('Daily logs');
            self.resetNewLogInput();
            self.activate();
            if (self.logId) {
                self.renderDetails(self.logId);
            }
            else {
                self.renderSummary();
            }
        });
        return this;
    },


    activate: function () {
        var self = this;
        self.$('textarea').focus();
        setTimeout(function () {
            self.$('textarea').focus();
        }, 200);
    },

    renderSummary() {
        //Hide details and show summary 
        this.$("#logListContainer").removeClass('hidden');
        this.$("#logDetailsContainer").addClass('hidden');

        //Destroy views from details
        Common.searchLogListView.disable();

        //Render summary
        Common.searchLogListView.enable(this, 'LogLineView', '#logList');
    },
    renderDetails(logId) {
        //Hide summary and show details
        this.$("#logListContainer").addClass('hidden');
        this.$("#logDetailsContainer").removeClass('hidden');

        //Destroy views from summary
        Common.searchLogListView.disable();

        //Render details
        Common.searchLogListView.enable(this, 'LogView', '#logDetailsContainer', logId);
    },


    /////////////////////////////////////
    // User events on view
    /////////////////////////////////////
    idFilterFocus: function (eventName, args) {
        //Direct search result to follow this input field
        this.inputDomId = '0';
        this.inputProperty = 'id';
        this.waitForSearchLogListView();
    },

    datetimeFilterFocus: function (eventName, args) {
        //Direct search result to follow this input field
        this.inputDomId = '1';
        this.inputProperty = 'datetime';
        this.waitForSearchLogListView();
    },

    commentFilterFocus: function (eventName, args) {
        //Direct search result to follow this input field
        this.inputDomId = '2';
        this.inputProperty = 'comment';
        this.waitForSearchLogListView();
    },

    clickedClearFilter: function (e) {
        e.preventDefault();
        //Clear filter inputs
        this.$('#idFilter').val('');
        this.$('#datetimeFilter').val('');
        this.$('#logTypeFilterLabel').html('Type (all)');
        this.$('#commentFilter').val('');

        //Clear inputs in model
        Common.SearchLogMatches.clearFilter();
    },

    //Contininous monitoring of data entered in input field 'comment'
    waitForSearchLogListView() {
        if (Common.searchLogListView) {
            Common.searchLogListView.load(this.inputDomId, false, this.inputProperty);
        }
        else {
            setTimeout(this.waitForSearchLogListView, 200);
        }

    },


    /////////////////////////////////////
    // User input --> model (for new logs)
    /////////////////////////////////////
    inputChangedComment: function () {
        this.model.set('comment', this.$('#comment').val());
    },


    /////////////////////////////////////
    // User actions (buttons for new logs)
    /////////////////////////////////////
    clickedAddComment: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        this.inputChangedComment();
        this.$('#serverError').text("").hide();// Hide any servers on a new submit

        if (this.validate() === false) {
            return;
        }
        var self = this;
        var log = new Log();
        log.set('comment', this.model.get('comment'));
        log.set('logTypeId', this.model.get('logTypeId'));
        Common.Logs.add(log);
        log.save(null, {
            success: function () {
                self.resetNewLogInput();
            },
            error: function (e) {
                console.log("failed to save. e=" + e);
            },
        });
    },

    resetNewLogInput: function () {
        this.model.set('comment', '');
        this.$('#comment').val('');
        this.$('#logTypeLabel').html('Info');
        this.model.set('logTypeId', '100');// 'info'. See LogType.php
    },

    clickedCancelComment: function (e) {
        e.preventDefault();
        this.resetNewLogInput();
    },

    validate: function () {
        if (this.model.get('comment') === '') {
            return false;
        }
        else {
            return true;
        }
    },


    /////////////////////////////////////
    // User actions (pagenation)
    /////////////////////////////////////
    clickedNextPage: function (e) {
        e.preventDefault();
        Common.SearchLogMatches.nextPage();
    },

    clickedPrevPage: function (e) {
        e.preventDefault();
        Common.SearchLogMatches.prevPage();
    },


    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openLogTypeMenu: function (e) {
        if (this.$('#logTypeMenuDropdown:visible').length === 0) {
            $(".dropdown").fadeOut(100);
            this.$("#logTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    openLogTypeFilterMenu: function (e) {
        if (this.$('#logTypeFilterMenuDropdown:visible').length === 0) {
            $(".dropdown").fadeOut(100);
            this.$("#logTypeFilterMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    userChangedLogType: function (e) {
        e.preventDefault();
        var logTypeId = e.currentTarget.getAttribute('name');
        this.model.set('logTypeId', logTypeId);
        this.$("#logTypeLabel").html(e.target.innerHTML);
    },

    userChangedLogTypeFilter: function (e) {
        e.preventDefault();
        var logTypeId = e.currentTarget.getAttribute('name');
        this.$("#logTypeFilterLabel").html(e.target.innerHTML);

        //apply filter
        if (logTypeId === "all") {
            logTypeId = "";
        }
        Common.searchLogListView.setLogTypeIdFilter(logTypeId);
    },


    /////////////////////////////////////
    // Model events (for selection / highlights)
    /////////////////////////////////////
    changeMatchIsSelected: function (searchLogMatch) {
        if (searchLogMatch.get('isSelected') === true) {

            //We only need the trigger, so the event is reset
            searchLogMatch.set('isSelected', false);

            //Close the search panel
            if (Common.searchLogListView) {
                Common.searchLogListView.unload();
            }
        }
    },
    changedPageInfo: function () {
        this.$('#pageInfo').html(Common.SearchLogMatches.pageInfo);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        Common.searchLogListView.disable();
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});