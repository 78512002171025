﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing EditBuildingNameView');
    },

    events: {
        //Name
        "click #editName": "clickedEditName",
        "click #cancelName": "clickedCancelName",
        "click #saveName": "clickedSaveName",

        //Address
        "click #editAddress": "clickedEditAddress",
        "click #cancelAddress": "clickedCancelAddress",
        "click #saveAddress": "clickedSaveAddress",

        //Email
        "click #editEmail": "clickedEditEmail",
        "click #cancelEmail": "clickedCancelEmail",
        "click #saveEmail": "clickedSaveEmail"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#editBuildingName-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
        });
        return this;
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clickedEditName: function (e) {
        e.preventDefault();
        this.$("#viewNameRow").addClass('hidden');
        this.$("#editNameRow").removeClass('hidden');
        this.$('#inputName').val(this.model.get('name'));
    },
    clickedCancelName: function (e) {
        e.preventDefault();
        this.$("#viewNameRow").removeClass('hidden');
        this.$("#editNameRow").addClass('hidden');
    },
    clickedSaveName: function (e) {
        e.preventDefault();
        if (this.$('#inputName').val() == '') {
            return;
        }
        this.$("#viewNameRow").removeClass('hidden');
        this.$("#editNameRow").addClass('hidden');
        this.model.set('name', this.$('#inputName').val());
        this.render();
        this.model.save();
    },

    clickedEditAddress: function (e) {
        e.preventDefault();
        this.$("#viewAddressRow").addClass('hidden');
        this.$("#editAddressRow").removeClass('hidden');
        this.$('#inputStreetName').val(this.model.get('streetName'));
        this.$('#inputCity').val(this.model.get('city'));
        this.$('#inputZip').val(this.model.get('zip'));
        this.$('#inputState').val(this.model.get('state'));
        this.$('#inputCountry').val(this.model.get('country'));
    },
    clickedCancelAddress: function (e) {
        e.preventDefault();
        this.$("#viewAddressRow").removeClass('hidden');
        this.$("#editAddressRow").addClass('hidden');
    },
    clickedSaveAddress: function (e) {
        e.preventDefault();
        this.$("#viewAddressRow").removeClass('hidden');
        this.$("#editAddressRow").addClass('hidden');
        this.model.set('streetName', this.$('#inputStreetName').val());
        this.model.set('city', this.$('#inputCity').val());
        this.model.set('zip', this.$('#inputZip').val());
        this.model.set('state', this.$('#inputState').val());
        this.model.set('country', this.$('#inputCountry').val());
        this.render();
        this.model.save();
    },

    clickedEditEmail: function (e) {
        e.preventDefault();
        this.$("#viewEmailRow").addClass('hidden');
        this.$("#editEmailRow").removeClass('hidden');
        this.$('#inputEmail').val(this.model.get('email'));
    },
    clickedCancelEmail: function (e) {
        e.preventDefault();
        this.$("#viewEmailRow").removeClass('hidden');
        this.$("#editEmailRow").addClass('hidden');
    },
    clickedSaveEmail: function (e) {
        e.preventDefault();
        if (this.$('#inputEmail').val() == '') {
            return;
        }
        this.$("#viewEmailRow").removeClass('hidden');
        this.$("#editEmailRow").addClass('hidden');
        this.model.set('email', this.$('#inputEmail').val());
        this.render();
        this.model.save();
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});