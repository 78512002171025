'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Collection.extend({

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'condoPersonLinkType/index.php',

    getFormattedCondoPersonLink: function (condoPersonLink, label, includeResidentData, hostPersonName) {
        var primary = '';
        if (condoPersonLink.get('isPrimary')) {
            primary = "&nbsp;- primary";
        }
        var preLabel = "<div><div class='left'>" + label + " (" + Common.CondoPersonLinkTypes_Labels[condoPersonLink.get('condoPersonLinkTypeId')];
        var prePersonLabel = "</div>";
        var personLabel = "";
        var postLabel = "<div class='left'>)" + primary+"</div></div>";
        if (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_guestId) {
            prePersonLabel = " of&nbsp;" + prePersonLabel;
            personLabel = "<div class=' left link personLink' name='" + condoPersonLink.get('hostCondoPersonLinkId') + "'>" + hostPersonName ? hostPersonName : condoPersonLink.get('hostPersonName') + "</div>";
        } else if (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_staffId) {
            prePersonLabel = " for&nbsp;" + prePersonLabel;
            personLabel = "<div class=' left link personLink' name='" + condoPersonLink.get('hostCondoPersonLinkId') + "'>" + hostPersonName ? hostPersonName : condoPersonLink.get('hostPersonName') + "</div>";
        } else if (includeResidentData && condoPersonLink.get('isResident') == true) {
            prePersonLabel = ", resident" + prePersonLabel;
        }

        return preLabel + prePersonLabel + personLabel + postLabel;
    },

    getNameFromId: function (condoPersonLinkTypeId) {
        return Common.CondoPersonLinkTypes_Labels[condoPersonLinkTypeId];
    }
});