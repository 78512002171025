'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        header: '',
        url: '',
        imgClass: '',
        isSelected: false
    },

    urlRoot: '',

    initialize: function () {
    }
});