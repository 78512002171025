﻿'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        isMobile: false,
        number: '',
        comment: ''
    },

    reset: function () {
        this.set('isMobile', false);
        this.set('number', '');
        this.set('comment   ', '');
    },

    isValid: function () {
        return this.get('number') !== '';
    }
});