'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        log: '',
        isFiltered: false,  //new logs are never filtered
        isMatch: true,
        filteredId: '',
        filteredDatetime: '',
        filteredLogTypeId: '',
        filteredComment: ''
    },

    urlRoot: '',

    initialize: function (options) {
        //Store properties used for matching
        this.log = options.log;

        //Set property for rendered output
        this.set('id', this.log.get('id'));
        this.set('logTypeId', this.log.get('logTypeId'));
        this.set('comment', this.log.get('comment'));
        this.set('datetime', this.log.get('datetime'));

        //Apply filter if present
        if (Common.SearchLogMatches.isFilterEnabled()) {
            this.applyFilter();
        }
    },

    applyFilter: function () {
        this.set('isFiltered', true);
        this.setFilteredId(Common.SearchLogMatches.getFiltered(this.log.get('id'), Common.SearchLogMatches.idFilter));
        this.setFilteredDatetime(Common.SearchLogMatches.getFiltered(this.log.get('datetime'), Common.SearchLogMatches.datetimeFilter));
        this.setFilteredLogTypeId(this.log.get('logTypeId'), Common.SearchLogMatches.logTypeIdFilter);
        this.setFilteredComment(Common.SearchLogMatches.getFiltered(this.log.get('comment'), Common.SearchLogMatches.commentFilter));
    },

    setFilteredOff: function () {
        this.set('isFiltered', false);
        this.set('isMatch', true);

        this.set('filteredId', '');
        this.set('filteredDatetime', '');
        this.set('filteredLogTypeId', '');
        this.set('filteredComment', '');

        this.set('id', this.log.get('id'));
        this.set('logTypeId', this.log.get('logTypeId'));
        this.set('comment', this.log.get('comment'));
        this.set('datetime', this.log.get('datetime'));
    },

    setFilteredId: function (filteredId) {
        this.set('filteredId', filteredId);
        if (filteredId === '') {
            this.set('id', this.log.get('id'));
        }
        else {
            this.set('id', filteredId);
        }
        this.evaluateMatch();
    },

    setFilteredDatetime: function (filteredDatetime) {
        this.set('filteredDatetime', filteredDatetime);
        if (filteredDatetime === '') {
            this.set('datetime', this.log.get('datetime'));
        }
        else {
            this.set('datetime', filteredDatetime);
        }
        this.evaluateMatch();
    },

    setFilteredLogTypeId: function (orig, filter) {
        if (orig === filter) {
            this.set('filteredLogTypeId', orig);
        }
        else {
            this.set('filteredLogTypeId', '');
        }
        this.evaluateMatch();
    },

    setFilteredComment: function (filteredComment) {
        this.set('filteredComment', filteredComment);
        if (filteredComment === '') {
            this.set('comment', this.log.get('comment'));
        }
        else {
            this.set('comment', filteredComment);
        }
        this.evaluateMatch();
    },

    evaluateMatch: function () {
        //If the user entered NO text in any of the filters, then 'isFiltered' will be false. if ANY of the filters are active, 'isFiltered' is true
        if (this.get('isFiltered') === false) {
            this.set('isMatch', true);
        }
        else {
            //Only set 'isMatch' if filtered property is enabled in Common.SearchLogMatches, AND the filtered output for this model instance has a value
            if (Common.SearchLogMatches.idFilter !== '') {
                if (this.get('filteredId') === '') {
                    this.set('isMatch', false);
                    return;
                }
            }
            if (Common.SearchLogMatches.datetimeFilter !== '') {
                if (this.get('filteredDatetime') === '') {
                    this.set('isMatch', false);
                    return;
                }
            }
            if (Common.SearchLogMatches.logTypeIdFilter !== '') {
                if (this.get('filteredLogTypeId') === '') {
                    this.set('isMatch', false);
                    return;
                }
            }
            if (Common.SearchLogMatches.commentFilter !== '') {
                if (this.get('filteredComment') === '') {
                    this.set('isMatch', false);
                    return;
                }
            }
            this.set('isMatch', true);
        }
    },

    destroy: function () {
        this.collection.remove(this);
        this.log.destroy();
    }
});