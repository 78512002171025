﻿'use strict';
var Backbone = require('backbone');
var Email = require('../models/email');

module.exports = Backbone.Collection.extend({
    model: Email,

    initialize: function () {
        this.listenTo(this, 'change:email', this.changedEmail);
    },

    changedEmail: function (email) {
        if (email.get('email')) {
            var emptyEntries;
            //Make sure we always have one free email entry
            emptyEntries = this.filter(function (e) { return !e.get('email'); });
            if (emptyEntries.length == 0) {
                var emailModel = new this.model();
                this.add(emailModel);
            }
        }
        else {
            //If clearing out an email entry, remove it if there are other empty entries
            emptyEntries = this.filter(function (e) { return !e.get('email'); });
            if (emptyEntries.length > 1) {
                this.remove(email);
            }
        }
    }
});