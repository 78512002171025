'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var DeletePrompt = require('../models/deletePrompt');
var DeletePromptView = require('../views/deletePromptView');
var Util = require('../util/util');

var LogView = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing LogView');

        this.listenTo(this.model, 'change:isMatch', this.changeIsmatch);
        this.listenTo(this.model, 'change:id', this.changedId);
        this.listenTo(this.model, 'change:datetime', this.changedDatetime);
        this.listenTo(this.model, 'change:comment', this.changedComment);
        this.listenTo(this.model, 'remove', this.removedSearchLogMatch);

        this.listenTo(this.model.log, 'change:star', this.changedStar);
    },

    tagName: 'div',
    className: '',

    events: {
        "click #delete": "clickedDelete",
        "click #star": "clickedStar",
        "click #editLogType": "clickedEditLogType",
        "click #editComment": "clickedEditComment",
        "click #cancelLogType": "clickedCancelLogType",
        "click #cancelComment": "clickedCancelComment",
        "click #saveLogType": "clickedSaveLogType",
        "click #saveComment": "clickedSaveComment",

        //LogType dropdown
        "click #logTypeMenu": "openLogTypeMenu",
        "click .logTypeItem": "userChangedLogType"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#log-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.renderId();
            self.renderUser();
            self.renderLogType();
            self.renderStar();
            self.renderComment();
            $(self.el).attr('name', self.model.log.get('serverTimestamp'));
        });
        return this;
    },

    renderStar: function () {
        if (this.model.log.get('star') == true) {
            this.$('#star').addClass('starred');
            this.$('#star').removeClass('unStarred');
        }
        else {
            this.$('#star').removeClass('starred');
            this.$('#star').addClass('unStarred');
        }
    },

    renderId: function () {
        this.$('#id').html(this.model.get('id'));
    },

    renderUser: function () {
        this.$('#user').html(this.model.log.get('user'));
    },

    renderLogType: function () {
        this.$('#type').html(this.getLogTypeStringFromId(this.model.get('logTypeId')));
        this.$('#logTypeLabel').html(this.getLogTypeStringFromId(this.model.get('logTypeId')));
    },

    renderComment: function () {
        var comment = this.model.get('comment');
        var commentHtml = Util.jsonToBr(comment);
        this.$('#comment').html(commentHtml);
        this.$('#commentEdit').val(comment);
    },

    getLogTypeStringFromId: function (logTypeId) {
        switch (logTypeId) {
            case "1":
            case "2":
            case "3":
                return 'Profile';
            case "5":
            case "6":
                return 'Mail';
            case "7":
            case "8":
                return 'Package';
            case "9":
            case "10":
            case "11":
                return 'Event';
            case "13":
            case "14":
            case "15":
                return 'Away';
            case "20":
            case "21":
            case "22":
            case "23":
            case "24":
                return 'Checkin';
            case "100":
                return 'Info';
            case "101":
                return 'Incident';
            case "102":
                return 'Leak';
            case "103":
                return 'Conctractor';
            case "104":
                return 'Window washing';
            case "105":
                return 'Maintenance';
            default:
                return "";
        }
    },


    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    changedId: function (m) {
        if (m === this.model) {
            this.renderId();
        }
    },

    changedStar: function (m) {
        if (m === this.model.log) {
            this.renderStar();
        }
    },

    changedDatetime: function (m) {
        if (m === this.model) {
            this.$('#datetime').html(this.model.get('datetime'));
        }
    },

    changedComment: function (m) {
        if (m === this.model) {
            this.$('#comment').html(this.model.get('comment'));
        }
    },

    changeIsmatch: function (m) {
        if (this.model.get('isMatch') === true) {
            $(this.el).removeClass('hidden');
        }
        else {
            $(this.el).addClass('hidden');
        }
    },

    removedSearchLogMatch: function (m) {
        if (m === this.model) {
            this.destroy();
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        e.stopPropagation();

        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('label') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    delete: function () {
        this.model.destroy();
    },

    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.log.get('star') == false) {
            this.model.log.set('star', true);
        }
        else {
            this.model.log.set('star', false);
        }
        this.model.log.save();
    },

    clickedEditLogType: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$("#viewLogTypeRow").addClass('hidden');
        this.$("#editLogTypeRow").removeClass('hidden');
        this.tempLogTypeId = this.model.log.get('logTypeId');
    },

    clickedEditComment: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var height = this.$('#comment').outerHeight();
        this.$("#viewCommentRow").addClass('hidden');
        this.$("#editCommentRow").removeClass('hidden');
        this.$("#commentEdit").height(height);
    },

    clickedCancelLogType: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$("#viewLogTypeRow").removeClass('hidden');
        this.$("#editLogTypeRow").addClass('hidden');
        this.renderLogType();
    },

    clickedCancelComment: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
        this.renderComment();
    },

    clickedSaveLogType: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$("#viewLogTypeRow").removeClass('hidden');
        this.$("#editLogTypeRow").addClass('hidden');
        this.model.log.set('logTypeId', this.tempLogTypeId);
        this.model.set('logTypeId', this.model.log.get('logTypeId'));
        this.model.log.save();
        this.renderLogType();
    },

    clickedSaveComment: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
        this.model.log.set('comment', this.$("#commentEdit").val());
        this.model.set('comment', this.model.log.get('comment'));
        this.model.log.save();
        this.renderComment();
    },

    openLogTypeMenu: function (e) {
        if (this.$('#logTypeMenuDropdown:visible').length === 0) {
            $(".dropdown").fadeOut(100);
            this.$("#logTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    userChangedLogType: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var logTypeId = e.currentTarget.getAttribute('name');
        this.$("#logTypeLabel").html(e.target.innerHTML);
        this.tempLogTypeId = logTypeId;
        this.$("#logTypeMenuDropdown").fadeOut(100);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});
Common.LogView = LogView;
module.exports = LogView;