﻿'use strict';
var Backbone = require('backbone');
var Guests = require('../collections/guests');
var Accesss = require('../collections/accesss');
var Comments = require('../collections/comments');
var Periods = require('../collections/periods');
var Aways = require('../collections/aways');
var Emails = require('../collections/emails');
var Phones = require('../collections/phones');
var MailDeliveryPreferences = require('../collections/mailDeliveryPreferences');
var PackageDeliveryPreferences = require('../collections/packageDeliveryPreferences');
var Types = require('../collections/types');
var Person = require('../models/person');
var Access = require('../models/access');
var Comment = require('../models/comment');
var Period = require('../models/period');
var Away = require('../models/away');
var Type = require('../models/type');
var Email = require('../models/email');
var Phone = require('../models/phone');
var MailDeliveryPreference = require('../models/mailDeliveryPreference');
var PackageDeliveryPreference = require('../models/packageDeliveryPreference');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        isAlsoOwnerOrTenant: true,      //TRUE when no links are known or when at least one link is with role Owner/Tenant, i.e. this person has a strong relationship with the building
        isNeitherOwnerOrTenant: false,  //TRUE when there are one links with role Owner/Tenant, i.e. this is an actual guest/tenant/staff or other non-resident, i.e. this person has a weak relationship with the building
        mailDeliveryPreference: '',
        packageDeliveryPreference: '',
        period: '',
        comments: ''
    },

    initialize: function (options) {
        this.person = options.person;
        this.condoPersonLink = options.condoPersonLink;

        //Create collections related to this 'Person'
        this.Guests = new Guests();
        this.Guests.setup({ person: this.person });
        this.Accesss = new Accesss();
        this.Comments = new Comments();
        this.Periods = new Periods();
        this.Types = new Types();
        this.Aways = new Aways();
        this.Emails = new Emails();
        this.Phones = new Phones();
        this.MailDeliveryPreferences = new MailDeliveryPreferences();
        this.PackageDeliveryPreferences = new PackageDeliveryPreferences();

        this.listenTo(this.person.CondoPersonLinks, "reset", this.resetCondoPersonLinksOnPerson);
        this.listenTo(this.person.CondoPersonLinks, "add", this.addedCondoPersonLinkOnPerson);
        this.listenTo(this.person, "change:output", this.recalcTitle);
        this.listenTo(this.Types, "add", this.recalcIsPrimaryVisibility);
        this.listenTo(this.Types, "remove", this.recalcIsPrimaryVisibility);
        this.listenTo(this.Types, "change:isCondoSet", this.recalcIsPrimaryVisibility);
        this.listenTo(this.Types, "change:isOwnerOrTenant", this.recalcIsPrimaryVisibility);
        this.listenTo(this.Types, "change:isMarkedForDeletion", this.recalcIsPrimaryVisibility);
        this.listenTo(this.Types, "change:isPrimary", this.changedIsPrimary);
        this.listenTo(this.Types, "change:isMarkedForDeletion", this.changedMarkedForDeletion);
        
        //Load existing data
        var self = this;
        this.person.CondoPersonLinks.forEach(function (condoPersonLink) {
            self.addedCondoPersonLinkOnPerson(condoPersonLink);
        });
        this.person.get('aways').forEach(function (away) {
            var awayModel = new Away(away);
            self.Aways.add(awayModel);
        });
    },

    recalcTitle: function () {
        if (this.condoPersonLink.get('isStaffOrGuest')) {
            this.set('title', this.person.get('output') + " (" + this.condoPersonLink.get('label') + ")");
        }
        else {
            var condosString = "";
            var condoOwnerOrTenantCondoIdList = [];
            this.person.CondoPersonLinks.forEach(function (condoPersonLink) {
                if (condoPersonLink.get('isOwnerOrTenant')) {
                    if (!condoOwnerOrTenantCondoIdList.includes(condoPersonLink.get('condoId'))) {
                        condoOwnerOrTenantCondoIdList.push(condoPersonLink.get('condoId'));
                        if (condosString != "") {
                            condosString += ", ";
                        }
                        condosString += condoPersonLink.get('label');
                    }
                }
            });
            this.set('title', this.person.get('output') + " (" + condosString + ")");
        }
    },

    recalcDataTiedToCondoPersonLinks: function () {
        var isAlsoOwnerOrTenant = (this.person.CondoPersonLinks.length === 0 || (this.person.CondoPersonLinks.findWhere({ isOwnerOrTenant: true }) !== undefined));
        this.set('isAlsoOwnerOrTenant', isAlsoOwnerOrTenant);

        if (isAlsoOwnerOrTenant) {
            this.set('mailDeliveryPreference', this.person.getFormattedNotes('mailDeliveryPreference'));
            this.set('packageDeliveryPreference', this.person.getFormattedNotes('packageDeliveryPreference'));
        }
        else {
            this.set('period', this.person.getFormattedPeriods());
        }

        var isNeitherOwnerOrTenant = (this.person.CondoPersonLinks.findWhere({ isOwnerOrTenant: false }) === undefined);
        this.set('isNeitherOwnerOrTenant ', isNeitherOwnerOrTenant);

        this.set('comments', this.person.getFormattedNotes('comment'));
    },

    resetCondoPersonLinksOnPerson: function () {
        this.recalcDataTiedToCondoPersonLinks();
        this.Guests.reset();
        this.Accesss.reset();
        this.Types.reset();
        this.Periods.reset();
    },

    addedCondoPersonLinkOnPerson: function (condoPersonLink) {
        this.recalcDataTiedToCondoPersonLinks();
        this.recalcTitle();
        var typeModel = new Type({ person: this.person, condoPersonLink: condoPersonLink });
        this.Types.add(typeModel);
        var accessModel = new Access({ person: this.person, condoPersonLink: condoPersonLink });
        this.Accesss.add(accessModel);
        if (condoPersonLink.get('isStaffOrGuest')) {
            var periodModel = new Period({ person: this.person, condoPersonLink: condoPersonLink });
            this.Periods.add(periodModel);
        }
    },

    recalcDeliveryPreferenceCollections: function () {
        this.MailDeliveryPreferences.reset();
        this.PackageDeliveryPreferences.reset();
        this.Comments.reset();
        var self = this;
        var isMultiCondo = this.person.get('isMultiCondo');
        this.person.CondoPersonLinks.forEach(function (condoPersonLink) {
            var mailDeliveryPreferenceModel = new MailDeliveryPreference({ person: self.person, condoPersonLink: condoPersonLink, isMultiCondo: isMultiCondo });
            self.MailDeliveryPreferences.add(mailDeliveryPreferenceModel);
            var packageDeliveryPreferenceModel = new PackageDeliveryPreference({ person: self.person, condoPersonLink: condoPersonLink, key: "packageDeliveryPreference", isMultiCondo: isMultiCondo });
            self.PackageDeliveryPreferences.add(packageDeliveryPreferenceModel);
            var commentModel = new Comment({ person: self.person, condoPersonLink: condoPersonLink, key: "comment", isMultiCondo: isMultiCondo });
            self.Comments.add(commentModel);
        });
    },


    /////////////////////////////////////
    // Edit: DeliveryPreferences
    /////////////////////////////////////
    saveMailDeliveryPreference: function () {
        this.MailDeliveryPreferences.forEach(function (preference) {
            preference.save();
        });
        this.recalcDataTiedToCondoPersonLinks();
    },

    savePackageDeliveryPreference: function () {
        this.PackageDeliveryPreferences.forEach(function (preference) {
            preference.save();
        });
        this.recalcDataTiedToCondoPersonLinks();
    },


    /////////////////////////////////////
    // Edit: type 
    /////////////////////////////////////
    editType: function () {
        this.Types.person = this.person;
        this.Types.ensureWeHaveOneFreeEntry();
        this.Types.models.forEach(function (type) {
            type.edit();
        });
    },

    cancelTypeEdit: function () {
        var self = this;

        //Cancel edit mode
        this.Types.models.forEach(function (type) {
            type.cancel();
        });

        //Remove 'Type' 'AddNew'
        var addNewTypes = this.Types.where({ isNewType: true });
        if (addNewTypes) {
            addNewTypes.forEach(function (type) {
                self.Types.remove(type);
            });
        }
    },

    saveType: function () {
        var self = this;
        this.Types.person = null;

        //Make sure one of the types are set to primary (if primary mode is enabled)
        var ownerOrTenants = this.Types.where({ isOwnerOrTenant: true, isCondoSet: true, isMarkedForDeletion: false });
        if (ownerOrTenants && ownerOrTenants.length > 1) {
            var primary = this.Types.where({ isOwnerOrTenant: true, isCondoSet: true, isMarkedForDeletion: false, isPrimary: true });
            if (primary.length === 0) {
                ownerOrTenants[0].set('isPrimary', true);
            }
        }

        //Remove the 'Type' 'AddNew' if its invalid or prepare it for saving if it ISvalid
        var addNewTypes = this.Types.where({ isNewType: true });
        if (addNewTypes) {
            addNewTypes.forEach(function (type) {
                if (type.isValidForCreation()) {
                    type.person = self.person;
                }
                else {
                    self.Types.remove(type);
                }
            });
        }

        //Save, or leave edit mode if no saving is to be done
        var success = true;
        var collection = this.Types.models.slice(0);//a copy  is used since items could be removed from Types during saving...
        collection.forEach(function (type) {
            if (type.okToSave()) {
                type.save();
            }
            else {
                type.cancel();
                success = false;
            }
        });
        return success;
    },

    recalcIsPrimaryVisibility: function () {
        //Show primary for all isOwnerOrTenant-entries, if there are at least two of them
        var ownerOrTenants = this.Types.where({ isOwnerOrTenant: true, isCondoSet: true, isMarkedForDeletion: false });
        if (ownerOrTenants && ownerOrTenants.length > 1) {
            ownerOrTenants.forEach(function (ownerOrTenant) {
                ownerOrTenant.set('isPrimaryConfigurable', true);
            });
        }
        else {
            ownerOrTenants.forEach(function (ownerOrTenant) {
                ownerOrTenant.set('isPrimaryConfigurable', false);
            });
        }
    },

    changedIsPrimary: function (changedType, isPrimary) {
        //Make sure only one of the types are set to primary
        if (isPrimary) {
            this.Types.forEach(function (type) {
                if (type != changedType) {
                    type.set('isPrimary', false);
                }
            });
        }
    },

    changedMarkedForDeletion: function () {
        //Make sure one of the types are set to primary (if primary mode is enabled)
        var ownerOrTenants = this.Types.where({ isOwnerOrTenant: true, isCondoSet: true, isMarkedForDeletion: false });
        if (ownerOrTenants && ownerOrTenants.length > 1) {
            var primary = this.Types.where({ isOwnerOrTenant: true, isCondoSet: true, isMarkedForDeletion: false, isPrimary: true });
            if (primary.length === 0) {
                ownerOrTenants[0].set('isPrimary', true);
            }
        }
    },


    /////////////////////////////////////
    // Edit: access 
    /////////////////////////////////////
    editAccess: function () {
        this.Accesss.models.forEach(function (access) {
            access.edit();
        });
    },

    cancelAccessEdit: function () {
        this.Accesss.models.forEach(function (access) {
            access.cancel();
        });
    },

    saveAccess: function () {
        this.Accesss.forEach(function (access) {
            access.save();
        });
    },


    /////////////////////////////////////
    // Edit: Comment/Period
    /////////////////////////////////////
    saveComment: function () {
        this.Comments.forEach(function (comment) {
            comment.save();
        });
    },

    savePeriod: function () {
        this.Periods.forEach(function (period) {
            period.save();
        });
    },


    /////////////////////////////////////
    // Edit: Away
    /////////////////////////////////////
    editAway: function () {
        var self = this;
        this.Aways.reset();
        var aways = this.person.get('aways');
        if (Array.isArray(aways)) {
            aways.forEach(function (away) {
                var awayModel = new Away(away);
                self.Aways.add(awayModel);
            });
        }
        this.Aways.add(new Away());
    },

    saveAway: function () {
        var aways = [];
        this.Aways.models.forEach(function (away) {
            if (away.isValid()) {
                aways.push(away);
            }
        });
        var jsonAways = JSON.stringify(aways);
        this.person.set('awaysString', jsonAways);
        this.person.save();
    },


    /////////////////////////////////////
    // Edit: Email
    /////////////////////////////////////
    editEmail: function () {
        var self = this;
        this.Emails.reset();
        var emails = this.person.get('emails');
        if (Array.isArray(emails)) {
            emails.forEach(function (email) {
                var emailModel = new Email(email);
                self.Emails.add(emailModel);
            });
        }
        this.Emails.add(new Email());
    },

    saveEmail: function () {
        var emails = [];
        this.Emails.models.forEach(function (email) {
            if (email.isValid()) {
                emails.push(email);
            }
        });
        var jsonEmails = JSON.stringify(emails);
        this.person.set('emailsString', jsonEmails);
        this.person.save();
    },


    /////////////////////////////////////
    // Edit: Phone
    /////////////////////////////////////
    editPhone: function () {
        var self = this;
        this.Phones.reset();
        var phones = this.person.get('phones');
        if (Array.isArray(phones)) {
            phones.forEach(function (phone) {
                var phoneModel = new Phone(phone);
                self.Phones.add(phoneModel);
            });
        }
        this.Phones.add(new Phone());
    },

    savePhone: function () {
        var phones = [];
        this.Phones.models.forEach(function (phone) {
            if (phone.isValid()) {
                phones.push(phone);
            }
        });
        var jsonPhones = JSON.stringify(phones);
        this.person.set('phonesString', jsonPhones);
        this.person.save();
    }
});