﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var SearchPersonExMatch = require('../models/searchPersonExMatch');

module.exports = Backbone.Collection.extend({
    model: SearchPersonExMatch,

    url: '',
    initialize: function () {
        this.filter = '';
        this.condoPersonLinkIds = [];
        this.includeDeleted = false;
    },
    setCondoPersonLinkIds: function (condoPersonLinkIds) {
        this.condoPersonLinkIds = condoPersonLinkIds;
        this.setFilter(this.filter);
    },

    load: function (renderView) {
        this.renderView = renderView;
    },

    unload: function () {
        this.renderView == null;
    },

    setFilter: function (filter) {
        this.filter = filter;
        if (filter == '') {
            this.reset();
            return;
        }

        //Clear existing search results
        this.reset();

        //Loop all persons for match, update/create if matchs is found
        var self = this;
        Common.Persons.forEach(function (person) {

            //Direct match on person:
            var personFilter = self.getFiltered(person.get('output'), filter);
            if (personFilter != '') {
                person.CondoPersonLinks.forEach(function (condoPersonLink) {
                    if (self.condoPersonLinkIds.indexOf(condoPersonLink.get('condoPersonLinkTypeId')) > -1) {
                        self.addOrUpdate(person, true, false, condoPersonLink, filter, false);
                    }
                });
            }

            //Direct match on alias
            var aliasFilter = self.getFiltered(person.get('alias'), filter);
            if (aliasFilter != '') {
                person.CondoPersonLinks.forEach(function (condoPersonLink) {
                    if (self.condoPersonLinkIds.indexOf(condoPersonLink.get('condoPersonLinkTypeId')) > -1) {
                        self.addOrUpdate(person, false, true, condoPersonLink, filter, false);
                    }
                });
            }

            //Direct match on condoPersonLink label or host name
            person.CondoPersonLinks.forEach(function (condoPersonLink) {
                if (self.condoPersonLinkIds.indexOf(condoPersonLink.get('condoPersonLinkTypeId')) > -1) {
                    var labelFilter = self.getFiltered(condoPersonLink.get('label'), filter);
                    if (labelFilter != '') {
                        self.addOrUpdate(person, false, false, condoPersonLink, filter, false);
                    }

                    if (condoPersonLink.get('hostPersonName') && (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_staffId || condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_guestId)) {
                        var hostNameFilter = self.getFiltered(condoPersonLink.get('hostPersonName'), filter);
                        if (hostNameFilter != '') {
                            self.addOrUpdate(person, false, false, condoPersonLink, filter, true);
                        }
                    }
                }
            });
        });

        this.sort();

        if (this.renderView && this.renderView.redraw) {
            this.renderView.redraw();
        }
    },

    //Used to sort entries added to the collection
    comparator: function (a, b) {
        var star = a.get('star') - b.get('star');
        var resident = b.get('staffOrGuest') - a.get('staffOrGuest');
        var condoLabel = a.get('condoLabel') === b.get('condoLabel') ? 0 : a.get('condoLabel') < b.get('condoLabel') ? 1 : -1;
        var lowPriority = a.get('lowPriority') - b.get('lowPriority');

        if (star === 1) {
            return -1;
        }
        else if (star === 0) {
            if (lowPriority === 1) {
                return 1;
            }
            else if (lowPriority === 0) {
                if (resident === 1) {
                    return -1;
                }
                else if (resident === 0) {

                    if (condoLabel === 1) {
                        return -1;
                    }
                    else if (condoLabel === -1) {
                        return 1;
                    }
                    return 0;
                }
                else {
                    return 1;
                }
            }
            else {
                //lowPriority = -1
                return -1;
            }
        }
        else {
            //star === -1
            return 1;
        }
    },

    //Add one entry for each link where this person is a guest/staff
    //Add a single entry containing ALL links where this person has other roles (owner/tenant/deceased/formerowner)
    addOrUpdate: function (person, isPersonMatch, isAliasMatch, condoPersonLink, filter, isHostMatch) {
        //Each link is processed individually as each link will either be part of a combined entry for a person who is owners/tenants or multiple units or a link will be its own entry for this person (as its a guest/staff)
        var existingMatch = this.models.find(function (searchPersonExMatch) {
            return (searchPersonExMatch.origPerson == person &&
                searchPersonExMatch.get('isPersonMatch') == isPersonMatch &&
                searchPersonExMatch.get('isAliasMatch') == isAliasMatch &&
                searchPersonExMatch.get('isHostMatch') == isHostMatch &&
                searchPersonExMatch.CondoPersonLinks.includes(condoPersonLink));
        });
        if (existingMatch == null) {
            //Lump entries together, unless for guests/staff (where we only lump together if host is the same)
            var staffOrGuest = (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_guestId || condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_staffId);
            if (staffOrGuest) {
                existingMatch = this.models.find(function (searchPersonExMatch) {
                    return (searchPersonExMatch.origPerson == person &&
                        searchPersonExMatch.get('hostCondoPersonLinkId') == condoPersonLink.id);
                });
                if (existingMatch) {
                    this.updateExisting(existingMatch, condoPersonLink, filter);
                    return;
                }
            }
            else {
                existingMatch = this.models.find(function (searchPersonExMatch) {
                    return (searchPersonExMatch.origPerson == person &&
                        searchPersonExMatch.get('isPersonMatch') == isPersonMatch &&
                        searchPersonExMatch.get('isHostMatch') == isHostMatch &&
                        searchPersonExMatch.get('isAliasMatch') == isAliasMatch &&
                        !searchPersonExMatch.get('staffOrGuest'));
                });
                if (existingMatch) {
                    this.updateExisting(existingMatch, condoPersonLink, filter);
                    return;
                }
            }
            this.addNew(person, isPersonMatch, isAliasMatch, condoPersonLink, filter, isHostMatch);
        }
        else {
            this.updateExisting(existingMatch, condoPersonLink, filter);
        }
    },

    addNew: function (person, isPersonMatch, isAliasMatch, condoPersonLink, filter, isHostMatch) {
        var output = person.get('output');
        if (isPersonMatch == true) {
            output = this.getFiltered(person.get('output'), filter);
        }
        else if (isAliasMatch == true) {
            output = this.getFiltered(person.get('alias'), filter);
        }
        var hostPersonName = condoPersonLink.get('hostPersonName');
        if (isHostMatch == true) {
            hostPersonName = this.getFiltered(hostPersonName, filter);
        }
        var labelFilter = this.getFiltered(condoPersonLink.get('label'), filter);
        if (!labelFilter) {
            labelFilter = condoPersonLink.get('label');
        }
        var condoPersonLinkOutput = Common.CondoPersonLinkTypes.getFormattedCondoPersonLink(condoPersonLink, labelFilter, null, hostPersonName);
        var staffOrGuest = (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_guestId || condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_staffId);
        var lowPriority = (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_deceasedId || condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_formerResidentId);
        var noAccess = condoPersonLink.get('accessTypeId') == Common.AccessTypes_noAccessId;

        var match = new this.model({
            origPerson: person,
            isPersonMatch: isPersonMatch,
            isAliasMatch: isAliasMatch,
            isHostMatch: isHostMatch,
            CondoPersonLinks: [condoPersonLink],
            output: output,
            condoPersonLinkOutput: condoPersonLinkOutput,
            noAccess: noAccess,
            staffOrGuest: staffOrGuest,
            star: condoPersonLink.get('star'),
            condoLabel: condoPersonLink.get('label'),
            isResident: condoPersonLink.get('isResident'),
            lowPriority: lowPriority
        });
        if (staffOrGuest) {
            match.set('hostCondoPersonLinkId', condoPersonLink.id);
        }
        this.add(match);
    },

    updateExisting: function (existingMatch, condoPersonLink, filter) {
        //Update output (name) to contain filtered component
        var output = existingMatch.origPerson.get('output');
        if (existingMatch.isPersonMatch == true) {
            output = this.getFiltered(existingMatch.origPerson.get('output'), filter);
        }
        else if (existingMatch.isAliasMatch == true) {
            output = this.getFiltered(existingMatch.origPerson.get('alias'), filter);
        }
        existingMatch.set('output', output);
        if (condoPersonLink) {
            if (!existingMatch.CondoPersonLinks.includes(condoPersonLink)) {
                existingMatch.CondoPersonLinks.push(condoPersonLink);
            }
        }

        //Update links to contain filtered component
        var result = '';
        var self = this;
        existingMatch.CondoPersonLinks.forEach(function (link) {
            var labelFilter = self.getFiltered(link.get('label'), filter);
            if (!labelFilter) {
                labelFilter = link.get('label');
            }
            var hostPersonNameFilter = link.get('hostPersonName');
            if (hostPersonNameFilter) {
                hostPersonNameFilter = self.getFiltered(link.get('hostPersonName'), filter);
                if (!hostPersonNameFilter) {
                    hostPersonNameFilter = link.get('hostPersonName');
                }
            }
            var condoPersonLinkOutput = Common.CondoPersonLinkTypes.getFormattedCondoPersonLink(link, labelFilter, undefined, hostPersonNameFilter);
            if (result != '') {
                result += "<br>";
            }
            result += condoPersonLinkOutput;
        });
        existingMatch.set('condoPersonLinkOutput', result);
    },

    getFiltered: function (source, filter) {
        if (filter == "") {
            return "";
        }
        filter = filter.toLowerCase();
        var filtered = "";
        var startPos = 0;

        //Get initial filter pos
        var matchPos = source.toLowerCase().indexOf(filter);
        if (matchPos >= 0) {
            while (matchPos >= 0) {
                filtered = filtered + source.substring(startPos, matchPos);
                filtered = filtered + "<span>" + source.substring(matchPos, matchPos + filter.length) + "</span>";
                startPos = matchPos + filter.length;
                matchPos = source.toLowerCase().indexOf(filter, startPos);
            }
            filtered = filtered + source.substring(startPos, source.length);
            return filtered;
        }
        else {
            return "";
        }
    },

    highlightNext: function () {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') == true;
        });

        //Move to first or next or none
        if (currentlyHighlighted == null) {
            if (this.models.length > 0) {
                this.models[0].set('isHighlighted', true);
            }
        }
        else {
            var idx = this.models.indexOf(currentlyHighlighted);
            if (idx > -1) {
                idx++;
                if (idx < this.models.length) {
                    currentlyHighlighted.set('isHighlighted', false);
                    this.models[idx].set('isHighlighted', true);
                }
            }
        }
    },

    highlightPrevious: function () {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') == true;
        });

        if (currentlyHighlighted != null) {
            currentlyHighlighted.set('isHighlighted', false);
            var idx = this.models.indexOf(currentlyHighlighted);
            idx--;
            if (idx > -1) {
                this.models[idx].set('isHighlighted', true);
            }
        }
    },

    selectHighlighted: function (hostView) {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') == true;
        });
        if (currentlyHighlighted) {
            currentlyHighlighted.set('isSelected', hostView);
        }
    }
});