﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var BuildingAccount = require('../models/buildingAccount');
var BuildingAccountView = require('../views/buildingAccountView');
var AddBuildingAccountView = require('../views/addBuildingAccountView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing EditBuildingAccountsView');

        this.listenTo(Common.Accounts, 'change:id', this.changedAccountId);
        this.accountViews = [];
    },

    events: {
        //Account
        "click #addAccount": "clickedAddAccount"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#editBuildingAccounts-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.renderAccounts();
        });
        return this;
    },

    renderAccounts: function () {
        var self = this;
        Common.Accounts.fetch({
            reset: true,
            success: function () {
                Common.Accounts.each(function (account) {
                    if (account.get('loggedIn') != true) {
                        self.accountAdd(account);
                    }
                });
            },
            error: function (e, a) {
                console.log("EditBuildingView: " + e);
            }
        });
    },

    accountAdd: function (account) {
        var view = new BuildingAccountView({ model: account });
        this.accountViews.push(view);
        this.$('#accountList').append(view.render().el);
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clickedAddAccount: function (e) {
        e.preventDefault();
        e.stopPropagation();

        //Show dialog
        var model = new BuildingAccount();
        var view = new AddBuildingAccountView({ model: model });
        Common.appView.requestDialog(view);
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedAccountId: function (m) {
        if (m.get('id') != '') {
            this.accountAdd(m);
        }
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});