﻿'use strict';
var Backbone = require('backbone');
var Photo = require('../models/photo');

module.exports = Backbone.Collection.extend({
    model: Photo,

    initialize: function () {
        this.listenTo(this, 'change:path', this.changedPath);
    },

    changedPath: function (photo) {
        var emptyEntries;
        if (photo.get('path')) {
            //Make sure we always have one free photo entry
            emptyEntries = this.filter(function (e) { return !e.get('path'); });
            if (emptyEntries.length == 0) {
                var photoModel = new this.model();
                this.add(photoModel);
            }
        }
        else {
            //If clearing out an photo entry, remove it if there are other empty entries
            emptyEntries = this.filter(function (e) { return !e.get('path'); });
            if (emptyEntries.length > 1) {
                this.remove(photo);
            }
        }
    }
});