﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Options: condoPersonLink (guaranteed)
    defaults: {
        edit: false
    },

    initialize: function (options) {
        this.person = options.person;
        this.set('condoLabel', this.get('condoPersonLink').get('label'));
        this.set('accessTypeId', this.get('condoPersonLink').get('accessTypeId'));
        this.listenTo(options.condoPersonLink, "remove", this.removedCondoPersonLink);
    },

    edit: function () {
        this.set('edit', true);
    },

    cancel: function () {
        this.set('accessTypeId', this.get('condoPersonLink').get('accessTypeId'));
        this.set('edit', false);
    },

    removedCondoPersonLink: function () {
        if (this.collection) {
            this.collection.remove(this);
        }
        this.stopListening();
    },

    save: function () {
        this.set('edit', false);
        this.get('condoPersonLink').set('accessTypeId', this.get('accessTypeId'));
        this.get('condoPersonLink').save(null, {
            success: function () {
            }
        });
    }
});