﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing EditBuildingGmailView');
    },

    events: {
        //ClientId
        "click #editClientId": "clickedEditClientId",
        "click #cancelClientId": "clickedCancelClientId",
        "click #saveClientId": "clickedSaveClientId",

        //Secret
        "click #editSecret": "clickedEditSecret",
        "click #cancelSecret": "clickedCancelSecret",
        "click #saveSecret": "clickedSaveSecret",

        //Token
        "click #editToken": "clickedEditToken",
        "click #cancelToken": "clickedCancelToken",
        "click #saveToken": "clickedSaveToken"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#editBuildingGmail-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
        });
        return this;
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clickedEditClientId: function (e) {
        e.preventDefault();
        this.$("#viewClientIdRow").addClass('hidden');
        this.$("#editClientIdRow").removeClass('hidden');
        this.$('#inputClientId').val(this.model.get('gmailClientId'));
    },
    clickedCancelClientId: function (e) {
        e.preventDefault();
        this.$("#viewClientIdRow").removeClass('hidden');
        this.$("#editClientIdRow").addClass('hidden');
    },
    clickedSaveClientId: function (e) {
        e.preventDefault();
        if (this.$('#inputClientId').val() == '') {
            return;
        }
        this.$("#viewClientIdRow").removeClass('hidden');
        this.$("#editClientIdRow").addClass('hidden');
        this.model.set('gmailClientId', this.$('#inputClientId').val());
        this.render();
        this.model.save();
    },

    clickedEditSecret: function (e) {
        e.preventDefault();
        this.$("#viewSecretRow").addClass('hidden');
        this.$("#editSecretRow").removeClass('hidden');
        this.$('#inputSecret').val(this.model.get('gmailSecret'));
    },
    clickedCancelSecret: function (e) {
        e.preventDefault();
        this.$("#viewSecretRow").removeClass('hidden');
        this.$("#editSecretRow").addClass('hidden');
    },
    clickedSaveSecret: function (e) {
        e.preventDefault();
        this.$("#viewSecretRow").removeClass('hidden');
        this.$("#editSecretRow").addClass('hidden');
        this.model.set('gmailSecret', this.$('#inputSecret').val());
        this.render();
        this.model.save();
    },

    clickedEditToken: function (e) {
        e.preventDefault();
        this.$("#viewTokenRow").addClass('hidden');
        this.$("#editTokenRow").removeClass('hidden');
        this.$('#inputToken').val(this.model.get('gmailToken'));
    },
    clickedCancelToken: function (e) {
        e.preventDefault();
        this.$("#viewTokenRow").removeClass('hidden');
        this.$("#editTokenRow").addClass('hidden');
    },
    clickedSaveToken: function (e) {
        e.preventDefault();
        if (this.$('#inputToken').val() == '') {
            return;
        }
        this.$("#viewTokenRow").removeClass('hidden');
        this.$("#editTokenRow").addClass('hidden');
        this.model.set('gmailToken', this.$('#inputToken').val());
        this.render();
        this.model.save();
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});