﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var TrashItem = require('../models/trashItem');

module.exports = Backbone.Model.extend({
    defaults: {
        rawTitle: '',
        title: '',
        allSelected: false,
        open: false,
        selectedCount: 0,

        //Flag used to decide where to render rounded corners. Tied to the models position in the collection
        index: Common.CollectionLocation_single
    },

    initialize: function (options) {
        var self = this;
        this.TrashCollection = Backbone.Collection.extend({
            title: options.title,
            model: TrashItem,
            url: options.url,
            getDescription: options.getDescription,
            restore: options.restore,
            completedRestore: options.completedRestore,
        });
        this.TrashItems = new this.TrashCollection();
        this.TrashItems.fetch();
        this.listenTo(this.TrashItems, "change:selected", this.changedSelected);
        this.listenTo(this.TrashItems, "remove", this.removedTrashItem);
        this.listenTo(this.TrashItems, "sync", this.syncedTrashItem);
        if (options.setup) {
            options.setup(this);
        }
    },

    syncedTrashItem: function () {
        this.recalcTitle();
        this.set('rawTitle', this.TrashItems.title);
    },

    removedTrashItem: function () {
        this.recalcTitle();
        this.changedSelected();
    },

    recalcTitle: function () {
        var title = this.TrashItems.title;
        if (this.TrashItems.length > 0) {
            title += " (" + this.TrashItems.length + ")";
        }
        this.set('title', title);
    },

    changedSelected: function () {
        var selected = this.TrashItems.where({ selected: true });
        if (selected) {
            this.set('selectedCount', selected.length);
        }
        else {
            this.set('selectedCount', 0);
        }
    },

    restoreSelected: function () {
        var self = this;
        var selected = this.TrashItems.where({ selected: true });
        if (selected) {
            selected.forEach(function (trashItem) {
                self.TrashItems.remove(trashItem);
                self.TrashItems.restore(trashItem);
            });
            this.TrashItems.completedRestore();
            this.set('open', false);
        }
    },

    deleteSelected: function () {
        var self = this;
        var selected = this.TrashItems.where({ selected: true });
        if (selected) {
            selected.forEach(function (trashItem) {
                trashItem.destroy();
            });
            this.set('open', false);
        }
    },

    toggleAllSelected: function () {
        var allSelected = !this.get('allSelected');
        this.set('allSelected', allSelected);
        this.TrashItems.forEach(function (trashItem) {
            trashItem.set('selected', allSelected);
        });
    },

    toggleOpen: function () {
        if (this.TrashItems.length > 0) {
            var open = !this.get('open');
            this.set('open', open);
        }
    }
});