﻿'use strict';
var $ = require('jquery');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/menu.css');
require('jquery-ui/themes/base/datepicker.css');
require('jquery-ui/themes/base/theme.css');
require('jquery-ui-bundle');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PeriodView=' + this.cid);

        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "change .datepicker:eq(0)": "userChangedLeave",
        "change .datepicker:eq(1)": "userChangedBack"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#period-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        $(function () {
            if (self.model.get('isMultiCondo') == true) {
                self.$("#condoLabel").removeClass('hidden');
                self.$("#condoLabel").html(self.model.get('condoLabel'));
            }
            self.$(".datepicker:eq(0)").datepicker({ minDate: 0 });
            self.$(".datepicker:eq(1)").datepicker({ minDate: 0 });
        });
        return this;
    },

    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedLeave: function () {
        this.model.set('startDate', this.$(".datepicker:eq(0)").val());

        //Set minDate for 'back' to one day after 'leave'
        var leaveDate;
        var leave = $(".datepicker:eq(0)").datepicker('getDate');
        if (leave) {
            leaveDate = new Date(leave.getTime());
            leaveDate.setDate(leaveDate.getDate() + 1);
        }
        else {
            leaveDate = new Date();
        }
        this.$(".datepicker:eq(1)").datepicker('destroy');
        this.$(".datepicker:eq(1)").datepicker({ minDate: leaveDate });
        this.$(".datepicker:eq(1)").val('');
    },

    userChangedBack: function () {
        this.model.set('endDate', this.$(".datepicker:eq(1)").val());
    },


    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.$(".datepicker:eq(0)").val('');
        this.$(".datepicker:eq(1)").val('');
        this.model.set('startDate', '');
        this.model.set('endDate', '');
        this.model.set('isDeleted', true);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});