﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Log = require('../models/log');
var CheckinView = require('../views/checkinView');
var CheckinNotificationView = require('../views/checkinNotificationView');

module.exports = Backbone.Model.extend({
    defaults: {
        hostCondoPersonLink: '',
        host: '',
        hostNumber: '',
        hostName: '',
        message: '',
        guestName: '',
        condoLabel: '',
        canHostBeNotified: true
    },

    //Parameters: CondoPersonLink
    initialize: function (options) {
        this.condoPersonLink = options.condoPersonLink;
        this.set('condoLabel', this.condoPersonLink.get('label'));
        this.person = this.condoPersonLink.get('person');
        this.hostCondoPersonLink = this.condoPersonLink.get('hostCondoPersonLink');
        this.host = this.condoPersonLink.get('hostPerson');
        this.set('hostName', this.host.get('formal'));
        this.set('guestName', this.person.get('output'));
        if (this.host.get('phones').length > 0) {
            this.set('hostNumber', this.host.get('phones')[0].number);
        }
        this.set('canHostBeNotified', this.host.get('emails').length > 0);
        this.tryCheckin();
        this.result = null;
    },

    tryCheckin: function () {
        if (this.condoPersonLink.get('accessTypeId') == Common.AccessTypes_callFirstId) {
            //Show dialog
            var view = new CheckinView({ model: this });
            Common.appView.requestDialog(view);
            return;
        }
        else if (this.condoPersonLink.get('accessTypeId') == Common.AccessTypes_dontCallId) {
            this.hostPreapproved();
        }
        else if (this.condoPersonLink.get('accessTypeId') == Common.AccessTypes_allowedAccessId) {
            this.hostPreapproved();
        }
        else if (this.condoPersonLink.get('accessTypeId') == Common.AccessTypes_noAccessId) {
            this.hostPrerejected();
        }
    },

    rejectedByHost: function () {
        this.result = Common.Logs_guestCheckinRejectedByHostId;

        //Show dialog
        var view = new CheckinNotificationView({ model: this, allowed: false });
        Common.appView.requestDialog(view);
    },

    rejectedNoAnswer: function () {
        this.result = Common.Logs_guestCheckinRejectedHostUnavailableId;

        //Show dialog
        var view = new CheckinNotificationView({ model: this, allowed: false });
        Common.appView.requestDialog(view);
    },

    hostApproved: function () {
        this.result = Common.Logs_guestCheckinApprovedByHostId;

        this.condoPersonLink.set('star', false);
        this.condoPersonLink.save();

        //Show dialog
        var view = new CheckinNotificationView({ model: this, allowed: true });
        Common.appView.requestDialog(view);
    },
    hostPreapproved: function () {
        this.result = Common.Logs_guestCheckinPreApprovedByHostId;

        this.condoPersonLink.set('star', false);
        this.condoPersonLink.save();

        //Show dialog
        var view = new CheckinNotificationView({ model: this, allowed: true });
        Common.appView.requestDialog(view);
    },
    hostPrerejected: function () {
        this.result = Common.Logs_guestCheckinPreRejectedByHostId;

        //Show dialog
        var view = new CheckinNotificationView({ model: this, allowed: false });
        Common.appView.requestDialog(view);
    },
    notifyHost: function () {
        if (this.result) {
            var self = this;
            var log = new Log();
            log.set('logTypeId', this.result);
            log.set('condoPersonLinkId', this.hostCondoPersonLink.get('id'));
            log.set('staffGuestId', this.condoPersonLink.get('personId'));
            Common.Logs.add(this.model);
            log.save();
        }
    }
});