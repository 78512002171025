﻿'use strict';
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var CondoPersonLink = require('../models/condoPersonLink');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        condoLabel: 'Add condo',
        condoId: '',
        condoPersonLinkTypeId: Common.CondoPersonLinkTypes_ownerId,
        isResident: true,
        isPrimary: false,
        star: false,
        isOwnerOrTenant: false,
        isStaffOrGuestForThisType: false,
        hostCondoPersonLinkId: '',
        isNewType: false,
        edit: false,
        isMarkedForDeletion: false,
        isPrimaryConfigurable: false,
        isCondoSet: false
    },

    //options: always 'Person' 
    //options: for existing types, a link to 'CondoPersonLink' will be provided
    //
    //Usage:
    //- show access type for a specific 'CondoPersonLink'
    //- enable creation of new access type for a 'Person', where the person will be associated with another condo ('isNewType' will be set to TRUE)
    //  - the creation can be ANY role or will be limited to Staff/Guest if the 'Person' only has roles of that type
    //- enable edit of role of existing 'CondoPersonLink'
    initialize: function (options) {
        this.person = options.person;
        this.listenTo(this.person.CondoPersonLinks, "reset", this.resetPersonsCondoPersonLinks);
        this.listenTo(this.person.CondoPersonLinks, "add", this.addedPersonsCondoPersonLink);
        this.listenTo(this, "change:edit", this.changedEdit);
        this.listenTo(this, "change:condoPersonLinkTypeId", this.evaluatePersonType);

        if (options.condoPersonLink) {
            //Mode: view/edit existing CondoPersonLink
            this.condoPersonLink = options.condoPersonLink;
            var attrs = this.condoPersonLink.getServerAttributes();
            this.set(attrs);
            var condo = this.condoPersonLink.get('condo');
            if (condo) {
                this.setCondo(condo);
            }
            else {
                this.listenTo(this.condoPersonLink, "change:condo", this.changedCondo);
            }
        }
        else {
            //Mode: create new 'condoPersonLink', for the specified 'Person'            
            this.set('isNewType', true);
        }
        if (this.get('condoId')) {
            this.set('isCondoSet', true);
        }
        this.evaluatePersonType();
        this.setDefaults();
    },

    okToSave: function () {
        //Disallow deleting the last OwnerTenant for a condo
        if (this.get('isMarkedForDeletion') === true) {
            return this.condoPersonLink.allowDeletion();
        }

        //Disallow changing away from OwnerTenant if this is the last ownerTenant
        if (this.condoPersonLink && this.condoPersonLink.allowChangeTypeAndCondo(this.get('condoPersonLinkTypeId'), this.get('condoId')) === false) {
            return false;
        }

        return true;
    },

    isValidForCreation: function () {
        if (this.get('isMarkedForDeletion')) {
            return false;
        }
        if (this.get('condoId') === '') {
            return false;
        }
        if (this.get('isNewType') === false) {
            return false;
        }
        return true;
    },

    edit: function () {
        this.set('edit', true);
    },

    changedEdit: function (model, edit) {
        if (edit) {
            this.origProperties = this.getCurrentProperties();
        }
    },

    getCurrentProperties: function () {
        var current = _.clone(this.attributes);
        delete current.edit;
        delete current.person;
        delete current.condoPersonLink;
        return current;
    },

    hasChanged: function () {
        var current = this.getCurrentProperties();
        if (JSON.stringify(current) === JSON.stringify(this.origProperties)) {
            return false;
        }
        return true;
    },

    evaluatePersonType: function () {
        var isOwnerOrTenant = this.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId || this.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_tenantId;
        this.set('isOwnerOrTenant', isOwnerOrTenant);
        if (!isOwnerOrTenant) {
            this.set('isPrimaryConfigurable', false);
        }
    },

    resetPersonsCondoPersonLinks: function () {
        this.evaluatePersonType();
    },

    addedPersonsCondoPersonLink: function () {
        this.evaluatePersonType();
    },

    changedCondo: function (model, condo) {
        this.setCondo(condo);
    },

    cancel: function () {
        this.setDefaults();
        this.set('edit', false);
    },

    setDefaults: function () {
        this.set('isMarkedForDeletion', false);
        if (this.condoPersonLink) {
            //Render existing link/type
            this.set('condoPersonLinkTypeId', this.condoPersonLink.get('condoPersonLinkTypeId'));
            this.set('isResident', this.condoPersonLink.get('isResident') == true);
            this.set('star', this.condoPersonLink.get('star') == true);
            this.set('isStaffOrGuestForThisType', this.condoPersonLink.get('isStaffOrGuest'));
            var condo = this.condoPersonLink.get('condo');
            if (condo) {
                this.setCondo(condo);
            }
            this.set('hostCondoPersonLinkId', this.condoPersonLink.get('hostCondoPersonLinkId'));
        } else {
            //Create new link/type
            this.set('condoLabel', 'Add condo');
            this.set('condoId', '');
            this.set('isCondoSet', false)
            if (this.person.get('isAlsoOwnerOrTenant')) {
                this.set('condoPersonLinkTypeId', Common.CondoPersonLinkTypes_ownerId);
            }
            else {
                this.set('condoPersonLinkTypeId', Common.CondoPersonLinkTypes_guestId);
            }
        }
    },

    markForDeletion: function () {
        this.set('isMarkedForDeletion', true);
    },

    setCondoId: function (condoId) {
        var condo = Common.Condos.findWhere({ id: condoId });
        if (condo) {
            this.setCondo(condo);
        }
    },

    setCondo: function (condo) {
        this.set('condoLabel', condo.get('label'));
        this.set('condoId', condo.get('id'));
        this.set('isCondoSet', true);
    },

    save: function (attrs, options) {
        //Error handling
        if (!this.get('person')) {
            return;
        }

        //Deletion detection
        if (this.get('isMarkedForDeletion') === true) {
            if (this.condoPersonLink) {
                this.condoPersonLink.destroy();
                this.collection.remove(this);
            }
            else {
                //Prepare for next creation of new entry
                this.setDefaults();
            }
            return;
        }

        //Save or Create new?
        if (this.condoPersonLink) {

            //Save...
            if (this.hasChanged()) {
                this.condoPersonLink.setCondoId(this.get('condoId'));
                this.condoPersonLink.set('condoPersonLinkTypeId', this.get('condoPersonLinkTypeId'));
                this.condoPersonLink.set('isResident', this.get('isResident'));
                this.condoPersonLink.set('isPrimary', this.get('isPrimary'));
                this.condoPersonLink.set('star', this.get('star'));
                if (this.get('hostCondoPersonLinkId')) {
                    this.condoPersonLink.set('hostCondoPersonLinkId', this.get('hostCondoPersonLinkId'));
                }
                this.condoPersonLink.save(null, {
                    success: function () {
                        if (options && options.success) {
                            options.success();
                        }
                    }
                });
            }
            else {
                if (options && options.success) {
                    options.success();
                }
            }
        }
        else {
            //Create new...
            if (this.get('condoId') != '') {
                var accessTypeId = Common.AccessTypes_callFirstId;
                switch (this.get('condoPersonLinkTypeId')) {
                    case Common.CondoPersonLinkTypes_ownerId:
                    case Common.CondoPersonLinkTypes_tenantId:
                        accessTypeId = Common.AccessTypes_allowedAccessId;
                        break;
                    case Common.CondoPersonLinkTypes_formerResidentId:
                    case Common.CondoPersonLinkTypes_deceasedId:
                        accessTypeId = Common.AccessTypes_noAccessId;
                        break;
                }

                var hostCondoPersonLink;
                if (this.get('hostCondoPersonLinkId')) {
                    hostCondoPersonLink = Common.CondoPersonLinks.findWhere({ id: this.get('hostCondoPersonLinkId') });
                }
                var condoPersonLink = new CondoPersonLink({
                    accessTypeId: accessTypeId,
                    personId: this.person.get('id'),
                    condoId: this.get('condoId'),
                    isResident: this.get('isResident'),
                    isPrimary: this.get('isPrimary'),
                    condoPersonLinkTypeId: this.get('condoPersonLinkTypeId'),
                    hostCondoPersonLinkId: this.get('hostCondoPersonLinkId'),
                    hostCondoPersonLink: hostCondoPersonLink
                });
                Common.CondoPersonLinks.add(condoPersonLink);
                condoPersonLink.save();
            }
        }

        this.setDefaults();
        this.set('edit', false);
    }
});