﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var TrashItemView = require('../views/trashItemView');
var DeletePrompt = require('../models/deletePrompt');
var DeletePromptView = require('../views/deletePromptView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing TrashItemsView');
    },

    events: {
        "click #header": "clickedHeader",
        "click #all": "clickedAll",
        "click #delete": "clickedDelete",
        "click #restore": "clickedRestore"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#trashItems-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        var self = this;
        $(function () {
            self.listenTo(self.model.TrashItems, "sync", self.renderTrashItems);
            self.listenTo(self.model, "change:open", self.changedOpen);
            self.listenTo(self.model, "change:title", self.renderTitle);
            self.listenTo(self.model, "change:index", self.renderIndex);
            self.listenTo(self.model, "change:selectedCount", self.renderButtons);
            self.renderIndex();
            self.renderTrashItems();
            self.renderTitle();
        });
        return this;
    },

    renderIndex: function () {
        var index = this.model.get('index');
        if (index === Common.CollectionLocation_first) {
            this.$('#header').removeClass('boxInsert');
            this.$('#header').removeClass('boxTop');
            this.$('#header').removeClass('boxBottom');
            this.$('#header').addClass('boxTop');
        }
        else if (index === Common.CollectionLocation_middle) {
            this.$('#header').removeClass('boxInsert');
            this.$('#header').removeClass('boxTop');
            this.$('#header').removeClass('boxBottom');
            this.$('#header').addClass('boxInsert');
        }
        else if (index === Common.CollectionLocation_last) {
            this.$('#header').removeClass('boxInsert');
            this.$('#header').removeClass('boxTop');
            this.$('#header').removeClass('boxBottom');
            this.$('#header').addClass('boxBottom');
        }
        else if (index === Common.CollectionLocation_single) {
            this.$('#header').removeClass('boxInsert');
            this.$('#header').removeClass('boxTop');
            this.$('#header').removeClass('boxBottom');
            this.$('#header').addClass('boxTop');
            this.$('#header').addClass('boxBottom');
        }
    },

    renderTitle: function () {
        this.$('#title').html(this.model.get('title'));
    },

    renderTrashItems: function () {
        var self = this;
        this.$('#trashList').html('');
        this.model.TrashItems.forEach(function (trashItem) {
            var view = new TrashItemView({ model: trashItem });
            self.$('#trashList').append(view.render().el);
        });
    },

    renderButtons: function () {
        var count = this.model.get('selectedCount');
        if (count > 0) {
            this.$('#trashItemsButtonRow').removeClass('hidden');
            this.$('#restore').html('Restore (' + count + ')');
            this.$('#delete').html('Delete (' + count + ')');
        }
        else {
            this.$('#trashItemsButtonRow').addClass('hidden');
        }
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedOpen: function (model, open) {
        if (open) {
            var el = this.$('#content'),
                curHeight = el.height(),
                autoHeight = el.css('height', 'auto').height();
            el.height(curHeight).animate({ height: autoHeight }, 200);
        }
        else {
            this.$('#content').animate({ height: 0 }, 200);
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedAll: function (e) {
        e.preventDefault();
        this.model.toggleAllSelected();
    },

    clickedHeader: function (e) {
        e.preventDefault();
        this.model.toggleOpen();
    },

    clickedDelete: function (e) {
        e.preventDefault(); 
        e.stopPropagation();

        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('selectedCount') + " " + this.model.get('rawTitle') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    clickedRestore: function (e) {
        e.preventDefault(); 
        e.stopPropagation();
        this.model.restoreSelected();
    },

    delete: function () {
        this.model.deleteSelected();
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});