﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SmallPersonView=' + this.cid);

        this.listenTo(this.model, 'change:condoPersonLinkTypeId', this.changedCondoPersonLinkTypeId);
    },

    events: {
        //User trigger: Select person type from dropdown
        "click .personTypeItem": "userChangedCondoPersonLinkType",
        "click #personExProfileTypeMenu": "openPersonTypeMenu",

        //Model: user input --> update mmodel 
        "blur #firstname": "blurFirstname",
        "focus #firstname": "focusFirstname",
        "blur #lastname": "blurLastname",
        "focus #lastname": "focusLastname",

        //Title
        "click #titleMenu": "openTitleMenu",
        "click .dropdownTitleItem": "clickedTitleItem",
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#smallPerson-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.renderAsCreateNew();

            //Separator line: hidden always if first
            if (self.model.has('isFirst') && self.model.has('isFirst') == true) {
                self.$('#profileLineSeparator').hide();
            }
        });
        return this;
    },

    renderAsCreateNew: function () {
        //Clean content in UI
        this.$('input:eq(0)').val('');
        this.$('input:eq(1)').val('');

        //Set default data
        this.model.set('condoPersonLinkTypeId', Common.CondoPersonLinkTypes_ownerId);
    },



    /////////////////////////////////////
    // Menu: person type
    /////////////////////////////////////
    userChangedCondoPersonLinkType: function (e) {
        e.preventDefault();
        var condoPersonLinkTypeId = Common.CondoPersonLinkTypes_ownerId;
        if (e) {
            condoPersonLinkTypeId = e.currentTarget.getAttribute('name');
        }
        this.model.set('condoPersonLinkTypeId', condoPersonLinkTypeId);
    },

    openPersonTypeMenu: function (e) {
        if (this.$('#personTypeMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#personTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },


    /////////////////////////////////////
    // Menu: title
    /////////////////////////////////////
    openTitleMenu: function (e) {
        if (this.$('#titleMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#titleMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    clickedTitleItem: function (e) {
        e.preventDefault();
        var title = e.target.innerText;
        this.model.set('title', title);
        this.$("#titleLabel").html(title);
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    focusFirstname: function () {
        var self = this;
        this.$('input:eq(1)').off();
        this.$('input:eq(1)').on('input', function () {
            self.userChangedFirstname();
        });
    },

    blurFirstname: function () {
        this.$('input:eq(1)').off();
    },

    focusLastname: function () {
        var self = this;
        this.$('input:eq(0)').off();
        this.$('input:eq(0)').on('input', function () {
            self.userChangedLastname();
        });
    },

    blurLastname: function () {
        this.$('input:eq(0)').off();
    },

    userChangedLastname: function () {
        this.model.set('lastname', this.$("input:eq(0)").val());
    },

    userChangedFirstname: function () {
        this.model.set('firstname', this.$('input:eq(1)').val());
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedCondoPersonLinkTypeId: function (model, value) {
        var str = Common.CondoPersonLinkTypes.getNameFromId(value);
        this.$('#personTypeLabel').html(str);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();

        this.$('input:eq(0)').off();
        this.$('input:eq(1)').off();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});