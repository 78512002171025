'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        output: '',
        condoPersonLinkOutput: ''
    },

    urlRoot: '',

    initialize: function (options) {
        //Store properties used for matching
        this.origPerson = options.origPerson;
        this.isPersonMatch = options.isPersonMatch;
        this.isAliasMatch = options.isAliasMatch;
        this.CondoPersonLinks = options.CondoPersonLinks;
        this.noAccess = options.noAccess;
        this.staffOrGuest = options.staffOrGuest;

        //Set property for rendered output
        this.set('output', options.output);
        this.set('condoPersonLinkOutput', options.condoPersonLinkOutput);
    },

    isMatch: function (person, isAliasMatch, isPersonMatch, condoPersonLink) {
        if (person != this.origPerson) {
            return false;
        }
        if (isPersonMatch == true && isPersonMatch == this.isPersonMatch) {
            return true;
        }
        if (isAliasMatch == true && isAliasMatch == this.isAliasMatch) {
            return true;
        }
        if (isPersonMatch == false & isAliasMatch == false && condoPersonLink == this.condoPersonLink) {
            return true;
        }
        return false;
    }
});