﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var SearchLogMatch = require('../models/searchLogMatch');
var Cookies = require('js-cookie');

module.exports = Backbone.Collection.extend({
    model: SearchLogMatch,

    url: '',
    initialize: function () {
        this.idFilter = '';
        this.datetimeFilter = '';
        this.logTypeIdFilter = '';
        this.commentFilter = '';
        this.includeDeleted = false;

        //Properties Used in pagenation
        this.pageSize = 20;     //Number of records on eachpage
        this.pageIndex = 0;
        this.recordCount = 0;  //TODO: change to set based on server feedback
    },

    load: function (keepFilter) {
        this.unload();
        if (!keepFilter) {
            this.idFilter = '';
            this.datetimeFilter = '';
            this.logTypeIdFilter = '';
            this.commentFilter = '';
        }
        var self = this;
        Common.Logs.forEach(function (log) {
            self.addLog(log);
        });
        if (keepFilter) {
            this.applyFilter();
        }
        else {
            this.reportPageInfo();
        }
        this.listenTo(Common.Logs, 'add', this.addedLog);
        this.listenTo(Common.Logs, 'remove', this.removedLog);
        this.listenTo(Common.Logs, 'change:id', this.changedId);
        this.listenTo(Common.Logs, 'change:datetime', this.changedDatetime);
    },

    unload: function () {
        this.stopListening();
        this.reset();
    },

    setIdFilter: function (idFilter) {
        if (this.idFilter != idFilter) {
            this.idFilter = idFilter;
            this.applyFilter();
        }
    },

    setDatetimeFilter: function (datetimeFilter) {
        if (this.datetimeFilter != datetimeFilter) {
            this.datetimeFilter = datetimeFilter;
            this.applyFilter();
        }
    },

    setLogTypeIdFilter: function (logTypeIdFilter) {
        this.logTypeIdFilter = logTypeIdFilter;
        this.applyFilter();
    },

    setCommentFilter: function (commentFilter) {
        if (this.commentFilter != commentFilter) {
            this.commentFilter = commentFilter;
            this.applyFilter();
        }
    },

    isFilterEnabled() {
        return (this.idFilter !== '' || this.datetimeFilter !== '' || this.logTypeIdFilter !== '' || this.commentFilter !== '');
    },

    applyFilter: function () {
        this.pageIndex = 0;
        var self = this;
        if (this.isFilterEnabled() === true) {
            this.slice().reverse().forEach(function (searchLogMatch) {
                searchLogMatch.applyFilter();
            });
        }
        else {
            this.forEach(function (searchLogMatch) {
                searchLogMatch.setFilteredOff();
            });
        }
        this.loadPage();
    },

    clearFilter: function () {
        this.pageIndex = 0;
        this.idFilter = '';
        this.datetimeFilter = '';
        this.logTypeIdFilter = '';
        this.commentFilter = '';


        this.forEach(function (searchLogMatch) {
            searchLogMatch.setFilteredOff();
        });

        this.loadPage();
    },

    addedLog: function (log) {
        if (log.get('id')) {
            this.addLog(log);
        }
    },

    removedLog: function (log) {
        var match = this.findWhere({ id: log.id });
        this.remove(match);
    },

    addLog: function (log) {
        var match = new this.model({ log: log });
        this.add(match);
    },

    changedId: function (log) {
        this.addLog(log);
    },

    changedDatetime: function (log) {
        var match = this.findWhere(function (m) {
            return m.log == log;
        });
        if (match) {
            match.set('datetime', log.get('datetime'));
        }
    },

    nextPage: function () {
        if (this.total) {
            var maxPage = this.total / this.pageSize - 1;
            if (this.pageIndex > maxPage) {
                return;
            }
        }
        this.pageIndex++;
        this.loadPage();
    },

    prevPage: function () {
        if (this.pageIndex <= 0) {
            this.pageIndex = 0;
            return;
        }
        this.pageIndex--;
        this.loadPage();
    },

    loadPage: function () {
        var offset = this.pageSize * this.pageIndex;
        if (offset < 0) {
            offset = 0;
        }
        var self = this;
        Common.Logs.fetch({
            data: {
                limit: this.pageSize,
                offset: offset,
                idFilter: self.idFilter,
                datetimeFilter: self.datetimeFilter,
                logTypeIdFilter: self.logTypeIdFilter,
                commentFilter: self.commentFilter
            },
            success: function () {
                self.reportPageInfo();
            }
        });
    },

    reportPageInfo: function () {
        this.total = Cookies.get('logCount');
        if (this.total) {
            var start = this.pageIndex * this.pageSize + 1;
            var end = start + this.pageSize - 1;
            if (end > this.total) {
                end = this.total;
            }
            this.pageInfo = start + "-" + end + " of " + this.total;

            this.trigger('changedPageInfo');
        }
    },

    getFiltered: function (source, filter) {
        if (filter === "") {
            return "";
        }
        filter = filter.toLowerCase();
        var filtered = "";
        var startPos = 0;

        //Get initial filter pos
        var matchPos = source.toLowerCase().indexOf(filter);
        if (matchPos >= 0) {
            while (matchPos >= 0) {
                filtered = filtered + source.substring(startPos, matchPos);
                filtered = filtered + "<span>" + source.substring(matchPos, matchPos + filter.length) + "</span>";
                startPos = matchPos + filter.length;
                matchPos = source.toLowerCase().indexOf(filter, startPos);
            }
            filtered = filtered + source.substring(startPos, source.length);
            return filtered;
        }
        else {
            return "";
        }
    },

    highlightNext: function () {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') === true;
        });

        //Move to first or next or none
        if (currentlyHighlighted === null) {
            if (this.models.length > 0) {
                this.models[0].set('isHighlighted', true);
            }
        }
        else {
            var idx = this.models.indexOf(currentlyHighlighted);
            if (idx > -1) {
                idx++;
                if (idx < this.models.length) {
                    currentlyHighlighted.set('isHighlighted', false);
                    this.models[idx].set('isHighlighted', true);
                }
            }
        }
    },

    highlightPrevious: function () {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') === true;
        });

        if (currentlyHighlighted !== null) {
            currentlyHighlighted.set('isHighlighted', false);
            var idx = this.models.indexOf(currentlyHighlighted);
            idx--;
            if (idx > -1) {
                this.models[idx].set('isHighlighted', true);
            }
        }
    },

    selectHighlighted: function (hostView) {
        //Locate currently highlighted
        var currentlyHighlighted = this.findWhere(function (m) {
            return m.get('isHighlighted') === true;
        });
        if (currentlyHighlighted) {
            currentlyHighlighted.set('isSelected', hostView);
        }
    }
});