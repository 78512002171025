/////////////////////////////////////
// INTRO
//
// This is a generic view used to capture input 
// and present a list of matching logs.
//
// This view will be created as part of the app and used as a utility for any other view
// When the view is used, its called from 
//
//      searchLogListView.enable(
//          host:  the view hosting the input element. Required for jqyery lookup of the DOM element
//          viewClassName:  the output will be rendered with a view of this class name, e.g. '#logList'
//          renderListDomId: the output will be rendered in this DOM element
//      )
//
//      searchLogListView.load(
//          inputDomIdx: the input will be taken from this DOM element index (count 'input' elements inside the view)
//          propertyName: the name of the property (id, datetime or comment)
//      )
//
// The model for matching search results SearchLogMatch
// This model will contain HTML rendered output fiels as well as 'isHighlighted' field
// 
// A view rendering SearchLogMatch should 
// - set isHighlighted when the mouse enter, and delete it when the mouse leaves
// - listen to change in isHighlighted  and use this to trigger highlight (this class will trigger highlight caused by arrow key up/down)
// - listen for click on view, and when it happens, set isSelected on the model. 
//
// A view rendering the input element should
// - call searchLogListView.load() when input get focus
// - subscribe to SearchLogMatch: changed:isSelected
//
/////////////////////////////////////
'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SearchLogListView');

        //When the input DOM element loose focus, it will call requestClosePanel(). After a timeout, the panel must close.
        //We need to delay the closing as a click inside the panel would be missed if we closed right away.
        this.closeTimer = null;

        //Keep track of the inputDomIdx we are associated with
        this.propertyName = null;
        this.inputDomIdx = null;
        this.host = null;
        this.viewClassName = null;
        this.renderListDomId = null;

        this.highlighted = null;
        this.highlightedAlias = null;
        this.log = null;
        this.views = [];
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#searchLogList-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        return this;
    },

    addLogToUi: function (searchLogMatch) {
        var view = new Common[this.viewClassName]({ model: searchLogMatch, host: this.host });
        this.views.push(view);
        var el = view.render().el;
        var index = this.getUiIndex(searchLogMatch.log.get("serverTimestamp"));
        this.insertAtIndex(index, el);
        if (this.logId === searchLogMatch.get('id')) {
            this.selectedView = el;
        }
    },

    insertAtIndex(index, el) {
        if (index === 0) {
            $(this.renderListDomId).prepend(el);
            return;
        }
        $(this.renderListDomId + " >div:nth-child(" + (index) + ")").after(el);
    },

    getUiIndex(serverTimestamp) {
        var index = 0;
        $(this.renderListDomId).children('div').each(function () {
            if ($(this).attr('name') < serverTimestamp) {
                return index;
            }
            index++;
        });
        return index;
    },


    /////////////////////////////////////
    // Collection events
    /////////////////////////////////////
    addedSearchLogMatch: function (searchLogMatch) {
        this.addLogToUi(searchLogMatch);
    },


    /////////////////////////////////////
    // Load and release
    /////////////////////////////////////
    enable: function (host, viewClassName, renderListDomId, logId) {
        this.listenTo(Common.SearchLogMatches, 'add', this.addedSearchLogMatch);
        var self = this;
        this.host = host;
        this.viewClassName = viewClassName;
        this.renderListDomId = renderListDomId;
        this.logId = logId;
        //TODO: determine how to get pages from server and then print in UI, and THEN use this data to set correct range in fetch calls into 'limit' and 'offset'
        Common.Logs.fetch({
            data: {
                limit: Common.SearchLogMatches.pageSize
            },
            reset: true,
            success: function () {
                if (logId) {
                    var keepFilter = true;
                }
                Common.SearchLogMatches.load(keepFilter);
                if (self.logId && self.selectedView) {
                    self.selectedView.scrollIntoView();
                }
            }
        });

    },

    disable: function () {
        this.stopListening(Common.SearchLogMatches);
        //Dispose all views created by this class on behalf of the now disconnected hostView
        this.views.forEach(function (view) {
            view.destroy();
        });
        this.views = [];
        this.selectedView = null;
        this.logId = null;
        this.unload();
    },

    setLogTypeIdFilter: function (logTypeId) {
        Common.SearchLogMatches.setLogTypeIdFilter(logTypeId);
    },

    load: function (inputDomIdx, includeDeleted, propertyName) {
        //No action if same input element loads again
        if (this.propertyName === propertyName) {
            return;
        }

        //Make sure are always unload prior to any loading
        this.unload();

        this.inputDomIdx = inputDomIdx;
        this.propertyName = propertyName;
        if (includeDeleted) {
            Common.SearchLogMatches.includeDeleted = true;
        }
        else {
            Common.SearchLogMatches.includeDeleted = false;
        }

        //Subscribe to events for the element. Make sure it's not done until the DOM is loaded
        var self = this;
        $(function () {
            self.host.$('input:eq(' + self.inputDomIdx + ')').off();
            self.host.$('input:eq(' + self.inputDomIdx + ')').on('input', function () { self.applyFilter(); });
            self.host.$('input:eq(' + self.inputDomIdx + ')').on('keydown', function (e) { self.keyAction(e); });
        });
    },

    unload: function (propertyName) {
        //Don't unload panel already taken by another input element
        if (propertyName) {
            if (this.propertyName !== propertyName) {
                return;
            }
        }

        //Unsubscribe to events
        if (this.propertyName !== null) {
            this.host.$('input:eq(' + this.inputDomIdx + ')').off('input');
            this.host.$('input:eq(' + this.inputDomIdx + ')').off('blur');
            this.confirmClosePanel();
            this.propertyName = null;
        }
    },

    confirmClosePanel: function () {
        clearInterval(this.closeTimer);
    },


    /////////////////////////////////////
    // Input (key)
    /////////////////////////////////////
    keyAction: function (e) {
        var code = e.keyCode || e.which;
        if (code === 27) {
            Common.SearchLogMatches.reset();
        }
        if (code === 13) {
            Common.SearchLogMatches.selectHighlighted(this.host);
            this.confirmClosePanel();
            e.preventDefault();
        }
        if (code === 40) {
            Common.SearchLogMatches.highlightNext();
        }
        if (code === 38) {
            Common.SearchLogMatches.highlightPrevious();
        }
    },


    /////////////////////////////////////
    // Filtering
    /////////////////////////////////////
    applyFilter: function () {
        var inputElement = this.host.$('input:eq(' + this.inputDomIdx + ')');

        //Run filter on change of input
        var filter = inputElement.val();
        switch (this.propertyName) {
            case "id":
                Common.SearchLogMatches.setIdFilter(filter);
                break;
            case "datetime":
                Common.SearchLogMatches.setDatetimeFilter(filter);
                break;
            case "comment":
                Common.SearchLogMatches.setCommentFilter(filter);
                break;
        }
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        this.host.$('input:eq(' + self.inputDomIdx + ')').off();

        this.unload();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});