﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var DeletePrompt = require('../models/deletePrompt');
var EditBuildingNameView = require('../views/editBuildingNameView');
var EditBuildingAccountsView = require('../views/editBuildingAccountsView');
var EditBuildingNotificationsView = require('../views/editBuildingNotificationsView');
var EditBuildingGmailView = require('../views/editBuildingGmailView');
var DeletePromptView = require('../views/deletePromptView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing EditBuildingView');

        this.views = [];
    },

    events: {
        //Mouse over effect
        "mouseover .click": "requestHighlight",
        "mouseleave .click": "releaseHighlight",

        //Links
        "click #nameAddressEmail": "clickedNameAddressEmail",
        "click #accountsStaff": "clickedAccountsStaff",
        "click #emailNotifications": "clickedEmailNotifications",
        "click #gmail": "clickedGmail",

        //Delete
        "click #delete": "clickedDelete"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#editBuilding-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        $("#pageTitle").html(this.model.get('name') + ": settings");
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.renderDivs();
        });
        return this;
    },

    renderDivs: function () {
        var view = new EditBuildingNameView({ model: this.model });
        this.views.push(view);
        this.$('#nameDiv').html(view.render().el);
        this.$('#nameDiv').height(0);

        view = new EditBuildingAccountsView({ model: this.model });
        this.views.push(view);
        this.$('#accountsDiv').html(view.render().el);
        this.$('#accountsDiv').height(0);

        view = new EditBuildingNotificationsView({ model: this.model });
        this.views.push(view);
        this.$('#notificationsDiv').html(view.render().el);
        this.$('#notificationsDiv').height(0);

        view = new EditBuildingGmailView({ model: this.model });
        this.views.push(view);
        this.$('#gmailDiv').html(view.render().el);
        this.$('#gmailDiv').height(0);
    },

    requestHighlight: function (e) {
        e.currentTarget.classList.add('boxHighlight');
    },
    releaseHighlight: function (e) {
        e.currentTarget.classList.remove('boxHighlight');
    },

    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clickedNameAddressEmail: function (e) {
        e.preventDefault();
        this.clickedHeader('#nameDiv');
    },

    clickedHeader: function (elemId) {
        if (this.current == elemId) {
            this.hideBlock(elemId);
            this.current == null;
            return;
        }
        if (this.current) {
            this.hideBlock(this.current);
            this.current == null;
        }
        this.showBlock(elemId);
    },

    hideBlock: function (elemId) {
        this.$(elemId).animate({ height: 0 }, 200);
        this.current = null;
    },

    showBlock: function (elemId) {
        this.current = elemId;

        var el = this.$(elemId),
            curHeight = el.height(),
            autoHeight = el.css('height', 'auto').height();
        el.height(curHeight).animate({ height: autoHeight }, 200);
    },

    clickedAccountsStaff: function (e) {
        e.preventDefault();
        this.clickedHeader('#accountsDiv');
    },

    clickedEmailNotifications: function (e) {
        e.preventDefault();
        this.clickedHeader('#notificationsDiv');
    },

    clickedGmail: function (e) {
        e.preventDefault();
        this.clickedHeader('#gmailDiv');
    },

    clickedDelete: function (e) {
        e.preventDefault();

        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('name') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    delete: function () {
        if (this.model) {
            this.model.destroy();
            Common.dataSetupRedirector.resetBuilding();
            Common.Buildings.fetch({
                success: function () {
                    Common.Condos.fetch();
                }
            });
            Common.Router.navigate("#", { trigger: true });
        }
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.views.forEach(function (view) {
            view.destroy();
        });
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});