﻿//Import references to libraries (they are loaded in vendor.js)
import backbone from "backbone";
import Underscore from "underscore";
var BackboneValidation = require("backbone-validation");
import Croppie from "croppie";
import $ from 'jquery';

//Local JS files
import Common from './common';
import AppFirstSetup from './appFirstSetup';
require("./index.css");


import Validator from './util/validator';
import Util from './util/util';

import Accesss from "./collections/accesss";
import AccessTypes from "./collections/accessTypes";
import Accounts from "./collections/accounts";
import Aways from "./collections/aways";
import Buildings from "./collections/buildings";
import Comments from "./collections/comments";
import CondoPersonLinks from "./collections/condoPersonLinks";
import CondoPersonLinkTypes from "./collections/condoPersonLinkTypes";
import Persons from "./collections/persons";
import Condos from "./collections/condos";
import Logs from "./collections/logs";
import MainMenuEntries from "./collections/mainMenuEntries";
import SearchPersonExMatches from "./collections/searchPersonExMatches";
import SearchLogMatches from "./collections/searchLogMatches";
import Emails from "./collections/emails";
import Phones from "./collections/phones";
import Photos from "./collections/photos";
import Types from "./collections/types";
import MailDeliveryPreferences from "./collections/mailDeliveryPreferences";
import PackageDeliveryPreferences from "./collections/packageDeliveryPreferences";
import Periods from "./collections/periods";
import Guests from "./collections/guests";

import Access from "./models/access";
import Account from "./models/account";
import Away from "./models/away";
import Building from "./models/building";
import BuildingAccount from "./models/buildingAccount";
import Log from "./models/log";
import Checkin from "./models/checkin";
import Comment from "./models/comment";
import Condo from "./models/condo";
import CondoPersonLink from "./models/condoPersonLink";
import DeletePrompt from "./models/deletePrompt";
import Email from "./models/email";
import LogManager from "./models/logManager";
import MailDeliveryPreference from "./models/mailDeliveryPreference";
import MainMenuEntry from "./models/mainMenuEntry";
import PackageDeliveryPreference from "./models/packageDeliveryPreference";
import Period from "./models/period";
import Person from "./models/person";
import PersonToday from "./models/personToday";
import Phone from "./models/phone";
import Photo from "./models/photo";
import SearchLogMatch from "./models/searchLogMatch";
import SearchPersonExMatch from "./models/searchPersonExMatch";
import Type from "./models/type";
import Cropper from "./models/cropper";
import SearchCondo from "./models/searchCondo";
import CondoCreate from "./models/condoCreate";
import PersonProfile from "./models/personProfile";

import Router from "./routers/router";
import DataSetupRedirector from "./routers/dataSetupRedirector";

import CropperView from "./views/cropperView";
import SmallPersonView from "./views/smallPersonView";
import AccessView from "./views/accessView";
import AccountView from "./views/accountView";
import AddBuildingAccountView from "./views/addBuildingAccountView";
import AwayView from "./views/awayView";
import BuildingMenuEntryView from "./views/buildingMenuEntryView";
import DeletePromptView from "./views/deletePromptView";
import PromptView from "./views/promptView";
import CheckinView from "./views/checkinView";
import ChangeBuildingAccountPasswordView from "./views/changeBuildingAccountPasswordView";
import ChangePasswordView from "./views/changePasswordView";
import CheckinNotificationView from "./views/checkinNotificationView";
import CommentView from "./views/commentView";
import CondoThumbView from "./views/condoThumbView";
import CreateAccountView from "./views/createAccountView";
import CreateBuildingView from "./views/createBuildingView";
import DeliveryView from "./views/deliveryView";
import EditAccountView from "./views/editAccountView";
import EditBuildingGmailView from "./views/editBuildingGmailView";
import EditBuildingNameView from "./views/editBuildingNameView";
import EditBuildingNotificationsView from "./views/editBuildingNotificationsView";
import EmailView from "./views/emailView";
import EventView from "./views/eventView";
import LogView from "./views/logView";
//import LogEntryView from "./views/logEntryView";
import LoginView from "./views/loginView";
import LogLineView from "./views/logLineView";
import LogTodayView from "./views/logTodayView";
import MailDeliveryPreferenceView from "./views/mailDeliveryPreferenceView";
import MainMenuEntryView from "./views/mainMenuEntryView";
import MainMenuView from "./views/mainMenuView";
import PackageDeliveryPreferenceView from "./views/packageDeliveryPreferenceView";
import PeriodView from "./views/periodView";
import PersonExView from "./views/personExView";
import PersonProfileLineView from "./views/personProfileLineView";
import PersonTodayView from "./views/personTodayView";
import PhoneView from "./views/phoneView";
//import SearchPersonEntryView from "./views/searchPersonEntryView";
import SearchLogListView from "./views/searchLogListView";
import SearchPersonView from "./views/searchPersonView";
import SelectBuildingEntryView from "./views/selectBuildingEntryView";
import SelectBuildingView from "./views/selectBuildingView";
import TypeView from "./views/typeView";
import UspsView from "./views/uspsView";
import TodayView from "./views/todayView";
import SearchAccessView from "./views/searchAccessView";
import LogManagerView from "./views/logManagerView";
import GuestLineView from "./views/guestLineView";
import EditBuildingAccountView from "./views/editBuildingAccountView";
import BuildingView from "./views/buildingView";
import BuildingAccountView from "./views/buildingAccountView";
import SearchCondoView from "./views/searchCondoView";
import CondoCreateView from "./views/condoCreateView";
import CondoProfileView from "./views/condoProfileView";
import EditBuildingAccountsView from "./views/editBuildingAccountsView";
import EditBuildingView from "./views/editBuildingView";
import PersonProfileView from "./views/personProfileView";
import TrashItemsView from "./views/trashItemsView";
import TrashView from "./views/trashView";

import AppView from './views/appView';


var appView;

$(function () {

    'use strict';
    //Collections
    Common.AccessTypes = new AccessTypes();
    Common.Accounts = new Accounts();
    Common.Buildings = new Buildings();
    Common.CondoPersonLinks = new CondoPersonLinks();
    Common.CondoPersonLinkTypes = new CondoPersonLinkTypes();
    Common.Persons = new Persons();
    Common.Condos = new Condos();
    Common.Logs = new Logs();
    Common.MainMenuEntries = new MainMenuEntries();
    Common.SearchPersonExMatches = new SearchPersonExMatches();
    Common.SearchLogMatches = new SearchLogMatches();
    Common.dataSetupRedirector = new DataSetupRedirector();

    //Router
    Common.Router = new Router();

    //Models used in other collections
    Common.CondoPersonLink = CondoPersonLink;
    Common.Condo = Condo;

    //Start the **App**
    appView = new AppView();
    Common.appView = appView;

    //Start router (=navigation)
    Backbone.history.start();
});


