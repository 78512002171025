﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        buildingId: '',
        logTypeId: '',
        datetime: '',
        comment: '',
        condoPersonLinkId: '',
        staffGuestId: '',
        accountId: '',
        star: '0',
        isDeleted: '0'
    },

    urlRoot: Common.apiFolder + 'log/index.php',

    validation: {
        buildingId: {
            required: true
        }
    },

    initialize: function () {
    },

    //Try creating building
    create: function () {
        this.save();
    }
});