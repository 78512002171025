﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var CondoThumbView = require('../views/condoThumbView');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SearchCondoView');

        //Set building link (required for creation)
        var currentBuilding = Common.Buildings.findWhere({ isActive: true });
        if (currentBuilding) {
            this.model.set('buildingId', currentBuilding.get('id'));
        }
        this.listenTo(this.model, 'change:isCreating', this.changedIsCreating);
        this.listenTo(this.model, 'change:errorMessage', this.changedErrorMessage);
        this.listenTo(this.model, 'addedPerson', this.addedPerson);
        this.listenTo(this.model, 'clearedPersons', this.clearedPersons);

        //Set menu
        Common.MainMenuEntries.setSelected('');
    },

    events: {
        "click": "clicked",
        "click #createButton": "tryCreate",
        "click #cancel": "clickedCancel",

        //Model: user input --> update mmodel
        "blur #label": "inputBlur",
        "focus #label": "inputFocus"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#condoCreate-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.activate();
            self.model.clearPersons();
        });
        return this;
    },

    activate: function () {
        var self = this;
        self.$('input').focus();
        setTimeout(function () {
            self.$('input').focus();
        }, 200);
    },

    inputFocus: function () {
        var self = this;
        this.$('input:eq(0)').off();
        this.$('input:eq(0)').on('input', function () {
            self.inputChangedLabel();
        });
    },

    inputBlur: function () {
        this.$('input:eq(0)').off();
        this.inputChangedLabel();
        this.inputChangedLabel();
    },



    /////////////////////////////////////
    // User input --> model
    /////////////////////////////////////
    inputChangedLabel: function () {
        var val = this.$('#label').val();
        this.model.set('label', val);
        this.model.set('errorMessage', '');
        if (val === '') {
            this.$('#personList').addClass('hidden');
            this.model.clearPersons();
        }
        else {
            this.$('#personList').removeClass('hidden');
        }
    },


    /////////////////////////////////////
    // Persons
    /////////////////////////////////////
    addedPerson: function (view) {
        this.$('#personList').append(view.render().el);
    },

    clearedPersons: function () {
        this.$('#personList').html('');
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(".dropdown").fadeOut(100);
    },

    tryCreate: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        this.inputChangedLabel();
        this.model.tryCreate();
    },

    clickedCancel: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        Common.appView.closeDialog();
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedIsCreating: function (model, isCreating) {
        if (isCreating === false) {

            //Cleanup
            if (Common.appView.isDialog(this)) {
                Common.appView.closeDialog();
            }
            else {
                Common.Router.navigate("#searchCondo", { trigger: true });
            }
        }
    },

    changedErrorMessage: function () {
        this.$('#errorMessage').html(this.model.get('errorMessage'));
    },

    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.$('input:eq(0)').off();
        this.remove();

        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});