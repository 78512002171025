﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Building = require('../models/building');

module.exports = Backbone.Collection.extend({
    model: Building,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'building/index.php',

    setActive: function (building) {
        var self = this;
        var currentlyActiveBuilding = this.findWhere(function (b) {
            return b.get('isActive') == true;
        });
        if (currentlyActiveBuilding != building) {
            Common.dataSetupRedirector.resetBuilding();
            building.save({ "isActive": true }, {
                success: function () {
                    self.fetch({
                        success: function () {
                            Common.Accounts.fetch({
                                success: function () {
                                    Common.Condos.fetch({
                                        success: function () {
                                            Common.dataSetupRedirector.validateCurrentUrl();
                                            Common.Condos.fetch();
                                            Common.CondoPersonLinks.fetch();
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    },

    getActive: function () {
        return this.findWhere({ isActive: true });
    }
});