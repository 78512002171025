﻿'use strict';
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

//USAGE:
//-------------------------
//This object maintain reference to 'CondoPersonLinks' & 'Guests' collection of CondoPersonLink
//When this collection is updated, the 'Condo' object will raise a 'reset' event for 'CondoPersonLink'/'Guests', followed by an 'Add' event for each newly added CondoPersonLink
//This means subscriber must clear UI/models when a 'reset' event is received and must add new UI/models for each 'add' event
module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        label: '',
        buildingId: '',
        floorplan: '',
        comment: '',
        isDeleted: '0',

        //Local properties (might be set after time of creation as some are tied to collections not yet loaded)
        residentsLastnameSummary: '',
        isHidden: false,
        labelOutput: '',
        residentsLastnameSummaryOutput: '',
        tenantSummary: '',
        ownerSummary: '',
    },

    urlRoot: Common.apiFolder + 'condo/index.php',

    validation: {
        label: {
            required: true
        }
    },

    initialize: function () {
        //Collections for this model
        var self = this;
        this.filter = '';
        this.CondoPersonLinkList = Backbone.Collection.extend({
            model: Common.CondoPersonLink,
            url: Common.apiFolder + 'condoPersonLink/index.php'
        });
        this.CondoPersonLinks = new this.CondoPersonLinkList();
        this.Guests = new this.CondoPersonLinkList();

        this.syncedCondoPersonLink();

        //Set initial data used for output
        this.set('labelOutput', this.get('label'));
        this.set('residentsLastnameSummaryOutput', this.get('residentsLastnameSummary'));

        this.listenTo(Common.Condos, 'reset', this.resetCondos);

        this.listenTo(Common.CondoPersonLinks, 'sync', this.syncedCondoPersonLink);
        this.listenTo(Common.CondoPersonLinks, 'remove', this.removedCondoPersonLink);
        this.listenTo(Common.CondoPersonLinks, 'add', this.addedCondoPersonLink);
        this.listenTo(this.CondoPersonLinks, 'change:personOutput', this.changedPersonOutput);
        this.listenTo(this, 'change:residentsLastnameSummary', this.changedResidentsLastnameSummary);
    },

    resetCondos: function () {
        if (!this.collection) {
            this.stopListening();
        }
    },

    trimNonServerAttributes: function (attrs) {
        delete attrs.CondoPersonLinkList;
        delete attrs.CondoPersonLinks;
        delete attrs.Guests;
        return attrs;
    },

    getHostCondoPersonLink: function () {
        var self = this;
        var ownersOrTenants = this.CondoPersonLinks.where({ isOwnerOrTenant: true });
        if (ownersOrTenants.length === 1) {
            return ownersOrTenants[0];
        }
        if (ownersOrTenants.length > 1) {
            var primary = ownersOrTenants[0];
            var primaryGuestCount = 0;
            ownersOrTenants.forEach(function (ownerOrTenant) {
                var guestCount = self.Guests.where({ hostCondoPersonLinkId: ownerOrTenant.id }).length;
                if (primaryGuestCount < guestCount) {
                    primary = ownerOrTenant;
                    primaryGuestCount = guestCount;
                }
            });
            return primary;
        }
        return null;
    },


    /////////////////////////////////////
    // CondoPersonLink (maintain list & dependent data)
    /////////////////////////////////////
    syncedCondoPersonLink: function () {
        var self = this;
        //Initialize with list of CondoPersonLink
        this.CondoPersonLinks.reset();
        this.Guests.reset();
        Common.CondoPersonLinks.forEach(function (condoPersonLink) {
            self.addedCondoPersonLink(condoPersonLink);
        });
    },

    addedCondoPersonLink: function (condoPersonLink) {
        if (condoPersonLink.get('condoId') == this.id) {
            this.CondoPersonLinks.add(condoPersonLink);
            if (condoPersonLink.get('isStaffOrGuest')) {
                this.Guests.add(condoPersonLink);
            }
            this.rebuildSummary();
        }
    },

    removedCondoPersonLink: function (condoPersonLink) {
        if (condoPersonLink.get('condoId') == this.id) {
            condoPersonLink.destroy();
            this.rebuildSummary();
        }
    },

    changedPersonOutput: function (condoPersonLink, personOutput) {
        if (this.CondoPersonLinks.findWhere({ id: condoPersonLink.id })) {
            this.rebuildSummary();
        }
    },

    rebuildSummary: function () {
        var tenantSummary = '';
        var ownerSummary = '';
        var summary = '';
        this.CondoPersonLinks.forEach(function (condoPersonLink) {
            //Maintain summary of lastnames, separated by '/'
            var person = condoPersonLink.get('person');
            if (person) {
                if (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_tenantId) {
                    summary = tenantSummary;
                    if (!summary.includes(person.get('lastname'))) {
                        if (summary !== '') {
                            summary += " / ";
                        }
                        summary += person.get('lastname');
                    }
                    tenantSummary = summary;
                }
                if (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId) {
                    summary = ownerSummary;
                    if (!summary.includes(person.get('lastname'))) {
                        if (summary !== '') {
                            summary += " / ";
                        }
                        summary += person.get('lastname');
                    }
                    ownerSummary = summary;
                }
            }
        });

        if (tenantSummary == '') {
            this.set('residentsLastnameSummary', ownerSummary);
        }
        else {
            this.set('residentsLastnameSummary', tenantSummary);
        }
    },

    changedResidentsLastnameSummary: function (model, residentsLastnameSummary) {
        this.setFilter(this.filter);
    },


    /////////////////////////////////////
    // Filtering
    /////////////////////////////////////
    setFilter: function (filter) {
        if (filter == '') {
            this.set('isHidden', false);
            this.set('labelOutput', this.get('label'));
            this.set('residentsLastnameSummaryOutput', this.get('residentsLastnameSummary'));
        }
        else {
            var labelFiltered = Common.getFiltered(this.get('label'), filter);
            var personsFiltered = Common.getFiltered(this.get('residentsLastnameSummary'), filter);
            if (labelFiltered === '' && personsFiltered === '') {
                this.set('isHidden', true);
            }
            else {
                this.set('isHidden', false);
                if (labelFiltered === '') {
                    this.set('labelOutput', this.get('label'));
                }
                else {
                    this.set('labelOutput', labelFiltered);
                }
                if (personsFiltered === '') {
                    this.set('residentsLastnameSummaryOutput', this.get('residentsLastnameSummary'));
                }
                else {
                    this.set('residentsLastnameSummaryOutput', personsFiltered);
                }
            }
        }
        this.filter = filter;
    },


    /////////////////////////////////////
    // Deletion & creation
    /////////////////////////////////////
    delete: function () {
        this.destroy();

        //Callback
        if (this.deleted) {
            this.deleted();
        }
        else {
            Common.Router.navigate("#", { trigger: true });
        }
    },

    create: function () {
        this.save();
    },

    save: function (attrs, options) {

        options || (options = {});
        attrs || (attrs = _.clone(this.attributes));

        // Filter the data to send to the server (remove the objects created for internal logic)
        attrs = this.trimNonServerAttributes(attrs);

        options.data = JSON.stringify(attrs);

        // Proxy the call to the original save function
        return Backbone.Model.prototype.save.call(this, attrs, options);
    }
});