﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

//Account Model
//-----------
//Our **Account** model has same attributes as defined in the database
module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        repeatPassword: '',
        accountType: '',
        initials: '',
        emailSignature: '',
        accountTypeId: '',
        name: '',
        terms: '0',
        loggedIn: '0'
    },

    urlRoot: Common.apiFolder + 'account/index.php',

    validation: {
        email: {
            required: true,
            pattern: 'email'
        },
        firstname: {
            required: true
        },
        lastname: {
            required: true
        },
        password: {
            minLength: 3
        },
        repeatPassword: {
            equalTo: 'password',
            msg: 'The passwords do not match'
        },
        terms: {
            acceptance: true
        }
    },

    initialize: function () {
        this.createInitials();
        this.buildEmailSignature();
        this.listenTo(this, 'change:firstname', this.changedFirstname);
        this.listenTo(this, 'change:lastname', this.changedLastname);
        this.listenTo(this, 'change:accountTypeId', this.changedAccountTypeId);
        this.buildAccountType();
        this.buildName();
    },

    changedFirstname: function () {
        this.buildName();
    },

    changedLastname: function () {
        this.buildName();
    },

    changedAccountTypeId: function () {
        this.buildAccountType();
    },

    buildAccountType: function () {
        var accountType = Common.Accounts_Labels[this.get('accountTypeId')];
        this.set('accountType', accountType);
    },

    buildName: function () {
        this.set('name', this.get('firstname') + " " + this.get('lastname'));
    },

    //Try creating account
    create: function () {
        this.save({
            accountType: !this.get('loggedIn'),
            loggedIn: !this.get('loggedIn')
        });
    },

    buildEmailSignature: function () {
        var firstnameReplacement = "[firstname]";    //notification strings will replace this value with the firstname
        var lastnameReplacement = "[lastname]";    //notification strings will replace this value with the lastname
        this.set('emailSignature', this.get('emailSignature').replace(firstnameReplacement, this.get('firstname')).replace(lastnameReplacement, this.get('lastname')));
    },

    createInitials: function () {
        var initials = '';
        if (this.get('firstname').length > 0) {
            initials += this.get('firstname').charAt(0).toUpperCase();
        }
        var lastnameParts = this.get('lastname').split('-');
        if (lastnameParts.length > 0) {
            if (lastnameParts[0].length > 0) {
                initials += lastnameParts[0].charAt(0).toUpperCase();
            }
            if (lastnameParts.length > 1) {
                if (lastnameParts[1].length > 0) {
                    initials += lastnameParts[1].charAt(0).toUpperCase();
                }
            }
        }
        this.set('initials', initials);
    },
});