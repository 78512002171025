﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var CondoPersonLink = require('../models/condoPersonLink');
var DeletePrompt = require('../models/deletePrompt');
var PersonExView = require('../views/personExView');
var DeletePromptView = require('../views/deletePromptView');
var Util = require('../util/util');

module.exports = Backbone.View.extend({

    //Model: condo
    initialize: function (options) {
        console.log('Initializing CondoProfileView');

        this.views = [];
        //Tie view to follow collection of CondoPersonLink
        this.listenTo(this.model.Condos, 'reset', this.lostModel);
        this.listenTo(this.model.CondoPersonLinks, 'reset', this.resetCondoPersonLink);
        this.listenTo(this.model.CondoPersonLinks, 'add', this.addedCondoPersonLink);
        this.listenTo(this.model, 'change:label', this.changedLabel);

        //Set menu
        Common.MainMenuEntries.setSelected('');
    },

    events: {
        "click #deleteCondo": "deleteCondo",

        "click #editLabel": "editLabel",
        "click #cancelLabel": "cancelLabel",
        "click #saveLabel": "saveLabel",

        "click #editComment": "editComment",
        "click #cancelComment": "cancelComment",
        "click #saveComment": "saveComment",
        "click #deleteComment": "deleteComment",

        //Model: user input --> update mmodel
        "blur #labelEdit": "inputChangedLabel",
        "blur #commentEdit": "inputChangedComment"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#condoProfile-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.resetCondoPersonLink();
            var activeBuilding = Common.Buildings.getActive();
            if (activeBuilding && activeBuilding.get('accountTypeId') == Common.Accounts_propertyManagerId) {
                self.$('#addRemovePanel').removeClass('hidden');
            }
            self.renderCondo();

            //Create 'Addnew' entry for guests hosted by this person
            var view = new PersonExView({ condo: self.model });
            self.$("#addPerson").html(view.render().el);
        });
        return this;
    },

    addedCondoPersonLink: function (condoPersonLink) {
        var isFirst = false;
        if (this.views.length === 0) {
            isFirst = true;
        }
        var view = new PersonExView({ model: condoPersonLink, isFirst: isFirst });
        this.views.push(view);
        this.$("#personList").append(view.render().el);
    },

    renderCondo: function () {
        this.renderValidation(this.$('#label'));
        this.$('#pageTitle').html(this.model.get('label'));
        this.$('#label').html(this.model.get('label'));
        this.$('#comments').html(Util.jsonToBr(this.model.get('comment')));
    },

    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    deleteCondo: function (e) {
        e.preventDefault(); // Don't let this button submit the form

        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('label') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    delete: function () {
        this.model.destroy();
        var condoPersonLinks = this.model.CondoPersonLinks.models.slice(0);
        condoPersonLinks.forEach(function (condoPersonLink) {
            condoPersonLink.destroy();
        });
        Common.Router.navigate("#searchCondo", { trigger: true });
    },

    /////////////////////////////////////
    // Label: view/edit/save
    /////////////////////////////////////
    editLabel: function (e) {
        e.preventDefault();
        this.$("#viewLabelRow").addClass('hidden');
        this.$("#editLabelRow").removeClass('hidden');
        this.label = this.model.get('label');
        this.$('#labelEdit').val(this.label);
    },

    cancelLabel: function (e) {
        e.preventDefault();
        this.$("#viewLabelRow").removeClass('hidden');
        this.$("#editLabelRow").addClass('hidden');
    },

    saveLabel: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        this.$('#serverError').text("").hide();// Hide any servers on a new submit

        if (this.validate() == false) {
            return;
        }
        this.model.set('label', this.label);
        this.model.save();

        this.$("#viewLabelRow").removeClass('hidden');
        this.$("#editLabelRow").addClass('hidden');
        this.renderCondo();
    },

    inputChangedLabel: function () {
        this.label = this.$('#labelEdit').val();
    },


    /////////////////////////////////////
    // Comment: view/edit/save
    /////////////////////////////////////
    editComment: function (e) {
        e.preventDefault();
        this.$("#viewCommentRow").addClass('hidden');
        this.$("#editCommentRow").removeClass('hidden');
        this.comment = this.model.get('comment');
        this.$('#commentEdit').val(this.comment);
    },

    cancelComment: function (e) {
        e.preventDefault();
        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
    },

    saveComment: function (e) {
        e.preventDefault();
        this.model.set('comment', this.comment);
        this.model.save();

        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
        this.renderCondo();
    },

    deleteComment: function (e) {
        e.preventDefault();
        this.$('#commentEdit').val('');
        this.comment = '';
    },

    inputChangedComment: function () {
        this.comment = this.$('#commentEdit').val();
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedLabel: function (condo, label) {
        $('#pageTitle').html(label);
        $('#label').html(label);
    },

    resetCondoPersonLink: function () {
        this.views.forEach(function (view) {
            view.destroy();
        });
        this.views = [];
        this.$("#personList").html('');
        var self = this;
        this.model.CondoPersonLinks.forEach(function (condoPersonLink) {
            self.addedCondoPersonLink(condoPersonLink);
        });
    },

    lostModel: function () {
        //During loading, if Condos collection is reset, the Condo model will be discarded. If this model is used to render this view we need to reload
        Common.Router.navigate("#condo/" + this.model.id, { trigger: true });
    },

    /////////////////////////////////////
    // validation
    /////////////////////////////////////
    validate: function () {
        var $inp = this.$('#label');
        var $group = $inp.closest('.control-group');
        if (this.label && this.label == '') {
            this.renderValidation(this.$('#label'), "A unit number or name is required");
            return false;
        }
        else {
            this.renderValidation(this.$('#label'));
            return true;
        }
    },

    renderValidation(elem, error) {
        var $group = elem.closest('.control-group');
        if (error) {
            $group.addClass('has-error');
            $group.find('.help-block').html(error).removeClass('hidden');
        }
        else {
            $group.removeClass('has-error');
            $group.find('.help-block').html('').addClass('hidden');
        }
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});