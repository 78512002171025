﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    initialize: function () {
        this.accountIsLoaded = false;
        this.buildingIsLoaded = false;
        this.condoIsLoaded = false;
        this.listenTo(Common.Accounts, 'error', this.accountError);
        this.listenTo(Common.Buildings, 'error', this.buildingError);
        this.listenTo(Common.Accounts, 'sync', this.accountSync);
        this.listenTo(Common.Buildings, 'sync', this.buildingSync);
        this.listenTo(Common.Condos, 'sync', this.condoSync);
        this.listenTo(Common.Accounts, 'change:loggedIn', this.changedLoggedIn);
    },

    accountError: function () {
        // Redirect the to the login page.
        Common.Router.navigate("#login", { trigger: true });
    },

    buildingError: function () {
        Common.Router.navigate("#createBuilding", { trigger: true });
    },

    accountSync: function () {
        this.accountIsLoaded = true;
        this.validateCurrentUrl();
    },

    changedLoggedIn: function (account) {
        if (Common.LoggedInUser) {
            if (this.pendingRouter !== null) {
                Common.Router.navigate("#" + this.pendingRouter, { trigger: true });
            }
        }
    },

    buildingSync: function () {
        this.buildingIsLoaded = true;
        this.validateCurrentUrl();
    },

    condoSync: function () {
        if (this.condoIsLoaded === false && this.buildingIsLoaded === true && this.accountIsLoaded === true) {
            this.condoIsLoaded = true;

            //Now ALL data needed for the app is loaded
            this.validateCurrentUrl();

            //Redirect to supressed/pending URL (do NOT redirect to login when entering CondoIsValid)
            if (this.pendingRouter && this.pendingRouter != 'login') {
                Common.Router.navigate("#" + this.pendingRouter, { trigger: true });
            }
            else {
                Common.Router.navigate("#", { trigger: true });
            }
            this.pendingRouter = null;
        }
    },

    resetBuilding: function () {
        this.buildingIsLoaded = false;
        this.condoIsLoaded = false;
        Common.Condos.reset();
        Common.CondoPersonLinks.reset();
        Common.Persons.reset();
        Common.Buildings.forEach(function (building) {
            building.set('isActive', false);
        });
    },


    //Call this method when deleting a condo or deleting or creating a building or selecting a building.
    //It will trigger a redirect to a page to complete data creation
    validateCurrentUrl: function () {
        var path = Backbone.history.getFragment();

        //Preserve 'pendingRouter' as that should not be affected
        var pending = this.pendingRouter;

        //Run validation, this will trigger a redirect if needed, and will return to currently loaded URL once completed
        if (this.isNavigationAllowed(path)) {
            this.pendingRouter = pending;
            return true;
        }
        return false;
    },

    isNavigationAllowed: function (fullPath) {
        //Strip subpath away from current URL so that we get the router string
        var path = fullPath;
        if (path.indexOf("/") >= 0) {
            path = path.substring(0, path.indexOf("/"));
        }
        else if (path.indexOf("\\") >= 0) {
            path = path.substring(0, path.indexOf("\\"));
        }

        var pathUpperCase = path.toUpperCase();

        //We need a login completed
        if (!this.accountIsLoaded) {
            if (!this.pendingRouter) {
                if (path != 'loading') {
                    this.pendingRouter = fullPath;
                }
            }
            Common.Router.navigate("#loading", { trigger: true });
            return false;
        }
        if (!Common.LoggedInUser) {
            if (pathUpperCase == 'login'.toUpperCase() || pathUpperCase == 'createAccount'.toUpperCase()) {
                return true;
            }
            if (path != 'loading') {
                this.pendingRouter = fullPath;
            }
            Common.Router.navigate("#login", { trigger: true });
            return false;
        }
        if (pathUpperCase == 'login'.toUpperCase() || pathUpperCase == 'createAccount'.toUpperCase()) {
            return true;
        }

        //We need at least one building created
        if (!this.buildingIsLoaded) {
            if (!this.pendingRouter) {
                if (path != 'loading') {
                    this.pendingRouter = fullPath;
                }
            }
            Common.Router.navigate("#loading", { trigger: true });
            return false;
        }
        if (Common.Buildings.length == 0) {
            if (pathUpperCase == 'createBuilding'.toUpperCase()) {
                return true;
            }
            if (pathUpperCase == 'trash'.toUpperCase()) {
                return true;
            }
            if (path != 'loading') {
                this.pendingRouter = fullPath;
            }
            Common.Router.navigate("#createBuilding", { trigger: true });
            return false;
        }

        //We need an active building
        var activeBuilding = Common.Buildings.findWhere({ isActive: true });
        if (!activeBuilding) {
            if (Common.Buildings.length == 1) {
                var building = Common.Buildings[0];
                Common.Buildings.setActive(building);
            }
            else {
                if (pathUpperCase == 'selectBuilding'.toUpperCase()) {
                    return true;
                }
                if (pathUpperCase == 'trash'.toUpperCase()) {
                    return true;
                }
                if (path != 'loading') {
                    this.pendingRouter = fullPath;
                }
                Common.Router.navigate("#selectBuilding", { trigger: true });
                return false;
            }
        }
        if (pathUpperCase == 'selectBuilding'.toUpperCase()) {
            return true;
        }
        if (pathUpperCase == 'createBuilding'.toUpperCase()) {
            return true;
        }

        //We need at least one condo created
        if (!this.condoIsLoaded) {
            if (!this.pendingRouter) {
                if (path != 'loading') {
                    this.pendingRouter = fullPath;
                }
            }
            Common.Router.navigate("#loading", { trigger: true });
            return false;
        }
        if (Common.Condos.length === 0) {
            if (pathUpperCase == 'createCondo'.toUpperCase()) {
                return true;
            }
            if (pathUpperCase == 'editBuilding'.toUpperCase()) {
                return true;
            }
            if (pathUpperCase == 'trash'.toUpperCase()) {
                return true;
            }
            if (path != 'loading') {
                this.pendingRouter = fullPath;
            }
            Common.Router.navigate("#createCondo", { trigger: true });
            return false;
        }

        //All required data is created and known to the webclient
        return true;
    }
});