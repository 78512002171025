'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing UspsView');

        //Set menu
        Common.MainMenuEntries.setSelected('usps');
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#usps-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        $(function () {
            $('#pageTitle').html('Usps delivery preferences');
        });
        return this;
    },

    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});