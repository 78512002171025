﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    //Model: access 
    initialize: function (options) {
        console.log('Initializing AccessView=' + this.cid);

        this.isFirst = options.isFirst;
        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
        this.listenTo(this.model, 'change:accessTypeId', this.changedAccessTypeId);
        this.listenTo(this.model, 'change:edit', this.changedEdit);
        this.listenTo(this.model.person, 'change:isMultiCondo', this.renderMultiCondoVisibility);
    },

    className: "clear",

    events: {
        //User trigger: 
        "click .personAccessItem": "userChangedAccessTypeId",
        "click .personProfileAccessMenu": "openPersonAccessMenu"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    render: function () {
        this.template = _.template($('#access-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
            $(this.el).html(this.template());

        var self = this;
        $(function () {
            self.renderMultiCondoVisibility();
            self.$("#condoLabel").html(self.model.get('condoLabel'));

            //Separator line: hidden always if first
            if (self.isFirst) {
                self.$('#profileLineSeparator').hide();
            }
            else {
                self.$('#profileLineSeparator').show();
            }

            //Build combox
            self.buildAccessTypeComboBox();
        });
        return this;
    },

    renderMultiCondoVisibility: function () {
        if (this.model.person.get('isMultiCondo')) {
            this.$("#multiCondo").show();
        }
        else {
            this.$("#multiCondo").hide();
        }
    },

    buildAccessTypeComboBox: function () {
        Common.AccessTypes.buildDropDownUi(this.$("#personAccessMenuDropdown"), "personAccessItem");
        this.$("#label").html(Common.AccessTypes.idToString(this.model.get('accessTypeId')));
        this.$("#personAccessLabel").html(Common.AccessTypes.idToString(this.model.get('accessTypeId')));
    },

    changedAccessTypeId: function (e, a) {
        this.$("#personAccessLabel").html(Common.AccessTypes.idToString(a));
    },

    changedEdit: function () {
        if (this.model.get('edit') === true) {
            this.$("#accessView").hide();
            this.$("#accessEdit").show();
        }
        else {
            this.$("#accessView").show();
            this.$("#accessEdit").hide();
        }
        this.$("#label").html(Common.AccessTypes.idToString(this.model.get('accessTypeId')));
        this.$("#personAccessLabel").html(Common.AccessTypes.idToString(this.model.get('accessTypeId')));
    },


    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openPersonAccessMenu: function (e) {
        if (this.$('#personAccessMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#personAccessMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedAccessTypeId: function (e) {
        e.preventDefault();
        var accessTypeId = e.currentTarget.getAttribute('name');
        this.model.set('accessTypeId', accessTypeId);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});