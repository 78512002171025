﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        condoLabel: '',
        startDate: '',
        endDate: '',
        isMultiCondo: false
    },

    //options: always 'CondoPersonLink'
    initialize: function (options) {
        this.condoPersonLink = options.condoPersonLink;
        this.set('startDate', this.condoPersonLink.get('startDate'));
        this.set('endDate', this.condoPersonLink.get('endDate'));
    },

    save: function () {
        this.condoPersonLink.set('startDate', this.get('startDate'));
        this.condoPersonLink.set('endDate', this.get('endDate'));
        this.condoPersonLink.save(null, {
            success: function () {
                Common.Persons.fetch();
                Common.CondoPersonLinks.fetch();
            }
        });
    }
});