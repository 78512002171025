﻿'use strict';
var Backbone = require('backbone');
var Type = require('../models/type');

module.exports = Backbone.Collection.extend({
    model: Type,

    initialize: function () {
        this.listenTo(this, 'change:condoId', this.ensureWeHaveOneFreeEntry);
        this.listenTo(this, 'change:isMarkedForDeletion', this.ensureWeHaveOneFreeEntry);
    },

    ensureWeHaveOneFreeEntry: function () {
        var emptyEntries = this.findWhere({ isNewType: true, isMarkedForDeletion: false, condoId: '' });
        if (!emptyEntries) {
            if (this.person) {
                this.add(new Type({ person: this.person, edit: true }));
            }
        }
    }
});