'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var MainMenuEntry = require('../models/mainMenuEntry');
var MainMenuEntryView = require('../views/mainMenuEntryView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing MainMenuView');

        this.listenTo(Common.MainMenuEntries, 'add', this.addEntry);
        this.listenTo(Common.Condos, 'add', this.showOrHide);
        this.listenTo(Common.Condos, 'delete', this.showOrHide);
        this.listenTo(Common.Condos, 'sync', this.showOrHide);
        this.listenTo(Common.Condos, 'reset', this.showOrHide);
    },


    /////////////////////////////////////
    // Rendering (none)
    /////////////////////////////////////
    render: function () {
        var self = this;
        $(function () {
            self.buildMenu();
            self.showOrHide();
        });
        return this;
    },


    buildMenu: function () {
        //Create MainMenuButtons
        var mainMenuEntry = new MainMenuEntry({
            tag: 'access',
            header: 'Access',
            url: '#',
            imgClass: 'menuBtnHome'
        });
        Common.MainMenuEntries.add(mainMenuEntry);

        mainMenuEntry = new MainMenuEntry({
            tag: 'today',
            header: 'Today',
            url: '#today',
            imgClass: 'menuBtnStar'
        });
        Common.MainMenuEntries.add(mainMenuEntry);

        //TODO: removed links during development
        //mainMenuEntry = new MainMenuEntry({
        //    tag: 'usps',
        //    header: 'Letter',
        //    url: '#usps',
        //    imgClass: 'menuBtnMail'
        //});
        //Common.MainMenuEntries.add(mainMenuEntry);
        //mainMenuEntry = new MainMenuEntry({
        //    tag: 'delivery',
        //    header: 'Delivery',
        //    url: '#delivery',
        //    imgClass: 'menuBtnPackages'
        //});
        //Common.MainMenuEntries.add(mainMenuEntry);

        mainMenuEntry = new MainMenuEntry({
            tag: 'log',
            header: 'Log',
            url: '#log',
            imgClass: 'menuBtnLog'
        });
        Common.MainMenuEntries.add(mainMenuEntry);

        mainMenuEntry = new MainMenuEntry({
            tag: 'condos',
            header: 'Condos',
            url: '#searchCondo',
            imgClass: 'menuBtnSearch'
        });
        Common.MainMenuEntries.add(mainMenuEntry);
    },


    /////////////////////////////////////
    // Events from model
    /////////////////////////////////////
    showOrHide: function () {
        if (Common.Condos.length > 0) {
            $('#mainMenu').show();
        }
        else {
            $('#mainMenu').hide();
        }
    },


    /////////////////////////////////////
    // Events from collection
    /////////////////////////////////////
    addEntry: function (mainMenuEntry) {
        var view = new MainMenuEntryView({ model: mainMenuEntry });
        $('#mainMenu').append(view.render().el);
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});