﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        //model: EditBuilding: 
        console.log('Initializing EditBuildingNotificationsView');
    },

    events: {
        //NotificationApprovedByHost
        "click #editNotificationApprovedByHost": "clickedEditNotificationApprovedByHost",
        "click #cancelNotificationApprovedByHost": "clickedCancelNotificationApprovedByHost",
        "click #saveNotificationApprovedByHost": "clickedSaveNotificationApprovedByHost",

        //NotificationPreApprovedByHost
        "click #editNotificationPreApprovedByHost": "clickedEditNotificationPreApprovedByHost",
        "click #cancelNotificationPreApprovedByHost": "clickedCancelNotificationPreApprovedByHost",
        "click #saveNotificationPreApprovedByHost": "clickedSaveNotificationPreApprovedByHost",

        //NotificationRejectedByHost
        "click #editNotificationRejectedByHost": "clickedEditNotificationRejectedByHost",
        "click #cancelNotificationRejectedByHost": "clickedCancelNotificationRejectedByHost",
        "click #saveNotificationRejectedByHost": "clickedSaveNotificationRejectedByHost",

        //NotificationPreRejectedByHost
        "click #editNotificationPreRejectedByHost": "clickedEditNotificationPreRejectedByHost",
        "click #cancelNotificationPreRejectedByHost": "clickedCancelNotificationPreRejectedByHost",
        "click #saveNotificationPreRejectedByHost": "clickedSaveNotificationPreRejectedByHost",

        //NotificationRejectedHostUnavailable
        "click #editNotificationRejectedHostUnavailable": "clickedEditNotificationRejectedHostUnavailable",
        "click #cancelNotificationRejectedHostUnavailable": "clickedCancelNotificationRejectedHostUnavailable",
        "click #saveNotificationRejectedHostUnavailable": "clickedSaveNotificationRejectedHostUnavailable"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#editBuildingNotifications-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
        });
        return this;
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    clickedEditNotificationApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationApprovedByHostRow").addClass('hidden');
        this.$("#editNotificationApprovedByHostRow").removeClass('hidden');
        this.$('#inputNotificationApprovedByHostSubject').val(this.model.get('notificationGuestCheckinApprovedSubject'));
        this.$('#inputNotificationApprovedByHostBody').val(this.model.get('notificationGuestCheckinApprovedByHost'));
    },
    clickedCancelNotificationApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationApprovedByHostRow").removeClass('hidden');
        this.$("#editNotificationApprovedByHostRow").addClass('hidden');
    },
    clickedSaveNotificationApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationApprovedByHostRow").removeClass('hidden');
        this.$("#editNotificationApprovedByHostRow").addClass('hidden');
        this.model.set('notificationGuestCheckinApprovedSubject', this.$('#inputNotificationApprovedByHostSubject').val());
        this.model.set('notificationGuestCheckinApprovedByHost', this.$('#inputNotificationApprovedByHostBody').val());
        this.render();
        this.model.save();
    },

    clickedEditNotificationPreApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreApprovedByHostRow").addClass('hidden');
        this.$("#editNotificationPreApprovedByHostRow").removeClass('hidden');
        this.$('#inputNotificationPreApprovedByHostSubject').val(this.model.get('notificationGuestCheckinApprovedSubject'));
        this.$('#inputNotificationPreApprovedByHostBody').val(this.model.get('notificationGuestPreApprovedByHost'));
    },
    clickedCancelNotificationPreApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreApprovedByHostRow").removeClass('hidden');
        this.$("#editNotificationPreApprovedByHostRow").addClass('hidden');
    },
    clickedSaveNotificationPreApprovedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreApprovedByHostRow").removeClass('hidden');
        this.$("#editNotificationPreApprovedByHostRow").addClass('hidden');
        this.model.set('notificationGuestCheckinApprovedSubject', this.$('#inputNotificationPreApprovedByHostSubject').val());
        this.model.set('notificationGuestPreApprovedByHost', this.$('#inputNotificationPreApprovedByHostBody').val());
        this.render();
        this.model.save();
    },

    clickedEditNotificationRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedByHostRow").addClass('hidden');
        this.$("#editNotificationRejectedByHostRow").removeClass('hidden');
        this.$('#inputNotificationRejectedByHostSubject').val(this.model.get('notificationGuestCheckinRejectedSubject'));
        this.$('#inputNotificationRejectedByHostBody').val(this.model.get('notificationGuestCheckinRejectedByHost'));
    },
    clickedCancelNotificationRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedByHostRow").removeClass('hidden');
        this.$("#editNotificationRejectedByHostRow").addClass('hidden');
    },
    clickedSaveNotificationRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedByHostRow").removeClass('hidden');
        this.$("#editNotificationRejectedByHostRow").addClass('hidden');
        this.model.set('notificationGuestCheckinRejectedSubject', this.$('#inputNotificationRejectedByHostSubject').val());
        this.model.set('notificationGuestCheckinRejectedByHost', this.$('#inputNotificationRejectedByHostBody').val());
        this.render();
        this.model.save();
    },

    clickedEditNotificationPreRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreRejectedByHostRow").addClass('hidden');
        this.$("#editNotificationPreRejectedByHostRow").removeClass('hidden');
        this.$('#inputNotificationPreRejectedByHostSubject').val(this.model.get('notificationGuestCheckinRejectedSubject'));
        this.$('#inputNotificationPreRejectedByHostBody').val(this.model.get('notificationGuestPreRejectedByHost'));
    },
    clickedCancelNotificationPreRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreRejectedByHostRow").removeClass('hidden');
        this.$("#editNotificationPreRejectedByHostRow").addClass('hidden');
    },
    clickedSaveNotificationPreRejectedByHost: function (e) {
        e.preventDefault();
        this.$("#viewNotificationPreRejectedByHostRow").removeClass('hidden');
        this.$("#editNotificationPreRejectedByHostRow").addClass('hidden');
        this.model.set('notificationGuestCheckinRejectedSubject', this.$('#inputNotificationPreRejectedByHostSubject').val());
        this.model.set('notificationGuestPreRejectedByHost', this.$('#inputNotificationPreRejectedByHostBody').val());
        this.render();
        this.model.save();
    },

    clickedEditNotificationRejectedHostUnavailable: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedHostUnavailableRow").addClass('hidden');
        this.$("#editNotificationRejectedHostUnavailableRow").removeClass('hidden');
        this.$('#inputNotificationRejectedHostUnavailableSubject').val(this.model.get('notificationGuestCheckinRejectedSubject'));
        this.$('#inputNotificationRejectedHostUnavailableBody').val(this.model.get('notificationGuestCheckinRejectedHostUnavailable'));
    },
    clickedCancelNotificationRejectedHostUnavailable: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedHostUnavailableRow").removeClass('hidden');
        this.$("#editNotificationRejectedHostUnavailableRow").addClass('hidden');
    },
    clickedSaveNotificationRejectedHostUnavailable: function (e) {
        e.preventDefault();
        this.$("#viewNotificationRejectedHostUnavailableRow").removeClass('hidden');
        this.$("#editNotificationRejectedHostUnavailableRow").addClass('hidden');
        this.model.set('notificationGuestCheckinRejectedSubject', this.$('#inputNotificationRejectedHostUnavailableSubject').val());
        this.model.set('notificationGuestCheckinRejectedHostUnavailable', this.$('#inputNotificationRejectedHostUnavailableBody').val());
        this.render();
        this.model.save();
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});