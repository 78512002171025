'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var CondoPersonLink = require('../models/condoPersonLink');
var Checkin = require('../models/checkin');
var Person = require('../models/person');
var DeletePrompt = require('../models/deletePrompt');
var DeletePromptView = require('../views/deletePromptView');

module.exports = Backbone.View.extend({

    //model: CondoPersonLink
    initialize: function (options) {
        console.log('Initializing GuestLineView=' + this.cid);

        //Get data used if we  create a new entry
        this.host = options.host;
        this.hostPerson = options.hostPerson;
        this.condoLabel = options.condoLabel;
        this.isFirst = options.isFirst;
        this.createGuestView = options.createGuestView;

        //Events
        if (this.model) {
            this.listenTo(this.model, 'change:condoPersonLinkTypeId', this.changedCondoPersonLinkTypeId);
            this.listenTo(this.model, 'change:output', this.changedOutput);
            this.listenTo(this.model, 'change:star', this.renderStar);
            this.listenTo(options.guestCollection, 'remove', this.removeGuest);
            this.listenTo(options.guestCollection, 'reset', this.destroy);
        }
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",

        "click #star": "clickedStar",
        "click #checkin": "clickedCheckin",
        "click #add": "clickedAdd",
        "click #cancel": "clickedCancel",
        "click #save": "clickedSave",
        "click #nameOutput": "clickedGuest",

        "click .personTypeItem": "userChangedCondoPersonLinkType",
        "click #personTypeMenu": "openPersonTypeMenu",

        "change input:eq(0)": "userChangedLastname",
        "change input:eq(1)": "userChangedFirstname",
        "change input:eq(2)": "userChangedCompany",

        //Model: user input --> update mmodel 
        "focus input:eq(0)": "inputFocus",

        //Title
        "click #titleMenu": "openTitleMenu",
        "click .dropdownTitleItem": "clickedTitleItem"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#guestLine-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        var self = this;
        $(function () {
            //Render as existing or new
            if (self.model) {
                self.renderAsExisting();
            }
            else {
                self.renderAsAddNew();
                if (self.createGuestView) {
                    self.clickedAdd();
                }
            }

            //Separator line: hidden always if first
            if (self.isFirst) {
                self.$('#profileLineSeparator').hide();
            }

            //Handling 'enter' & ESC keypress
            $(self.el).keydown(function (e) {
                if (e.keyCode == 13) {
                    self.hitEnterKey(e);
                    return false;
                }
            });
            $(self.el).keyup(function (e) {
                if (e.keyCode == 27) {
                    self.hitEscKey(e);
                    return false;
                }
            });
        });
        return this;
    },

    renderStar: function () {
        if (this.model && this.model.get('star') == true) {
            this.$('#star').addClass('starred');
            this.$('#star').removeClass('unStarred');
        }
        else {
            this.$('#star').removeClass('starred');
            this.$('#star').addClass('unStarred');
        }
    },

    renderAsExisting: function () {
        //Build combox
        this.buildCondoPersonLinkTypeComboBox();

        this.$("#existing").removeClass('hidden');
        this.$("#addNew").addClass('hidden');
        this.$("#createNew").addClass('hidden');
        this.$("#personTypeOutputLabel").html(this.model.get('condoPersonLinkType'));
        this.$("#personTypeLabel").html(this.model.get('condoPersonLinkType'));
        this.$("#nameOutput").html(this.model.get('personOutput'));
        this.renderStar();
        this.$("#checkin").focus();
    },

    renderAsAddNew: function () {
        this.$("#existing").addClass('hidden');
        this.$("#addNew").removeClass('hidden');
        this.$("#createNew").addClass('hidden');
    },

    renderAsCreateNew: function () {
        //Build combox
        this.buildCondoPersonLinkTypeComboBox();

        this.$("#existing").addClass('hidden');
        this.$("#addNew").addClass('hidden');
        this.$("#createNew").removeClass('hidden');
        this.$('#lastname').focus();
    },

    buildCondoPersonLinkTypeComboBox: function () {
        //Set current type in UI
        var typeString = this.model.get('condoPersonLinkType');
        this.$("#personTypeLabel").html(typeString);
        this.$("#personTypeOutputLabel").html(typeString);
    },

    renderTitle: function () {
        if (this.model) {
            var person = this.model.get('person');
            if (person) {
                var title = person.get('title');
            }
            else {
                this.listenTo(this.model, "change:person", changedPerson);
            }
        }
        if (!title) {
            title = "Title";
        }
        this.$("#titleLabel").html(title);
    },

    changedPerson: function (model, person) {
        var title = person.get('title');
        this.$("#titleLabel").html(title);
    },


    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openPersonTypeMenu: function (e) {
        if (this.$('#personTypeMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#personTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },


    /////////////////////////////////////
    // Menu: title
    /////////////////////////////////////
    openTitleMenu: function (e) {
        if (this.$('#titleMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#titleMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    clickedTitleItem: function (e) {
        e.preventDefault();
        var title = e.target.innerText;
        this.model.set('title', title);
        if (!title) {
            title = "Title";
        }
        this.$("#titleLabel").html(title);
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedCondoPersonLinkType: function (e) {
        e.preventDefault();
        var condoPersonLinkTypeId = e.currentTarget.getAttribute('name');
        this.model.set('condoPersonLinkTypeId', condoPersonLinkTypeId);
        this.buildCondoPersonLinkTypeComboBox();
    },

    userChangedLastname: function (e) {
        var person = this.model.get('person');
        if (person) {
            person.set('lastname', this.$('input:eq(0)').val());
        }
    },

    userChangedFirstname: function (e) {
        var person = this.model.get('person');
        if (person) {
            person.set('firstname', this.$('input:eq(1)').val());
        }
    },

    userChangedCompany: function (e) {
        var person = this.model.get('person');
        if (person) {
            person.set('company', this.$('input:eq(2)').val());
        }
    },

    hitEnterKey: function (e) {
        if (this.$('#createNew').hasClass('hidden') == false) {
            this.clickedSave(e);
        } else if (this.$('#existing').hasClass('hidden') == false) {
            this.clickedCheckin(e);
        }
    },

    hitEscKey: function (e) {
        if (this.$('#createNew').hasClass('hidden') == false) {
            this.clickedCancel(e);
        } else if (this.$('#existing').hasClass('hidden') == false) {
            e.preventDefault();
            Common.Router.navigate("#", { trigger: true });
        }
    },

    clickedAdd: function (e) {
        if (e) {
            e.preventDefault();
        }
        var hostCondoPersonLink = this.hostPerson.getHostCondoPersonLink();
        var condoPersonLink = new CondoPersonLink({
            condoPersonLinkTypeId: Common.CondoPersonLinkTypes_guestId,
            condoId: hostCondoPersonLink.get('condoId'),
            isResident: false,
            star: false,
            accessTypeId: Common.AccessTypes_callFirstId,
            hostCondoPersonLink: hostCondoPersonLink
        });

        this.$('input:eq(0)').val('');
        this.$('input:eq(1)').val('');
        this.$('input:eq(2)').val('');

        this.model = condoPersonLink;
        this.renderAsCreateNew();
    },

    clickedCancel: function (e) {
        e.preventDefault();
        if (this.createGuestView) {
            Common.Router.navigate("#", { trigger: true });
            return;
        }

        var person = this.model.get('person');
        if (person && person.id) {
            Common.Persons.fetch();//required to reload Person data from server
            this.renderAsExisting();
        }
        else {
            this.model = null;
            this.renderAsAddNew();
            this.renderTitle();
            this.$('input:eq(0)').val('');
            this.$('input:eq(1)').val('');
            this.$('input:eq(2)').val('');
        }
    },

    clickedSave: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var self = this;
        this.model.save(null, {
            success: function () {
                if (self.createGuestView) {
                    self.listenTo(self.model, 'change:star', self.renderStar);
                    self.renderAsExisting();
                }
                else {
                    self.model = null;
                    self.renderAsAddNew();
                }
            }
        });
    },

    clickedDelete: function (e) {
        e.preventDefault();
        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('personOutput') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    delete: function () {
        this.model.destroy();
        this.destroy();
    },

    clickedGuest: function (e) {
        e.preventDefault();
        Common.Router.navigate("#person/" + this.model.id, { trigger: true });
    },

    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.get('star') == false) {
            this.model.set('star', true);
        }
        else {
            this.model.set('star', false);
        }
        this.model.save();
    },

    clickedCheckin: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var checkin = new Checkin({
            condoPersonLink: this.model
        });
        checkin.tryCheckin();

    },


    /////////////////////////////////////
    // Model --> UI
    /////////////////////////////////////
    changedOutput: function (guest) {
        this.render();
    },

    changedCondoPersonLinkTypeId: function () {
        this.buildCondoPersonLinkTypeComboBox();
    },


    /////////////////////////////////////
    // Person search results
    /////////////////////////////////////
    inputFocus: function (eventName, args) {
        //Direct search result to follow this input field
        var condoPersonLinkTypeIds = [Common.CondoPersonLinkTypes_staffId, Common.CondoPersonLinkTypes_guestId];
        Common.searchPersonView.load(this, '0', '', '.searchPersonMenu-list', condoPersonLinkTypeIds, true, true, true);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    removeGuest: function (guest) {
        if (guest == this.model) {
            this.destroy();
        }
    },
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});