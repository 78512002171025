'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({
    tagName: "div",
    className: "mainMenuContainer",

    initialize: function (options) {
        console.log('Initializing MainMenuEntryView');

        this.listenTo(Common.MainMenuEntries, 'remove', this.removeEntry);
        this.listenTo(Common.MainMenuEntries, 'reset', this.destroy);
        this.listenTo(Common.MainMenuEntries, 'change:isSelected', this.changedSelected);
    },

    events: {
        "click ": "clickedEntry"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#mainMenuEntry-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.renderSelected();
        });
        return this;
    },

    renderSelected: function () {
        if (this.model.get('isSelected') == true) {
            this.$el.addClass('mainMenuTextButtonSelected');
        }
        else {
            this.$el.removeClass('mainMenuTextButtonSelected');
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        Common.Router.navigate(this.model.get('url'), { trigger: true });
        if (Common.currentView.activate) {
            Common.currentView.activate();
        }
    },


    /////////////////////////////////////
    // Collection events
    /////////////////////////////////////
    removeEntry: function (mainMenuEntry) {
        if (mainMenuEntry == model) {
            this.destroy();
        }
    },
    changedSelected: function (mainMenuEntry) {
        if (mainMenuEntry == this.model) {
            this.renderSelected();
        }
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});