﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing TrashItemView');
    },

    tagName: 'div',
    className: 'rTableRow click',

    events: {
        "click ": "clickedEntry",
        "mouseover ": "setHighlight",
        "mouseleave": "clearHighlight"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#trashItem-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        $(function () {
            self.listenTo(self.model, "change:selected", self.changedSelected);
            self.listenTo(self.model.collection, "remove", self.removedModel);
        });
        return this;
    },

    changedSelected: function (model, selected) {
        this.$('#selected').prop('checked', selected);
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    removedModel: function (trashItem) {
        if (trashItem == this.model) {
            this.destroy();
        }
    },


    /////////////////////////////////////
    // User action
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        this.model.toggleSelection();
    },

    setHighlight: function () {
        $(this.el).addClass('boxHighlight');
    },

    clearHighlight: function () {
        $(this.el).removeClass('boxHighlight');
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});