﻿'use strict';
var $ = require('jquery');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/menu.css');
require('jquery-ui/themes/base/datepicker.css');
require('jquery-ui/themes/base/theme.css');
require('jquery-ui-bundle');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing AwayView=' + this.cid);

        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "change .datepicker:eq(0)": "userChangedLeave",
        "change .datepicker:eq(1)": "userChangedBack",
        "change #comment": "userChangedComment"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#away-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        $(function () {
            self.$(".datepicker:eq(0)").datepicker({ minDate: 0 });
            self.$(".datepicker:eq(1)").datepicker({ minDate: 0 });
            self.$("#comment").val(self.model.get('comment'));
            self.setVisibility();
        });
        return this;
    },

    setVisibility: function () {
        if (this.model.get('leave')) {
            this.$("#backDate").css("visibility", "visible");
            this.$("#comment").removeClass('hidden');
        }
        else {
            this.$("#backDate").css("visibility", "hidden");
            this.$("#comment").addClass('hidden');
        }
    },
    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedLeave: function () {
        this.model.set('leave', this.$(".datepicker:eq(0)").val());

        //Set minDate for 'back' to one day after 'leave'
        var leave = $(".datepicker:eq(0)").datepicker('getDate');
        var leaveDate = new Date(leave.getTime());
        leaveDate.setDate(leaveDate.getDate() + 1);
        this.$(".datepicker:eq(1)").datepicker('destroy');
        this.$(".datepicker:eq(1)").datepicker({ minDate: leaveDate });
        this.$(".datepicker:eq(1)").val('');
        this.setVisibility();
    },
    userChangedBack: function () {
        this.model.set('back', this.$(".datepicker:eq(1)").val());
    },

    userChangedComment: function () {
        this.model.set('comment', this.$("#comment").val());
    },


    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.$(".datepicker:eq(0)").val('');
        this.$(".datepicker:eq(1)").val('');
        this.$("#comment").val('');
        this.model.set('leave', '');
        this.model.set('back', '');
        this.model.set('comment', '');
        this.model.set('isDeleted', true);
        this.setVisibility();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});