'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var CondoPersonLink = require('../models/condoPersonLink');

module.exports = Backbone.Collection.extend({
    model: CondoPersonLink,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'condoPersonLink/index.php'
});