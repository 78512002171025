﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var Common = require('../common');
var Account = require('../models/account');
var Building = require('../models/building');
var SearchCondo = require('../models/searchCondo');
var LogManager = require('../models/logManager');
var CondoCreate = require('../models/condoCreate');
var PersonProfile = require('../models/personProfile');
var Trash = require('../models/trash');
var SearchAccessView = require('../views/searchAccessView');
var TodayView = require('../views/todayView');
var UspsView = require('../views/uspsView');
var DeliveryView = require('../views/deliveryView');
var LogManagerView = require('../views/logManagerView');
var EditBuildingView = require('../views/editBuildingView');
var LoginView = require('../views/loginView');
var CreateAccountView = require('../views/createAccountView');
var EditAccountView = require('../views/editAccountView');
var ChangePasswordView = require('../views/changePasswordView');
var CreateBuildingView = require('../views/createBuildingView');
var SearchCondoView = require('../views/searchCondoView');
var SelectBuildingView = require('../views/selectBuildingView');
var CondoProfileView = require('../views/condoProfileView');
var PersonProfileView = require('../views/personProfileView');
var CondoCreateView = require('../views/condoCreateView');
var TrashItemsView = require('../views/trashItemsView');
var TrashView = require('../views/trashView');
var CreateGuestView = require('../views/createGuestView');

module.exports = Backbone.Router.extend({
    routes: {
        //Swap which panel is shown in the app
        '': 'searchAccess',					//default page: lookup for access page
        'login': 'login',
        'createAccount': 'createAccount',
        'editAccount': 'editAccount',
        'changePassword': 'changePassword',
        'condo/:condo_id': 'condo',     //condo profile page
        'person/:condoPersonLink_id': 'person',   //person full profile page
        'person/:condoPersonLink_id/:edit': 'person',   //person full profile page
        'createBuilding': 'createBuilding',
        'selectBuilding': 'selectBuilding',
        'searchCondo': 'searchCondo',
        'createCondo': 'createCondo',
        'today': 'today',
        'usps': 'usps',
        'delivery': 'delivery',
        'editBuilding': 'editBuilding',
        'log': 'log',
        'trash': 'trash',
        'createGuest/:hostCondoPersonLink_id': 'createGuest',
        'log/:log_id': 'log'
    },

    searchAccess: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(SearchAccessView)) {
            return;
        }
        Common.currentView = new SearchAccessView();
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    today: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('today') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(TodayView)) {
            return;
        }
        Common.currentView = new TodayView();
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    trash: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('trash') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(TrashView)) {
            return;
        }
        var model = new Trash();
        Common.currentView = new TrashView({ model: model });
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    usps: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('usps') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(UspsView)) {
            return;
        }
        Common.currentView = new UspsView();
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    delivery: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('delivery') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(DeliveryView)) {
            return;
        }
        Common.currentView = new DeliveryView();
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    log: function (logId) {
        if (logId) {
            if (Common.dataSetupRedirector.isNavigationAllowed('log/' + logId) == false) {
                return;
            }
        }
        else {
            if (Common.dataSetupRedirector.isNavigationAllowed('log') == false) {
                return;
            }
        }

        //Apply log change to existing view or create new?
        if (Common.currentView && Common.currentView instanceof LogManagerView && logId && logId != '') {
            Common.currentView.renderDetails(logId);
        }
        else {
            if (Common.currentView) {
                $('#pageTitle').html('');
                Common.currentView.destroy();
                Common.currentView = null;
            }
            var model = new LogManager();
            Common.currentView = new LogManagerView({ model: model, logId: logId });
            $('#conciergeapp').html(Common.currentView.render().el);
        }
    },


    createGuest: function (condoPersonLinkId) {
        if (condoPersonLinkId) {
            if (Common.dataSetupRedirector.isNavigationAllowed('createGuest/' + condoPersonLinkId) == false) {
                return;
            }
            if (this.isViewAlreadyLoaded(CreateGuestView)) {
                return;
            }
            var condoPersonLink = Common.CondoPersonLinks.findWhere({ id: condoPersonLinkId });
            if (condoPersonLink) {
                var hostPerson = condoPersonLink.get('person');
                if (hostPerson) {
                    Common.currentView = new CreateGuestView({ model: hostPerson });
                    $('#conciergeapp').html(Common.currentView.render().el);
                }
            }
        }
    },


    editBuilding: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('editBuilding') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(EditBuildingView)) {
            return;
        }
        var activeBuilding = Common.Buildings.getActive();
        if (activeBuilding && activeBuilding.get('accountTypeId') != Common.Accounts_propertyManagerId) {
            this.navigate("#", { trigger: true });
            return;
        }

        //Lookup selected building
        var model = Common.Buildings.findWhere({ isActive: true });
        Common.currentView = new EditBuildingView({ model: model });
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    login: function () {
        if (Common.LoggedInUser) {
            Common.Accounts.logout();
        }
        if (this.isViewAlreadyLoaded(LoginView)) {
            return;
        }
        var model = new Account();
        Common.currentView = new LoginView({ model: model });
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    createAccount: function () {
        if (this.isViewAlreadyLoaded(CreateAccountView)) {
            return;
        }
        var model = new Account();
        Common.currentView = new CreateAccountView({ model: model });
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    editAccount: function () {
        if (this.isViewAlreadyLoaded(EditAccountView)) {
            return;
        }
        if (Common.LoggedInUser) {
            Common.currentView = new EditAccountView({ model: Common.LoggedInUser });
            $('#conciergeapp').html(Common.currentView.render().el);
        }
        else {
            this.navigate("#", { trigger: true });
        }
    },

    changePassword: function () {
        if (this.isViewAlreadyLoaded(ChangePasswordView)) {
            return;
        }
        if (Common.LoggedInUser) {
            Common.currentView = new ChangePasswordView({ model: Common.LoggedInUser });
            $('#conciergeapp').html(Common.currentView.render().el);
        }
        else {
            this.navigate("#", { trigger: true });
        }
    },

    createBuilding: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('createBuilding') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(CreateBuildingView)) {
            return;
        }
        if (Common.LoggedInUser && Common.LoggedInUser.get('accountTypeId') != Common.Accounts_propertyManagerId) {
            this.navigate("#", { trigger: true });
            return;
        }
        var model = new Building();
        Common.currentView = new CreateBuildingView({ model: model });
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    selectBuilding: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('selectBuilding') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(SelectBuildingView)) {
            return;
        }
        Common.currentView = new SelectBuildingView();
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    searchCondo: function (condoId) {
        if (condoId) {
            if (Common.dataSetupRedirector.isNavigationAllowed('condo/' + condoId) == false) {
                return;
            }
            var self = this;
            if (!this.tryLoadCondo(condoId)) {
                Common.CondoPersonLinks.fetch({
                    success: function () {
                        Common.Persons.fetch({
                            success: function () {
                                if (!self.tryLoadCondo(condoId)) {
                                    self.navigate("#", { trigger: true });
                                }
                            }
                        });
                    }
                });
            }
        }
        else {
            if (Common.dataSetupRedirector.isNavigationAllowed('searchCondo') == false) {
                return;
            }
            if (this.isViewAlreadyLoaded(SearchCondoView)) {
                return;
            }
            var model = new SearchCondo();
            Common.currentView = new SearchCondoView({ model: model });
            $('#conciergeapp').html(Common.currentView.render().el);
        }
    },

    createCondo: function () {
        if (Common.dataSetupRedirector.isNavigationAllowed('createCondo') == false) {
            return;
        }
        if (this.isViewAlreadyLoaded(CondoCreateView)) {
            return;
        }
        var model = new CondoCreate({ host: this });
        Common.currentView = new CondoCreateView({ model: model });
        $('#pageTitle').html('Create condo / suite');
        $('#conciergeapp').html(Common.currentView.render().el);
    },

    isViewAlreadyLoaded: function (viewType) {
        if (Common.currentView) {
            if (Common.currentView instanceof viewType) {
                return true;
            }
            $('#pageTitle').html('');
            Common.currentView.destroy();
            Common.currentView = null;
        }
        return false;
    },

    tryLoadCondo: function (condoId) {
        var condo = null;
        if (condoId) {
            condo = Common.Condos.findWhere({ id: condoId });
            if (!condo) {
                return false;
            }
        }

        if (Common.currentView) {
            if (Common.currentView instanceof CondoProfileView && condo.get('id') == Common.currentView.model.get('id') && Common.currentView.model.collection !== undefined) {
                return true;
            }
            else {
                $('#pageTitle').html('');
                Common.currentView.destroy();
                Common.currentView = null;
            }
        }
        if (condo) {
            Common.currentView = new CondoProfileView({ model: condo });
            $('#conciergeapp').html(Common.currentView.render().el);
        }
        return true;
    },

    condo: function (condoId) {
        if (Common.dataSetupRedirector.isNavigationAllowed('condo/' + condoId) == false) {
            return;
        }
        var self = this;
        if (!this.tryLoadCondo(condoId)) {
            Common.Condos.fetch({
                success: function () {
                    if (!self.tryLoadCondo(condoId)) {
                        self.navigate("#", { trigger: true });
                    }
                }
            });
        }
    },

    person: function (condoPersonLinkId, edit) {
        var self = this;
        if (!this.tryLoadPerson(condoPersonLinkId, edit)) {
            Common.Persons.fetch({
                success: function () {
                    Common.CondoPersonLinks.fetch({
                        success: function () {
                            if (!self.tryLoadPerson(condoPersonLinkId, edit)) {
                                self.navigate("#", { trigger: true });
                            }
                        }
                    });
                }
            });
        }
    },

    tryLoadPerson: function (condoPersonLinkId, edit) {
        var condoPersonLink = Common.CondoPersonLinks.findWhere({ id: condoPersonLinkId });
        if (condoPersonLink) {
            if (Common.currentView) {
                if (Common.currentView instanceof PersonProfileView && condoPersonLinkId == Common.currentView.model.get('id') && Common.currentView.model.collection !== undefined) {
                    return true;
                }

                $('#pageTitle').html('');
                Common.currentView.destroy();
                Common.currentView = null;
            }
            var personProfile = new PersonProfile({
                person: condoPersonLink.get('person'),
                condoPersonLink: condoPersonLink
            });
            Common.currentView = new PersonProfileView(
                {
                    model: personProfile,
                    edit: edit
                });
            $('#conciergeapp').html(Common.currentView.render().el);
            return true;
        }
        return false;
    }
});