'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Checkin = require('../models/checkin');

//model: searchPersonExMatch
var PersonProfileLineView = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PersonProfileLineView' + this.cid);

        this.listenTo(Common.SearchPersonExMatches, 'change:output', this.changedOutput);
        this.listenTo(Common.SearchPersonExMatches, 'change:condoPersonLinkOutput', this.changedCondoPersonLinkOutput);
        this.listenTo(Common.SearchPersonExMatches, 'remove', this.removeMatch);
        this.listenTo(Common.SearchPersonExMatches, 'reset', this.resetMatches);
        this.listenTo(Common.SearchPersonExMatches, 'change:isHighlighted', this.changeIsHighlighted);
        this.listenTo(Common.SearchPersonExMatches, 'change:isSelected', this.changeIsSelected);
    },

    tagName: 'div',
    className: 'box personProfileBox',

    events: {
        "click ": "clickedEntry",
        "mouseover ": "requestHighlight",
        "mouseleave": "releaseHighlight",
        "click #star": "clickedStar",
        "click #checkin": "clickedCheckin",
        "click #addGuestBtn": "clickedAddGuest"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#personProfileLine-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        this.renderStar();

        //Render options section once DOM is loaded
        var self = this;
        $(function () {
            //Type: no access: show now access
            if (self.model.get('noAccess')) {
                self.$('#noAccessLabel').html(Common.AccessTypes.idToString(Common.AccessTypes_noAccessId));
                self.$('#noAccessLabel').removeClass('hidden');
                return this;
            }

            //Type: guest/staff: show checkin
            if (self.model.get('staffOrGuest')) {
                self.$('#checkin').removeClass('hidden');
            }
            else {
                //Type: resident/owner: show primary phone number
                var phones = self.model.origPerson.get('phones');
                if (phones.length > 0 && phones[0].number) {
                    self.$('#phoneLink').removeClass('hidden');
                    self.$('#phoneLink').attr('href', "tel:" + phones[0].number);
                    self.$('#phoneLink').text(phones[0].number);
                }
                self.$('#addGuestBtn').removeClass('hidden');
            }

            //Photo
            var photos = self.model.origPerson.get('photos');
            if (photos.length > 0) {
                self.$("#profileImg").attr('src', Common.apiFolder + "person/" + photos[0].path);
                self.$("#profileImg").removeClass('imgProfilePlaceholder');
            }
        });
        return this;
    },

    renderStar: function () {
        var condoPersonLink = this.model.CondoPersonLinks[0];
        if (condoPersonLink) {
            if (condoPersonLink.get('star') == false) {
                this.$('#star').removeClass('starred');
                this.$('#star').addClass('unStarred');
            }
            else {
                this.$('#star').addClass('starred');
                this.$('#star').removeClass('unStarred');
            }
        }
    },

    changedOutput: function (searchPersonExMatch) {
        if (this.model == searchPersonExMatch) {
            this.$('#output').html(this.model.get('output'));
        }
    },

    changedCondoPersonLinkOutput: function (searchPersonExMatch) {
        if (this.model == searchPersonExMatch) {
            this.$('#condoPersonLinkOutput').html(this.model.get('condoPersonLinkOutput'));
        }
    },


    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    changeIsHighlighted: function (searchPersonExMatch) {
        if (searchPersonExMatch == this.model) {
            if (this.model.get('isHighlighted') == true) {
                $(this.el).addClass('boxHighlight');
            }
            else {
                $(this.el).removeClass('boxHighlight');
            }
        }
    },

    changeIsSelected: function (searchPersonExMatch) {
        if (searchPersonExMatch == this.model) {
            if (this.model.origPerson.CondoPersonLinks.length > 0) {
                Common.Router.navigate("#person/" + this.model.origPerson.CondoPersonLinks.first().id, { trigger: true });
            }
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        this.model.set('isSelected', true);
    },

    clickedHostName: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.CondoPersonLinks.length > 0) {
            Common.Router.navigate("#person/" + this.model.CondoPersonLinks[0].get('hostCondoPersonLinkId'), { trigger: true });
        }
    },

    requestHighlight: function () {
        this.model.set('isHighlighted', true);
    },

    releaseHighlight: function () {
        this.model.set('isHighlighted', false);
    },

    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var condoPersonLink = this.model.CondoPersonLinks[0];
        if (condoPersonLink) {
            if (condoPersonLink.get('star') == false) {
                condoPersonLink.set('star', true);
            }
            else {
                condoPersonLink.set('star', false);
            }
            condoPersonLink.save();
            this.renderStar();
        }
    },

    clickedCheckin: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var link = this.model.CondoPersonLinks[0];
        if (link) {
            var checkin = new Checkin({
                condoPersonLink: link
            });
            checkin.tryCheckin();
        }
    },

    clickedAddGuest: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var hostCondoPersonLink = this.model.origPerson.CondoPersonLinks.findWhere({ isOwnerOrTenant: true });
        if (hostCondoPersonLink) {
            Common.Router.navigate("#createGuest/" + hostCondoPersonLink.id, { trigger: true });
        }
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    removeMatch: function (searchPersonExMatch) {
        if (this.model == searchPersonExMatch) {
            this.destroy();
        }
    },

    resetMatches: function () {
        this.destroy();
    },

    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});
Common.PersonProfileLineView = PersonProfileLineView;
module.exports = PersonProfileLineView;