﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Condo = require('../models/condo');

module.exports = Backbone.Collection.extend({
    model: Condo,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'condo/index.php',

    filter: '',

    setFilter: function (filter) {
        if (this.filter !== filter) {
            this.filter = filter;
            this.forEach(function (condo) {
                condo.setFilter(filter);
            });
        }
    }
});