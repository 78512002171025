'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PromptView');

        this.message = options.message;
        this.header = options.header;
    },

    events: {
        "click": "clicked",
        "click #closeButton": "userClickedClose"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#prompt-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        var self = this;
        $(function () {
            self.$('#header').html(self.header);
            self.$('#message').html(self.message);
        });
        return this;
    },


    /////////////////////////////////////
    // User input
    /////////////////////////////////////
    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    },

    userClickedClose: function (e) {
        e.preventDefault();
        Common.appView.closeDialog();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});