'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var ChangeBuildingAccountPasswordView = require('../views/changeBuildingAccountPasswordView');

module.exports = Backbone.View.extend({

    //Model: BuildingAccount (contains a copy of type 'Account')
    initialize: function () {
        console.log('Initializing EditBuildingAccountView');
    },

    events: {
        "click": "clicked",
        "click #update": "clickedUpdate",
        "click #cancel": "clickedCancel",
        "click #resetPassword": "clickedResetPassword",

        //AccountType dropdown
        "click #accountTypeMenu": "openAccountTypeMenu",
        "click .accountTypeItem": "userChangedAccountType"
    },

    //Cache the template function for a single item
    template: _.template($('#editBuildingAccount-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        $(function () {
            if (self.model.get('accountTypeId') == Common.Accounts_propertyManagerId) {//In this case we DO check on the AccountType accociated with the account and NOT on the AccountType accociated with the building
                self.$('#passwordRow').addClass('hidden');
            }
        });
        return this;
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(".dropdown").fadeOut(100);
    },

    clickedUpdate: function (e) {
        e.preventDefault();
        e.stopPropagation();

        //Set properties in model
        this.model.set('firstname', this.$('#firstname').val());
        this.model.set('lastname', this.$('#lastname').val());

        //Update
        this.model.update();

        this.destroy();
        Common.appView.closeDialog();
    },

    clickedCancel: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        e.stopPropagation();

        this.destroy();
        Common.appView.closeDialog();
    },

    clickedResetPassword: function (e) {
        e.preventDefault();
        var view = new ChangeBuildingAccountPasswordView({ model: this.model });
        this.destroy();
        Common.appView.closeDialog();
        Common.appView.requestDialog(view);
    },


    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openAccountTypeMenu: function (e) {
        if (this.$('#accountTypeMenuDropdown:visible').length === 0) {
            $(".dropdown").fadeOut(100);
            this.$("#accountTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    userChangedAccountType: function (e) {
        var accountTypeId = e.currentTarget.getAttribute('name');
        this.model.set('accountTypeId', accountTypeId);
        this.$("#accountTypeLabel").html(e.target.innerHTML);
        $(".dropdown").fadeOut(100);
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});