﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');

//Building Model
//-----------
//Our **Building** model has same attributes as defined in the database

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        name: '',
        streetName: '',
        city: '',
        zip: '',
        state: '',
        country: '',
        triggerStopAsResident: '',
        triggerStartAsResident: '',
        email: '',
        notificationGuest: '',
        notificationStaff: '',
        notificationPackage: '',
        notificationMail: '',
        gmailClientId: '',
        gmailSecret: '',
        gmailToken: ''
    },

    urlRoot: Common.apiFolder + 'building/index.php',

    validation: {
        name: {
            required: true
        },
        email: {
            required: true
        }
    },

    initialize: function () {
    },

    //Try creating building
    create: function () {
        this.save();
    }
});
