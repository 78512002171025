﻿'use strict';
var Backbone = require('backbone');
//Away Model
//-----------

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        leave: '',
        back: '',
        comment: ''
    },

    reset: function () {
        this.set('leave', '');
        this.set('back', '');
        this.set('comment   ', '');
    },

    isValid: function () {
        return this.get('leave') !== '';
    }
});
