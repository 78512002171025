﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PhoneView=' + this.cid);

        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "change #number": "userChangedNumber",
        "change #comment": "userChangedComment",

        //Model: user input --> update mmodel 
        "click #isMobileToggle": "clickIsMobile"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#phone-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.attributes));
        if (this.model.get('isMobile') == true) {
            $(function () {
                self.$("#isMobile").prop("checked", true);
            });
        }
        $(function () {
            self.$("#comment").val(self.model.get('comment'));
        });
        return this;
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    clickIsMobile: function (e) {
        e.preventDefault();
        //Toggle the checkbox manually (doing it on events made all events go to the first person in the list)
        var newValue = !this.$("#isMobile").prop('checked');
        this.$("#isMobile").prop('checked', newValue);
        this.model.set('isMobile', this.$("#isMobile").prop('checked'));
    },

    userChangedNumber: function () {
        this.model.set('number', this.$("#number").val());
    },

    userChangedComment: function () {
        this.model.set('comment', this.$("#comment").val());
    },

    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.$("#number").val('');
        this.$("#comment").val('');
        this.$("#isMobile").prop('checked', false);
        this.model.set('number', '');
        this.model.set('comment', '');
        this.model.set('isMobile', false);
        this.model.set('isDeleted', true);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});