/////////////////////////////////////
// INTRO
//
// This is a generic view used to capture input 
// and present a list of matching persons.
//
// This view will be created as part of the app and used as a utility for any other view
// When the view is used, its called from 
//
//      searchPersonView.load(
//          host:  the view hosting the input element. Required for jqyery lookup of the DOM element
//          inputDomIdx: the input will be taken from this DOM element index (count 'input' elements inside the view)
//          viewClassName:  the output will be rendered with a view of this class name, e.g. '#personProfileLine-list'
//          renderListDomId: the output will be rendered in this DOM element
//          condoPersonLinkTypeIds: list of types we want to include in the filter (e.g. only owners & tenants
//          closePanelOnBlur: true/false, used to automatically close dropdown when focus leave input field
//      )
//
// The model for matching search results SearchPersonExMatch
// This model will contain HTML rendered output fiels as well as 'isHighlighted' field
// 
// A view rendering SearchPersonExMatch should 
// - set isHighlighted when the mouse enter, and delete it when the mouse leaves
// - listen to change in isHighlighted  and use this to trigger highlight (this class will trigger highlight caused by arrow key up/down)
// - listen for click on view, and when it happens, set isSelected on the model. 
//
// A view rendering the input element should
// - call searchPersonView.load() when input get focus
// - subscribe to SearchPersonExMatch: changed:isSelected
//
/////////////////////////////////////
'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SearchPersonView');

        this.listenTo(Common.SearchPersonExMatches, 'remove', this.removedMatch);
        this.listenTo(Common.SearchPersonExMatches, 'reset', this.resetMatches);
        this.renderInterval_ms = 10;
        this.maxRenderCount = 200;

        //When the input DOM element loose focus, it will call requestClosePanel(). After a timeout, the panel must close.
        //We need to delay the closing as a click inside the panel would be missed if we closed right away.
        this.closeTimer = null;

        //Keep track of the inputDomIdx we are associated with
        this.inputDomIdx = null;
        this.host = null;
        this.viewClassName = null;
        this.renderListDomId = null;

        this.highlighted = null;
        this.highlightedAlias = null;
        this.person = null;
        this.personEntryViews = [];
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#searchPerson-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        return this;
    },

    removedMatch: function () {
        if (Common.SearchPersonExMatches.length == 0) {
            $(this.renderListDomId).addClass('hidden');
        }
    },
    resetMatches: function () {
        $(this.renderListDomId).addClass('hidden');
    },

    redraw: function () {
        this.cancelLazyRender();

        if (this.viewClassName != null && this.viewClassName != '') {
            this.renderIndex = 0;
            this.lazyRender();
        }
    },

    lazyRender: function () {
        var self = this;

        //Stop when all models are rendered
        if (this.renderIndex >= Common.SearchPersonExMatches.models.length) {
            this.cancelLazyRender();
            return;
        }

        //Render next model
        var searchPersonExMatch = Common.SearchPersonExMatches.models[this.renderIndex];
        this.renderIndex++;
        var view = new Common[self.viewClassName]({ model: searchPersonExMatch, host: this.host });
        $(this.renderListDomId).append(view.render().el);
        $(this.renderListDomId).removeClass('hidden');

        //Schedule next timer
        if (this.renderIndex >= Common.SearchPersonExMatches.models.length || this.renderIndex > this.maxRenderCount) {
            this.cancelLazyRender();
            return;
        }
        this.renderTimer = setTimeout(function () { self.lazyRender(); }, this.renderInterval_ms);
    },

    cancelLazyRender: function () {
        if (this.renderTimer) {
            clearTimeout(this.renderTimer);
            this.renderTimer = null;
        }
    },


    /////////////////////////////////////
    // Load and release
    /////////////////////////////////////
    load: function (host, inputDomIdx, viewClassName, renderListDomId, condoPersonLinkTypeIds, closePanelOnBlur, includeDeleted, aggregrateResults) {
        Common.SearchPersonExMatches.load(this);

        //No action if same input element loads again
        if (this.inputDomIdx == inputDomIdx && this.host == host) {
            return;
        }

        //Make sure are always unload prior to any loading
        this.unload();

        this.inputDomIdx = inputDomIdx;
        this.host = host;
        this.viewClassName = viewClassName;
        this.renderListDomId = renderListDomId;
        Common.SearchPersonExMatches.setCondoPersonLinkIds(condoPersonLinkTypeIds);
        if (includeDeleted) {
            Common.SearchPersonExMatches.includeDeleted = true;
        }
        else {
            Common.SearchPersonExMatches.includeDeleted = false;
        }
        if (aggregrateResults) {
            Common.SearchPersonExMatches.aggregrateResults = true;
        }
        else {
            Common.SearchPersonExMatches.aggregrateResults = false;
        }
        Common.SearchPersonExMatches.setFilter('');

        //Subscribe to events for the element. Make sure it's not done until the DOM is loaded
        var self = this;
        $(function () {
            self.host.$('input:eq(' + self.inputDomIdx + ')').off();
            self.host.$('input:eq(' + self.inputDomIdx + ')').on('input', function () { self.applyFilter(); });
            if (closePanelOnBlur) {
                self.host.$('input:eq(' + self.inputDomIdx + ')').on('blur', function () {
                    self.inputBlur();
                });
            }
            self.host.$('input:eq(' + self.inputDomIdx + ')').on('keydown', function (e) { self.keyAction(e); });

            //Hide list (will automatically be shown when entries are added to it)
            $(self.renderListDomId).addClass('hidden');
        });
    },

    unload: function (inputDomIdx) {
        Common.SearchPersonExMatches.unload();

        //Don't unload panel already taken by another input element
        if (inputDomIdx) {
            if (this.inputDomIdx != inputDomIdx) {
                return;
            }
        }
        this.viewClassName = null;

        //Unsubscribe to events
        if (this.inputDomIdx != null) {
            this.host.$('input:eq(' + this.inputDomIdx + ')').off('input');
            this.host.$('input:eq(' + this.inputDomIdx + ')').off('blur');
            this.confirmClosePanel();
            this.inputDomIdx = null;
            this.host = null;
        }
    },

    reloadDataFromServer: function () {
        //Get fresh person data to search from
        Common.Persons.fetch();
        Common.CondoPersonLinks.fetch();
    },

    inputBlur: function () {
        this.requestClosePanel();
    },

    requestClosePanel: function () {
        var self = this;
        setTimeout(function () {
            self.confirmClosePanel();
        }, 200);
    },

    confirmClosePanel: function () {
        $(this.renderListDomId).addClass('hidden');
        clearInterval(this.closeTimer);
        if (Common.Persons.highlighted != null) {
            Common.Persons.highlighted.clearHighlight();
        }
    },


    /////////////////////////////////////
    // Input (key)
    /////////////////////////////////////
    keyAction: function (e) {
        var code = e.keyCode || e.which;
        if (code == 27) {
            Common.SearchPersonExMatches.reset();
        }
        if (code == 13) {
            Common.SearchPersonExMatches.selectHighlighted(this.host);
            this.confirmClosePanel();
            event.preventDefault();
        }
        if (code == 40) {
            Common.SearchPersonExMatches.highlightNext();
        }
        if (code == 38) {
            Common.SearchPersonExMatches.highlightPrevious();
        }
    },


    /////////////////////////////////////
    // Filtering
    /////////////////////////////////////
    applyFilter: function () {
        var inputElement = this.host.$('input:eq(' + this.inputDomIdx + ')');

        //Run filter on change of input
        var filter = inputElement.val();
        if (filter !== '' && Common.SearchPersonExMatches.filter == '') {
            //Filter changed from empty to some sort of filter --> trigger reload from server
            this.reloadDataFromServer();
        }
        Common.SearchPersonExMatches.setFilter(filter);

        //Position search results box
        var x = inputElement.offset().left + 10;
        var y = inputElement.offset().top + inputElement.height() + 16;
        $(".searchPersonMenu-list").offset({ left: x, top: y });
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.cancelLazyRender();
        this.remove();
        this.host.$('input:eq(' + self.inputDomIdx + ')').off();

        this.unload();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});