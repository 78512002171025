﻿'use strict';
var Backbone = require('backbone');
var Account = require('../models/account');
var Common = require('../common');

//BuildingAccount Model
//-----------

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        repeatPassword: '',
        accountTypeId: Common.Accounts_conciergeId,
        accountType: ''
    },

    validation: {
        username: {
            required: true,
            minLength: 3
        },
        firstname: {
            required: true
        },
        lastname: {
            required: true
        },
        password: {
            minLength: 3
        },
        repeatPassword: {
            equalTo: 'password',
            msg: 'The passwords do not match'
        }
    },

    initialize: function (options) {
        if (options) {
            this.account = options.account;

            //Setup properties (copy all 'account' to 'buildingAccount', then use 'username' instaed of 'email' )
            this.set(this.account.attributes);
            this.set('username', this.get('email'));
        }
        else {
            //New buildingAccount; set defaults
            this.set('accountType', Common.Accounts_Labels[this.get('accountTypeId')]);
        }

        this.unset('id');
    },

    //Add new account
    create: function () {
        this.set('email', this.get('username'));
        this.set('message', '');
        var self = this;
        //Copy properties into 'account' object
        var account = new Account();
        account.set(this.attributes);
        account.set('addToBuilding', '1');
        this.account = account;

        //Save
        Common.Accounts.add(account);
        account.save(null, {
            success: function (e, a) {
                if (self.account.get('message') != '') {
                    self.set('message', self.account.get('message'));
                    Common.Accounts.remove(self.account);
                }
                else {
                    self.set('id', self.account.get('id'));
                }
            },
            error: function (e) {
                console.log("failed to save. e=" + e);
            }
        });
    },

    //Update existing account
    update: function () {
        this.set('email', this.get('username'));

        //Copy properties into 'account' object
        var account = this.account;
        this.unset('account');
        this.account.set(this.attributes);

        //Save
        this.account.save();
        this.set('account', account);
    }
});
