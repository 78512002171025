'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var CondoPersonLink = require('../models/condoPersonLink');

module.exports = Backbone.Collection.extend({
    model: CondoPersonLink,

    setup: function (options) {
        this.person = options.person;
        this.listenTo(this.person.Condos, "reset", this.resetCondosOnPerson);
        this.listenTo(this.person.Condos, "add", this.addCondosOnPerson);
        var self = this;
        this.person.Condos.forEach(function (condo) {
            self.addCondo(condo);
        });
    },

    resetCondosOnPerson: function () {
        this.reset();
    },

    addCondosOnPerson: function (condo) {
        this.addCondo(condo);
    },

    addCondo: function (condo) {
        var self = this;
        var isOwnerOrTenantForThisCondo = this.person.CondoPersonLinks.findWhere({ condoId: condo.id, isOwnerOrTenant: true });
        if (isOwnerOrTenantForThisCondo) {
            condo.Guests.forEach(function (condoPersonLink) {
                self.add(condoPersonLink);
            });
            this.listenTo(condo.Guests, "reset", this.resetGuestsOnCondo);
            this.listenTo(condo.Guests, "add", this.addedGuestOnCondo);
            this.listenTo(condo.Guests, "remove", this.removedGuestOnCondo);
        }
    },

    removeCondo: function (condo) {
        var self = this;
        condo.Guests.forEach(function (condoPersonLink) {
            self.remove(condoPersonLink);
        });
    },
    resetGuestsOnCondo: function (condoGuests, options) {
        var self = this;
        if (options.previousModels) {
            options.previousModels.forEach(function (condoPersonLink) {
                self.remove(condoPersonLink);
            });
        }
    },

    addedGuestOnCondo: function (condoPersonLink) {
        this.add(condoPersonLink);
    },

    removedGuestOnCondo: function (condoPersonLink) {
        this.remove(condoPersonLink);
    }
});