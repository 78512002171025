﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var TrashItems = require('../models/trashItems');
var CondoPersonLink = require('../models/condoPersonLink');
var Condo = require('../models/condo');
var Building = require('../models/building');
var Log = require('../models/log');

module.exports = Backbone.Model.extend({
    initialize: function () {
        this.TrashItemsCollectionType = Backbone.Collection.extend({
            model: TrashItems
        });
        this.TrashItemsCollection = new this.TrashItemsCollectionType();
        this.listenTo(this.TrashItemsCollection, 'change:open', this.changedOpen);
        this.listenTo(this.TrashItemsCollection, 'add', this.addedTrashItemsModel);
        this.TrashItemsCollection.add(new TrashItems({
            url: Common.apiFolder + 'condoPersonLink/trash.php',
            title: 'Persons',
            getDescription: function (model) {
                var description = model.get('label') + ": " + (model.get('title') ? model.get('title') + " " : "") + (model.get('lastname') ? model.get('lastname') + ", " : "") + (model.get('firstname') ? model.get('firstname') : "") + (model.get('company') ? " (" + model.get('company') + ") " : "");
                return description;
            },
            restore: function (model) {
                var condoPersonLink = new CondoPersonLink(model.attributes);
                condoPersonLink.set('isDeleted', false);
                Common.CondoPersonLinks.add(condoPersonLink);
                condoPersonLink.save();
            },
            setup: function (trashItemsModel) {
                var self = trashItemsModel;
                self.listenTo(Common.Condos, "sync", function () {
                    self.TrashItems.fetch();
                });
            },
            completedRestore: function () { }
        }));
        this.TrashItemsCollection.add(new TrashItems({
            url: Common.apiFolder + 'log/trash.php',
            title: 'Logs',
            getDescription: function (model) {
                var description = model.get('comment');
                return description;
            },
            restore: function (model) {
                var log = new Log(model.attributes);
                log.set('isDeleted', false);
                Common.Logs.add(log);
                log.save();
            },
            completedRestore: function () { }
        }));
        var activeBuilding = Common.Buildings.getActive();
        if (activeBuilding && activeBuilding.get('accountTypeId') == Common.Accounts_propertyManagerId) {
            this.TrashItemsCollection.add(new TrashItems({
                url: Common.apiFolder + 'condo/trash.php',
                title: 'Condos',
                getDescription: function (model) {
                    var description = model.get('label');
                    return description;
                },
                restore: function (model) {
                    var condo = new Condo(model.attributes);
                    condo.set('isDeleted', false);
                    Common.Condos.add(condo);
                    condo.save();
                },
                completedRestore: function () {
                    Common.Condos.fetch();
                }
            }));
            this.TrashItemsCollection.add(new TrashItems({
                url: Common.apiFolder + 'building/trash.php',
                title: 'Buildings',
                getDescription: function (model) {
                    var description = model.get('name');
                    return description;
                },
                restore: function (model) {
                    var activeBuilding = Common.Buildings.findWhere({ isActive: true });
                    var building = new Building(model.attributes);
                    building.set('isDeleted', false);
                    building.set('isActive', false);
                    Common.Buildings.add(building);
                    if (!activeBuilding) {
                        Common.Buildings.setActive(building);
                    }
                    else {
                        building.save();
                    }
                },
                completedRestore: function () {
                }
            }));
        }
    },

    addedTrashItemsModel: function (trashItemsModel) {
        this.evaluateIndex();
    },

    evaluateIndex: function () {
        if (this.TrashItemsCollection.length == 1) {
            this.TrashItemsCollection.models[0].set('index', Common.CollectionLocation_single);
        }
        else {
            var self = this;
            this.TrashItemsCollection.models[0].set('index', Common.CollectionLocation_first);
            for (var i = 1; i < this.TrashItemsCollection.length - 1; i++) {
                self.TrashItemsCollection.models[i].set('index', Common.CollectionLocation_middle);
            }
            this.TrashItemsCollection.models[this.TrashItemsCollection.length - 1].set('index', Common.CollectionLocation_last);
        }
    },

    changedOpen: function (trashItemsModel, open) {
        if (open) {
            this.TrashItemsCollection.forEach(function (m) {
                if (m !== trashItemsModel) {
                    m.set('open', false);
                }
            });
        }
    }
});