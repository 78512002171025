'use strict';
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Person = require('../models/person');

//USAGE:
//-------------------------
//This object maintain reference to 'person' & 'condo' model
//Since the 'person' and 'condo' can appear AFTER creation of this object, the users of this model must subscribe to 'change:person' and 'change:condo' in order to use the object references
//If this object is for a guest/staff, then it will also maintain a reference to 'hostPerson' and 'hostCondoPersonLink' models. Like before, the user of CondoPersonLink will need to subscribe to 'change' events as these objects can be set after time of creation
//
//Users should consider subscribing to the following events to guarantee correct data after creation of element
// - THIS: 'change:person' 
// - THIS: 'change:condo' 
// - condo.CondoPersonLinks: 'reset'
// - condo.CondoPersonLinks: 'add'
// - condo.CondoPersonLinks: 'remove'
// - person.CondoPersonLinks: 'reset'
// - person.CondoPersonLinks: 'add'
// - person.CondoPersonLinks: 'remove'

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        condoPersonLinkTypeId: Common.CondoPersonLinkTypes_ownerId,
        label: '',                  //This property belong to 'condo' object but is returned by server for convenience
        condoId: '',
        personId: '',
        isResident: true,
        isPrimary: false,           //Each Person who is OwnerOrTenant for multiple condo's will have ONE of them set as primary, where the other condo's will be non-primary
        star: false,
        sortOrder: '',
        accessTypeId: Common.AccessTypes_allowedAccessId,
        eventId: null,
        startDate: null,
        endDate: null,
        packageDeliveryPreference: '',
        mailDeliveryPreference: '',
        hostCondoPersonLinkId: '',
        comment: '',
        isDeleted: false,

        //Derived properties
        isStaffOrGuest: false,
        isOwnerOrTenant: false,
        condoPersonLinkType: '',

        //Condo properties (tied to 'Condos' collection that may not be loaded yet at time of creation)
        condo: null,

        //Person properties (tied to 'Persons' collection that may not be loaded yet at time of creation)
        person: null,
        personOutput: '',

        //Host properties (tied to 'Persons' & 'CondoPersonLinks' collection that may not be loaded yet at time of creation)
        hostPerson: null,
        hostCondoPersonLink: null,
        hostPersonName: ''
    },

    urlRoot: Common.apiFolder + 'condoPersonLink/index.php',

    initialize: function (options) {
        this.syncedCondos();
        this.listenTo(Common.Condos, "sync", this.syncedCondos);
        this.syncedPersons();
        this.listenTo(Common.Persons, "sync", this.syncedPersons);
        if (options && options.hostCondoPersonLink) {
            this.set('hostCondoPersonLinkId', options.hostCondoPersonLink.id);
            var hostPerson = options.hostCondoPersonLink.get('person');
            this.set('hostPerson', hostPerson);
            this.set('hostPersonName', hostPerson.get('output'));
            this.set('hostCondoPersonLink', options.hostCondoPersonLink);
        }
        else {
            if (this.get('hostCondoPersonLinkId') != '') {
                this.syncedCondoPersonLinks();//required to fill out 'hostPerson' property
                this.listenTo(Common.CondoPersonLinks, "sync", this.syncedCondoPersonLinks);
            }
        }
        this.changedCondoPersonLinkTypeId(this, this.get('condoPersonLinkTypeId'));//required to fill out property 'condoPersonLinkType', 'isStaffOrGuest', 'isOwnerOrTenant'
        this.listenTo(this, "change:condoPersonLinkTypeId", this.changedCondoPersonLinkTypeId);
    },

    changedCondoPersonLinkTypeId: function (model, condoPersonLinkTypeId) {
        if (condoPersonLinkTypeId == Common.CondoPersonLinkTypes_staffId || condoPersonLinkTypeId == Common.CondoPersonLinkTypes_guestId) {
            this.set('isStaffOrGuest', true);
        }
        else {
            this.set('isStaffOrGuest', false);
        }
        if (condoPersonLinkTypeId == Common.CondoPersonLinkTypes_ownerId || condoPersonLinkTypeId == Common.CondoPersonLinkTypes_tenantId) {
            this.set('isOwnerOrTenant', true);
        }
        else {
            this.set('isOwnerOrTenant', false);
        }
        this.set('condoPersonLinkType', Common.CondoPersonLinkTypes.getNameFromId(condoPersonLinkTypeId));
    },

    //This method must be called if condoId is set AFTER constructur
    setCondoId: function (condoId) {
        this.set('condoId', condoId);
        this.syncedCondos();
    },

    syncedCondos: function () {
        var condo = Common.Condos.findWhere({ id: this.get('condoId') });
        if (condo) {
            if (this.get('condo')) {
                this.stopListening(this.get('condo'));
            }
            this.set('label', condo.get('label'));
            this.set('condo', condo);
            this.listenTo(condo, "change:label", this.changedCondoLabel);
        }
    },

    changedCondoLabel: function (condo, label) {
        this.set('label', label);
    },

    syncedPersons: function () {
        var person = Common.Persons.findWhere({ id: this.get('personId') });
        if (person) {
            if (this.get('person')) {
                this.stopListening(this.get('person'));
            }
        }
        else {
            person = new Person();
        }
        this.set('person', person);
        this.changedPersonOutput(person, person.get('output'));
        this.listenTo(this.get('person'), "change:output", this.changedPersonOutput);
        if (this.get('hostCondoPersonLink')) {
            var hostPerson = Common.Persons.findWhere({ id: this.get('hostCondoPersonLink').get('personId') });
            if (hostPerson) {
                if (this.get('hostPerson')) {
                    this.stopListening(this.get('hostPerson'));
                }
                this.set('hostPersonName', hostPerson.get('output'));
                this.set('hostPerson', hostPerson);
                this.listenTo(hostPerson, "change:output", this.changedHostPersonOutput);
            }
        }
    },

    syncedCondoPersonLinks: function () {
        var hostCondoPersonLink = Common.CondoPersonLinks.findWhere({ id: this.get('hostCondoPersonLinkId') });
        if (hostCondoPersonLink) {
            this.set('hostCondoPersonLink', hostCondoPersonLink);
            this.syncedPersons();
        }
    },

    changedPersonOutput: function (hostPerson, output) {
        this.set('personOutput', output);
    },


    changedHostPersonOutput: function (hostPerson, output) {
        this.set('hostPersonName', output);
    },

    create: function () {
        Common.CondoPersonLinks.add(this);
        this.save();
    },

    getServerAttributes: function () {
        var attrs = _.clone(this.attributes);
        attrs = this.trimNonServerAttributes(attrs);
        return attrs;
    },

    trimNonServerAttributes: function (attrs) {
        delete attrs.condo;
        delete attrs.person;
        delete attrs.hostPerson;
        delete attrs.hostCondoPersonLink;
        return attrs;
    },

    allowDeletion: function () {
        //Always allow deletion of non-owner/tenants
        if (this.get('isOwnerOrTenant') == false) {
            return true;
        }

        //Never allow deletion of the last owner/tenant as that would leave the condo without contact persons
        var condo = this.get('condo');
        if (condo) {
            var ownersOrTenants = condo.CondoPersonLinks.where({ isOwnerOrTenant: true });
            if (ownersOrTenants.length > 1) {
                return true;
            }
        }
        return false;
    },

    allowChangeTypeAndCondo: function (newCondoPersonLinkTypeId, newCondoId) {
        //Always allow change away from non-owner/tenants
        if (this.get('isOwnerOrTenant') == false) {
            return true;
        }

        //Never allow change away for a owner/tenant so that there are no owner/tenants left for the original condo
        var condo = this.get('condo');
        if (condo) {
            var ownersOrTenants = condo.CondoPersonLinks.where({ isOwnerOrTenant: true });
            if (newCondoId == this.get('condoId')) {
                //Same condo:
                if (newCondoPersonLinkTypeId == Common.CondoPersonLinkTypes_ownerId || newCondoPersonLinkTypeId == Common.CondoPersonLinkTypes_tenantId) {
                    return true;
                }
                else {
                    if (ownersOrTenants.length > 1) {
                        return true;
                    }
                }
            }
            else {
                //Change of condo:
                if (ownersOrTenants.length > 1) {
                    return true;
                }
            }
        }
        return false;
    },

    save: function (attrs, options) {
        //Clean up data prior to save
        if (this.get('isStaffOrGuest') == false) {
            this.set('hostCondoPersonLinkId', '');
            this.set('hostPerson', null);
            this.set('hostCondoPersonLink', null);
            this.set('hostPersonName', '');
        }

        options || (options = {});
        attrs || (attrs = _.clone(this.attributes));

        // Filter the data to send to the server (remove the objects created for internal logic)
        attrs = this.trimNonServerAttributes(attrs);

        options.data = JSON.stringify(attrs);

        //Is this a new person?
        var self = this;
        if (this.get('personId') === '') {
            var person = this.get('person');

            //Validate person (must be non-empty)
            if (person.get('isEmpty')) {
                return;
            }

            Common.Persons.add(person);
            person.save(null, {
                success: function () {
                    self.set('personId', person.id);
                    attrs.personId = person.id;
                    self.save(attrs, options);
                }
            });
        }
        else {
            //Is this a new entry?
            if (!this.id) {
                Common.CondoPersonLinks.add(this);
            }

            // Proxy the call to the original save function
            return Backbone.Model.prototype.save.call(this, attrs, options);
        }
    }
});