﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var PersonToday = require('../models/personToday');
var LogTodayView = require('../views/logTodayView');
var PersonTodayView = require('../views/personTodayView');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing TodayView');

        //Set menu
        Common.MainMenuEntries.setSelected('today');
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#today-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template());
        $(function () {
            $('#pageTitle').html('Today');
            self.renderLogs();
            self.renderGuests();
        });
        return this;
    },

    renderLogs: function () {
        var self = this;
        Common.Logs.fetch({
            success: function () {
                Common.Logs.forEach(function (log) {
                    if (log.get('star') == true) {
                        var view = new LogTodayView({ model: log });
                        self.$("#logList").prepend(view.render().el);
                    }
                });
            }
        });

    },

    renderGuests: function () {
        var self = this;
        Common.CondoPersonLinks.fetch({
            success: function () {
                Common.Persons.fetch({
                    success: function () {
                        Common.CondoPersonLinks.forEach(function (condoPersonLink) {
                            if (!condoPersonLink.get('hostCondoPersonLinkId')) {
                                return;
                            }
                            if (condoPersonLink.get('star') == true) {
                                var model = new PersonToday({ condoPersonLink: condoPersonLink });
                                var view = new PersonTodayView({ model: model });
                                self.$("#guestList").prepend(view.render().el);
                            }
                        });
                    }
                });
            }
        });
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});