﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing CreateAccountView');

        Backbone.Validation.bind(this);
        this.listenTo(Common.Accounts, 'sync', this.accountSync);
        this.listenTo(Common.Accounts, 'change', this.accountChange);
        Common.inputView = this;

        //Clear menu
        Common.MainMenuEntries.setSelected('');
    },

    events: {
        "click #createButton": "createAccount",
        "click #login": "login",
        "change input": "inputChanged",
        "focus input": "inputFocus",
        "blur input": "inputBlur"
    },

    //Cache the template function for a single item
    template: _.template($('#createAccount-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        $(this.el).html(this.template());
        $(function () {
            $('#pageTitle').html('Create Account');
        });
        return this;
    },

    inputChanged: function (eventName, args) {

        // Validate one attribute
        // The 'errorsMessage' returned is a string
        var attr = eventName.currentTarget.name;
        var value = eventName.currentTarget.value;
        var view = Common.inputView;
        view.fillModelWithDataFromView();
        //var error = view.model.isValid(attr);
        var error = view.model.preValidate(attr, value);

        if (error) {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            $group.addClass('has-error');
            $group.find('.help-block').html(error).removeClass('hidden');
        }
        else {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            $group.removeClass('has-error');
            $group.find('.help-block').html('').addClass('hidden');
        }
    },

    inputFocus: function (eventName, args) {
        //Skip validation if we enter a blank element, unless it has errors, OR its the 'repeatpassword' field
        var attr = eventName.currentTarget.name;
        var value = eventName.currentTarget.value;
        var view = Common.inputView;
        if (value == "") {
            var $el = view.$('[name=' + attr + ']'),
                $group = $el.closest('.control-group');
            if (!$group.hasClass('has-error')) {
                if (attr != "repeatPassword") {
                    return;
                }
            }
        }

        //Subscribe to 'input' change events to update validation live
        var id = "#" + eventName.currentTarget.id;
        $(id).on('input', this.inputChanged);
    },

    inputBlur: function (eventName, args) {
        var id = "#" + eventName.currentTarget.id;
        $(id).off('input', this.inputChanged);

        //Always run validaton when we leave a field
        this.inputChanged(eventName, args);
    },

    accountSync: function (eventName, args) {
        //If we click 'create' multiple times would not be told from server that it was rejected. Hence an initial check
        if (args.email === undefined) {
            $('#serverError').text(eventName.get('message')).show();
        }
    },

    accountChange: function (eventName, args) {
        if (eventName.changed != null && eventName.changed.message != null) {
            $('#serverError').text(eventName.changed.message).show();
        }
    },

    fillModelWithDataFromView: function () {
        var form = $("form:first-of-type");
        var data = form.serializeObject();
        this.model.set(data);
    },

    createAccount: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        $('#serverError').text("").hide();// Hide any servers on a new submit

        //Send data from form to model (consider attaching this to an event for input changes)
        this.fillModelWithDataFromView();

        //Validate data prior to sending to server
        if (!this.model.isValid(true)) {
            return;
        }

        this.model.set('autoLogin', true);
        Common.Accounts.add(this.model);
        this.model.save();

        Common.Router.navigate("#", { trigger: true });
        return;
    },

    login: function (e) {
        e.preventDefault();
        Common.Router.navigate("#login", { trigger: true });
    },

    destroy: function () {
        this.remove();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});