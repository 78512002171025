'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports =Backbone.View.extend({

        initialize: function () {
            console.log('Initializing EditAccountView');

            Backbone.Validation.bind(this);
            Common.inputView = this;

            //Clear menu
            Common.MainMenuEntries.setSelected('');
        },

        events: {
            "click #editButton": "editAccount",
            "click #changePassword": "changePassword",
            "change input": "inputChanged",
            "focus input": "inputFocus",
            "blur input": "inputBlur",
            "blur textarea": "textAreaBlur"
        },

        //Cache the template function for a single item
        template: _.template($('#editAccount-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

        render: function () {
            $(this.el).html(this.template(this.model.attributes));
            $(function () {
                $('#pageTitle').html('Edit Account');
            });
            return this;
        },

        inputChanged: function (eventName, args) {

            // Validate one attribute
            // The 'errorsMessage' returned is a string
            var attr = eventName.currentTarget.name;
            var value = eventName.currentTarget.value;
            var view = Common.inputView;
            view.fillModelWithDataFromView();
            var error = view.model.preValidate(attr, value);

            if (error) {
                var $el = view.$('[name=' + attr + ']'),
                    $group = $el.closest('.control-group');
                $group.addClass('has-error');
                $group.find('.help-block').html(error).removeClass('hidden');
            }
            else {
                var $el = view.$('[name=' + attr + ']'),
                    $group = $el.closest('.control-group');
                $group.removeClass('has-error');
                $group.find('.help-block').html('').addClass('hidden');
            }
        },

        inputFocus: function (eventName, args) {
            //Skip validation if we enter a blank element, unless it has errors, OR its the 'repeatpassword' field
            var attr = eventName.currentTarget.name;
            var value = eventName.currentTarget.value;
            var view = Common.inputView;
            if (value == "") {
                var $el = view.$('[name=' + attr + ']'),
                    $group = $el.closest('.control-group');
                if (!$group.hasClass('has-error')) {
                    if (attr != "repeatPassword") {
                        return;
                    }
                }
            }

            //Subscribe to 'input' change events to update validation live
            var id = "#" + eventName.currentTarget.id;
            $(id).on('input', this.inputChanged);
        },

        inputBlur: function (eventName, args) {
            var id = "#" + eventName.currentTarget.id;
            $(id).off('input', this.inputChanged);

            //Always run validaton when we leave a field
            this.inputChanged(eventName, args);
        },

        textareaBlur: function (eventName, args) {
            var id = "#" + eventName.currentTarget.id;
            $(id).off('textarea', this.inputChanged);

            //Always run validaton when we leave a field
            this.inputChanged(eventName, args);
        },

        fillModelWithDataFromView: function () {
            var form = $("form:first-of-type");
            var data = form.serializeObject();
            this.model.set(data);
        },

        editAccount: function (e) {
            e.preventDefault(); // Don't let this button submit the form
            $('#serverError').text("").hide();// Hide any servers on a new submit

            //Send data from form to model (consider attaching this to an event for input changes)
            this.fillModelWithDataFromView();

            //Highlight validation errors
            this.model.isValid(true);

            //Prevent sending invalid data to server
            if (!this.model.isValid(['firstname', 'lastname'])) {
                return;
            }

            //Update server (skip validation while we write)
            var validator = this.model.validate;
            this.model.validate = null;
            this.model.save();
            this.model.validate = validator;

            //Redirect to homepage
            //TODO: investifgate more elegant solution
            Common.Router.navigate("#", { trigger: true });
        },

        changePassword: function (e) {
            e.preventDefault();
            Common.Router.navigate("#changePassword", { trigger: true });
        },

        destroy: function () {
            this.remove();

            // Remove the validation binding
            // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
            Backbone.Validation.unbind(this);
            return Backbone.View.prototype.remove.apply(this, arguments);
        }
});