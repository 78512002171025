﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Log = require('../models/log');

module.exports = Backbone.Collection.extend({
    model: Log,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'log/index.php',
});