'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Checkin = require('../models/checkin');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PersonTodayView' + this.cid);

        this.listenTo(Common.CondoPersonLinks, 'change:star', this.changedStar);
    },

    tagName: 'div',
    className: 'box todayCell click',

    events: {
        "click ": "clickedEntry",
        "click #star": "clickedStar",
        "click #checkin": "clickedCheckin"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#personToday-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        return this;
    },

    hideAnimation() {
        var self = this;
        $(this.el).animate(
            {
                'opacity': 0,
                'height': 0,
                'padding-top': 0,
                'padding-bottom': 0,
                'min-height': 0,
                'margin-bottom': -8,
                'border-top-width': 0,
                'border-bottom-width': 0
            }, 500, function () {
                self.destroy();
            });
    },

    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    changedStar: function (cpl) {
        if (cpl == this.model.condoPersonLink) {
            if (cpl.get('star') == false) {
                this.hideAnimation();
            }
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        e.stopPropagation();
        Common.Router.navigate("#person/" + this.model.condoPersonLink.get('id'), { trigger: true });
    },

    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.model.condoPersonLink.set('star', false);
        this.model.condoPersonLink.save();
        this.hideAnimation();
    },

    clickedCheckin: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var checkin = new Checkin({
            condoPersonLink: this.model.condoPersonLink
        });
        checkin.tryCheckin();
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});