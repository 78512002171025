'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
    },

    urlRoot: '',

    initialize: function () {
        Common.Condos.setFilter('');
    },

    setFilter: function (filter) {
        Common.Condos.setFilter(filter);
    },

    load: function () {
        Common.Persons.fetch({
            success: function () {
                Common.CondoPersonLinks.fetch();
            }
        });
    }
});  