﻿'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    defaults: {
        deletedTime: '',
        description: '',
        selected: false
    },

    initialize: function (options) {
        this.set('deletedTime', this.calcTimeDistance());
        var description = this.collection.getDescription(this);
        this.set('description', description);
    },

    toggleSelection: function () {
        this.set('selected', !this.get('selected'));
    },

    calcTimeDistance: function () {
        var deletedTimedistance = this.get('deletedTimedistance');//[ms]
        var distanceInMinutes = Math.round(- deletedTimedistance / 60);
        var distanceInHours = Math.round(- deletedTimedistance / 60 / 60);
        var distanceInDays = Math.round(- deletedTimedistance / 60 / 60);

        if (distanceInMinutes < 2) {
            return "Just now";
        }
        else if (distanceInMinutes === 1) {
            return "1 minute ago";
        }
        else if (distanceInMinutes < 60) {
            return distanceInMinutes + " minutes ago";
        }
        else if (distanceInHours === 1) {
            return "1 hour ago";
        }
        else if (distanceInHours < 24) {
            return distanceInHours + " hours ago";
        }
        else if (distanceInHours < 600) {
            return distanceInDays + " days ago";
        }
        else {
            return "-";
        }
    }
});