﻿/*global define*/
'use strict';

var apiFolder;
//Setup folder relative to web server (required for dev/hosting switching)
if (window.location.hostname == 'localhost' || window.location.hostname == '192.168.1.81') {
    apiFolder = 'http://localhost:8081/concierge/api/';
}
else {
    apiFolder = '/api/';
}

module.exports = {

    apiFolder: apiFolder,
    LoggedInUser: null,
    dataSetupRedirector: null,
    keepAliveTimer: null,
    currentView: null,
    mainMenu: null,
    searchPersonView: null,
    searchLogListView: null,
    currentDialogView: null,
    appView: null,

    // What is the enter key constant?
    ENTER_KEY: 13,
    ESCAPE_KEY: 27,

    CondoPersonLinkTypes_ownerId: "1",
    CondoPersonLinkTypes_tenantId: "2",
    CondoPersonLinkTypes_guestId: "3",
    CondoPersonLinkTypes_staffId: "4",
    CondoPersonLinkTypes_formerResidentId: "5",
    CondoPersonLinkTypes_deceasedId: "6",
    CondoPersonLinkTypes_Labels: ['', 'Owner', 'Tenant', 'Guest', 'Staff', 'Past resident', 'Deceased'],

    AccessTypes_callFirstId: "1",
    AccessTypes_dontCallId: "2",
    AccessTypes_allowedAccessId: "3",
    AccessTypes_noAccessId: "4",

    Accounts_propertyManagerId: "1",
    Accounts_conciergeId: "2",
    Accounts_securityOfficerId: "3",
    Accounts_Labels: ["", "Property manager", "Concierge", "Security officer"],

    Logs_profileCreatedId: "1",
    Logs_profileUpdatedId: "2",
    Logs_profileDeletedId: "3",
    Logs_mailDeliveredId: "5",
    Logs_mailInMailstopId: "6",
    Logs_packageReceivedId: "7",
    Logs_packageDeliveredId: "8",
    Logs_eventCreatedId: "9",
    Logs_eventUpdatedId: "10",
    Logs_eventDeletedId: "11",
    Logs_outOfTownCreatedId: "13",
    Logs_outOfTownUpdatedId: "14",
    Logs_outOfTownDeletedId: "15",
    Logs_guestCheckinRejectedByHostId: "20",
    Logs_guestCheckinRejectedHostUnavailableId: "21",
    Logs_guestCheckinApprovedByHostId: "22",
    Logs_guestCheckinPreApprovedByHostId: "23",
    Logs_guestCheckinPreRejectedByHostId: "24",

    CollectionLocation_single: 1,
    CollectionLocation_first: 2,
    CollectionLocation_middle: 3,
    CollectionLocation_last: 4,

    getFiltered: function (source, filter) {
        if (filter === "") {
            return "";
        }
        filter = filter.toLowerCase();
        var filtered = "";
        var startPos = 0;

        //Get initial filter pos
        var matchPos = source.toLowerCase().indexOf(filter);
        if (matchPos >= 0) {
            while (matchPos >= 0) {
                filtered = filtered + source.substring(startPos, matchPos);
                filtered = filtered + "<span>" + source.substring(matchPos, matchPos + filter.length) + "</span>";
                startPos = matchPos + filter.length;
                matchPos = source.toLowerCase().indexOf(filter, startPos);
            }
            filtered = filtered + source.substring(startPos, source.length);
            return filtered;
        }
        else {
            return "";
        }
    }
};