﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

var _sync = Backbone.sync;
Backbone.sync = function (method, model, options) {

    var beforeSend = options.beforeSend;
    options.beforeSend = function (xhr) {
        var token = sessionStorage.getItem("csrfToken");
        if (token == null) {
            token = '';
        }
        xhr.setRequestHeader('X-Csrf-Token', token);
        if (beforeSend) return beforeSend.apply(this, arguments);
    };

    return _sync.call(this, method, model, options);
}
module.exports = null;
