﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing EmailView=' + this.cid);

        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "change #email": "userChangedEmail",
        "change #comment": "userChangedComment"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#email-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.$('#comment').val(self.model.get('comment'));
        });
        return this;
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedEmail: function () {
        this.model.set('email', this.$("#email").val());
    },

    userChangedComment: function () {
        this.model.set('comment', this.$("#comment").val());
    },


    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.$("#email").val('');
        this.$("#comment").val('');
        this.model.set('email', '');
        this.model.set('comment', '');
        this.model.set('isDeleted', true);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});