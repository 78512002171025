﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var AccountView = require('../views/accountView');
var BuildingView = require('../views/buildingView');
var MainMenuView = require('../views/mainMenuView');
var SearchPersonView = require('../views/searchPersonView');
var SearchLogListView = require('../views/searchLogListView');
var Cookies = require('js-cookie');

module.exports = Backbone.View.extend({

    //Instead of generating a new element, 
    //bind to the existing skeleton of the App already present in the HTLM (referred to by ID)
    el: $("#conciergeapp"),

    initialize: function () {
        this.render();
        this.listenTo(Common.Accounts, "sync", this.synced);
        this.listenTo(Common.Condos, "sync", this.synced);
        this.listenTo(Common.Buildings, "sync", this.synced);
        this.listenTo(Common.Logs, "sync", this.synced);
        this.listenTo(Common.CondoPersonLinks, "sync", this.synced);

        ///////////////////////////////////////////////////////////////////////
        //                      VERSION MANAGEMENT                           // 
        ///////////////////////////////////////////////////////////////////////
        //NOTE: The version set in file 'appView.js' must be identical to version set in file 'login.php'
        this.version = 8;

        //Retrieve account info. 
        var self = this;
        var view;
        $(function () {
            //Build TopLeft menu (account menu)
            view = new AccountView();
            $('#account').html(view.render().el);

            //Build TopLeft, Lower menu (Building menu)
            view = new BuildingView();
            $('#building').html(view.render().el);

            //Build MainMenu
            Common.mainMenu = new MainMenuView();
            Common.mainMenu.render();

            //Create search results panel for selecting persons
            Common.searchPersonView = new SearchPersonView();
            $('#searchPerson').html(Common.searchPersonView.render().el);

            //Create search results panel for selecting logs
            Common.searchLogListView = new SearchLogListView();
            $('#searchLog').html(Common.searchLogListView.render().el);

            //Load initial data
            Common.Accounts.fetch({
                success: function () {
                    Common.Buildings.fetch({
                        success: function () {
                            Common.Condos.fetch({
                                success: function () {
                                    //NOTE: Data is loaded from server. 
                                }
                            });
                        }
                    });
                }
            });

            //Close dialogs if clicking on masked out background
            $("#fullScreenDialog").on('click', function () {
                self.closeDialog();
            });
        });
    },

    requestDialog: function (dialogView) {
        if (Common.currentDialogView) {
            Common.currentDialogView.destroy();
        }
        Common.currentDialogView = dialogView;
        $("#fullScreenBlackout").removeClass('hidden');
        $("#fullScreenDialog").removeClass('hidden');
        $('#fullScreenDialog').html(dialogView.render().el);
    },

    closeDialog: function () {
        if (Common.currentDialogView) {
            Common.currentDialogView.destroy();
            Common.currentDialogView = null;
            $("#fullScreenBlackout").addClass('hidden');
            $("#fullScreenDialog").addClass('hidden');
        }
    },

    synced: function (collection, data, x, y, z) {
        var token = Cookies.get('serverResponseOptions');
        if (token) {
            token = JSON.parse(token);
            if (token.version) {
                if (this.version < token.version) {
                    console.log('html too old');
                    window.location.reload(true);
                }
            }
        }
    },

    isDialog: function (view) {
        return Common.currentDialogView === view;
    }
});