'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        label: ''
    },

    urlRoot: '',

    initialize: function () {
    }
});