﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var TrashItemsView = require('../views/trashItemsView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing TrashView');

        this.views = [];
    },

    events: {
        //Mouse over effect
        "mouseover .click": "requestHighlight",
        "mouseleave .click": "releaseHighlight"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#trash-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        $("#pageTitle").html("Trash");
        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            self.model.TrashItemsCollection.forEach(function (trashItemsModel) {
                var view = new TrashItemsView({ model: trashItemsModel });
                self.$('#list').append(view.render().el);
            });
        });
        return this;
    },

    requestHighlight: function (e) {
        e.currentTarget.classList.add('boxHighlight');
    },

    releaseHighlight: function (e) {
        e.currentTarget.classList.remove('boxHighlight');
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.views.forEach(function (view) {
            view.destroy();
        });
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});