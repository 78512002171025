﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var DeletePrompt = require('../models/deletePrompt');
var DeletePromptView = require('../views/deletePromptView');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing CondoThumbView' + this.cid);

        this.listenTo(Common.Condos, 'change:isHidden', this.changedIsHidden);
        this.listenTo(Common.Condos, 'change:labelOutput', this.changedLabelOutput);
        this.listenTo(Common.Condos, 'change:residentsLastnameSummaryOutput', this.changedResidentsLastnameSummaryOutput);

        this.isNew = options.isNew;
    },

    tagName: 'div',
    className: 'box condoThumb click',

    events: {
        "click ": "clickedEntry",
        "click #delete": "clickedDelete",
        "mouseover ": "requestHighlight",
        "mouseleave": "releaseHighlight"
    },

    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#condoThumb-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        if (this.isNew == true) {
            $(this.el).addClass('newAdditionAnimation');
        }
        return this;
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        Common.Router.navigate("#condo/" + this.model.get('id'), { trigger: true });
    },

    requestHighlight: function () {
        $(this.el).addClass('boxHighlight');
    },

    releaseHighlight: function () {
        $(this.el).removeClass('boxHighlight');
    },

    clickedDelete: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        //Show dialog
        var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('label') });
        var view = new DeletePromptView({ model: model });
        Common.appView.requestDialog(view);
    },

    delete: function () {
        this.model.CondoPersonLinks.forEach(function (condoPersonLink) {
            condoPersonLink.destroy();
        });
        this.destroy();
    },



    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changedIsHidden: function (model, isHidden) {
        if (model.id == this.model.id) {
            if (isHidden) {
                $(this.el).addClass('hidden');
            }
            else {
                $(this.el).removeClass('hidden');
            }
        }
    },

    changedLabelOutput: function (model, labelOutput) {
        if (model == this.model) {
            this.$('#name').html(labelOutput);
        }
    },

    changedResidentsLastnameSummaryOutput: function (model, residentsLastnameSummaryOutput) {
        if (model == this.model) {
            this.$('#residents').html(residentsLastnameSummaryOutput);
        }
    },

    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});