'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing BuildingMenuEntryView');
    },

    events: {
        "click #selectBuildingMenuEntry": "selectBuilding"
    },

    template: _.template($('#buildingMenuEntry-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        return this;
    },

    selectBuilding: function (e) {
        e.preventDefault();
        Common.Buildings.setActive(this.model);

        Common.Router.navigate("#", { trigger: true });
    },

    destroy: function () {
        this.remove();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});