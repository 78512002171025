﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Checkin = require('../models/checkin');
var CondoPersonLink = require('../models/condoPersonLink');
var Person = require('../models/person');
var DeletePrompt = require('../models/deletePrompt');
var DeletePromptView = require('../views/deletePromptView');
var PromptView = require('../views/promptView');

//Model: CondoPersonLink
module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PersonExView=' + this.cid);

        //Link to auxilary models(s)
        this.isFirst = options.isFirst;
        if (options.condo) {
            this.condo = options.condo;
        }

        //Model data changes --> update UI
        this.listenTo(this.model, 'change:star', this.renderStar);
        this.listenTo(this.model, 'remove', this.destroy);
    },

    events: {
        //User trigger: Select person type from dropdown
        "click .personTypeItem": "userChangedCondoPersonLinkType",
        "click #personExProfileTypeMenu": "openPersonTypeMenu",

        //User trigger: deletion
        "click #delete": "requestDeletePerson",

        //Model: user input --> update mmodel 
        "change input:eq(0)": "userChangedLastname",
        "change input:eq(1)": "userChangedFirstname",

        //Title
        "click #titleMenu": "openTitleMenu",
        "click .dropdownTitleItem": "clickedTitleItem",

        //Links
        "click #nameOutput": "clickedName",

        //User actions
        "click #checkin": "clickedCheckin",
        "click #addNew": "clickedAdd",
        "click #cancel": "clickedCancel",
        "click #save": "clickedSave",
        "click #star": "clickStar"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#personEx-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            //Render as existing or new
            if (self.model && self.model.id) {
                self.renderAsExisting();

                //Separator line: hidden always if first
                if (self.isFirst) {
                    self.$('#profileLineSeparator').hide();
                }
            }
            else {
                self.renderAsAddNew();
            }
        });
        return this;
    },

    renderAsExisting: function () {
        this.renderTitle();

        //Build combox
        this.buildCondoPersonLinkTypeComboBox();

        this.$("#existing").removeClass('hidden');
        this.$("#addNew").addClass('hidden');
        this.$("#createNew").addClass('hidden');
        this.buildCondoPersonLinkTypeComboBox();
        this.$("#nameOutput").html(this.model.get('personOutput'));
        this.renderStar();

        //Checkin button
        if (this.model.get('isStaffOrGuest')) {
            this.$('#checkin').show();
            this.$('#star').show();
        }
        else {
            this.$('#checkin').hide();
            this.$('#star').hide();
        }
    },

    renderAsAddNew: function () {
        this.renderTitle();
        this.$("#existing").addClass('hidden');
        this.$("#addNew").removeClass('hidden');
        this.$("#createNew").addClass('hidden');
    },

    renderAsCreateNew: function () {
        //Build combox
        this.buildCondoPersonLinkTypeComboBox();

        //Clean content in UI
        this.$('input:eq(0)').val('');
        this.$('input:eq(1)').val('');

        this.$("#existing").addClass('hidden');
        this.$("#addNew").addClass('hidden');
        this.$("#createNew").removeClass('hidden');
    },

    renderStar: function () {
        if (this.model.get('star') == true) {
            this.$('#star').addClass('starred');
            this.$('#star').removeClass('unStarred');
        }
        else {
            this.$('#star').removeClass('starred');
            this.$('#star').addClass('unStarred');
        }
    },

    buildCondoPersonLinkTypeComboBox: function () {
        //Set current type in UI
        var typeString = this.model.get('condoPersonLinkType');
        this.$("#personTypeLabel").html(typeString);
        switch (this.model.get('condoPersonLinkTypeId')) {
            case Common.CondoPersonLinkTypes_ownerId:
            case Common.CondoPersonLinkTypes_tenantId:
                if (this.model.get('isResident') == true) {
                    typeString = typeString + ", resident";
                }
                break;
        }
        this.$("#personTypeOutputLabel").html(typeString);
    },

    renderTitle: function () {
        if (this.model) {
            var title = this.model.get('person').get('title');
        }
        if (!title) {
            title = "Title";
        }
        this.$("#titleLabel").html(title);
    },

    renderFirstname: function () {
        this.$('input:eq(3)').val(this.model.get('person').get('firstname'));
        this.$('#firstname').val(this.model.get('person').get('firstname'));
    },

    renderMiddlename: function () {
        this.$('#middlename').val(this.model.get('person').get('middlename'));
    },

    renderLastname: function () {
        this.$('#lastname').val(this.model.get('person').get('lastname'));
    },


    /////////////////////////////////////
    // Menu: person type
    /////////////////////////////////////
    userChangedCondoPersonLinkType: function (e) {
        e.preventDefault();
        var condoPersonLinkTypeId = e.currentTarget.getAttribute('name');
        this.model.set('condoPersonLinkTypeId', condoPersonLinkTypeId);
        this.buildCondoPersonLinkTypeComboBox();
    },
    openPersonTypeMenu: function (e) {
        if (this.$('#personTypeMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#personTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },


    /////////////////////////////////////
    // Menu: title
    /////////////////////////////////////
    openTitleMenu: function (e) {
        if (this.$('#titleMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#titleMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    clickedTitleItem: function (e) {
        e.preventDefault();
        var title = e.target.innerText;
        this.model.get('person').set('title', title);
        this.$("#titleLabel").html(title);
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedLastname: function () {
        this.model.get('person').set('lastname', this.$("input:eq(0)").val());
    },
    userChangedFirstname: function () {
        this.model.get('person').set('firstname', this.$('input:eq(1)').val());
    },
    clickedName: function (e) {
        e.preventDefault();
        Common.Router.navigate("#person/" + this.model.id, { trigger: true });
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedAdd: function (e) {
        e.preventDefault();
        var condoPersonLink = new CondoPersonLink({
            condoPersonLinkTypeId: Common.CondoPersonLinkTypes_ownerId,
            condoId: this.condo.id,
            isResident: false,
            star: false,
            accessTypeId: Common.AccessTypes_allowedAccessId,
            hostCondoPersonLink: this.condo.getHostCondoPersonLink()
        });
        this.model = condoPersonLink;
        this.renderAsCreateNew();
    },

    clickedCancel: function (e) {
        e.preventDefault();
        if (this.model.get('person').has('id')) {
            this.renderAsExisting();
        }
        else {
            this.model = null;
            this.renderAsAddNew();
            this.renderTitle();
            this.$('input:eq(0)').val('');
            this.$('input:eq(1)').val('');
        }
    },

    clickedSave: function (e) {
        e.preventDefault();
        var self = this;

        //Default: set IsResident = true for owners and tenants
        if (this.model.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId ||
            this.model.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_tenantId) {
            this.model.set('isResident', true);
        }

        //Default: set access to "call first" for guest/staff
        if (this.model.get('isStaffOrGuest')) {
            this.model.set('isResident', false);
            this.model.set('accessTypeId', Common.AccessTypes_callFirstId);
        }

        //A name is required to add a person
        var person = this.model.get('person');
        if (!person) {
            self.renderAsAddNew();
            return;
        }
        else {
            if (person.get('lastname') == '' && person.get('firstname') == '') {
                self.renderAsAddNew();
                return;
            }
        }

        this.model.save();
        this.model = null;
        this.renderAsAddNew();
    },

    clickedCheckin: function (e) {
        e.preventDefault();
        var checkin = new Checkin({
            condoPersonLink: this.model
        });
        checkin.tryCheckin();
    },

    clickStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.get('star') == false) {
            this.model.set('star', true);
        }
        else {
            this.model.set('star', false);
        }
        this.model.save();
    },



    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    requestDeletePerson: function (e) {
        e.preventDefault(); // Don't let this button submit the form

        //Show dialog
        var view;
        if (this.model.allowDeletion()) {
            var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('personOutput') });
            view = new DeletePromptView({ model: model });
        }
        else {
            view = new PromptView({ message: 'Its not allowed to delete the last owner/tenant of a condo.<br><br>Alternatively delete the entire condo or add another owner/tenant before deleting this one', header: 'Deletion rejected' });
        }
        Common.appView.requestDialog(view);
    },

    delete: function () {
        this.model.destroy();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();

        this.$('input:eq(3)').off('input');

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});