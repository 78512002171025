'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var BuildingAccount= require('../models/buildingAccount');
var DeletePrompt= require('../models/deletePrompt');
var EditBuildingAccountView= require('../views/editBuildingAccountView');
var DeletePromptView = require('../views/deletePromptView');

module.exports =Backbone.View.extend({

        initialize: function () {
            console.log('Initializing BuildingAccountView');

            this.listenTo(this.model, 'change', this.changedAccount);
            this.listenTo(Common.Accounts, 'remove', this.removedAccount);
        },

        events: {
            "click #edit": "clickedEdit",
            "click #delete": "clickedDelete"
        },

        //Cache the template function for a single item
        template: _.template($('#buildingAccount-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

        render: function () {
            $(this.el).html(this.template(this.model.attributes));
            var self = this;
            $(function () {
                self.$("#type").html("(" + self.model.get('accountType') + ")");
            });
            return this;
        },

        clickedEdit: function (e) {
            e.preventDefault();
            e.stopPropagation();

            //Show dialog
            var model = new BuildingAccount({ account: this.model });
            var view = new EditBuildingAccountView({ model: model });
            Common.appView.requestDialog(view);
        },

        clickedDelete: function (e) {
            e.preventDefault(); // Don't let this button submit the form
            e.stopPropagation();

            //Show dialog
            var model = new DeletePrompt({ host: this, deleteMessage: this.model.get('name') });
            var view = new DeletePromptView({ model: model });
            Common.appView.requestDialog(view);
        },

        delete: function () {
            this.model.destroy();
        },

        changedAccount: function () {
            this.render();
        },

        removedAccount: function (m) {
            if (m === this.model) {
                this.destroy();
            }
        },

        destroy: function () {
            this.remove();
            return Backbone.View.prototype.remove.apply(this, arguments);
        }
    });