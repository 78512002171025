'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Collection.extend({

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'accessType/index.php',

    idToString: function (id) {
        if (id == "1") {
            return "Yes, call first";
        }
        if (id == "2") {
            return "Yes, dont call";
        }
        if (id == "3") {
            return "Yes, key access";
        }
        if (id == "4") {
            return "No";
        }
    },

    buildDropDownUi: function (dropdownElement, entryClassname) {
        dropdownElement.html('');
        dropdownElement.append(this.getDrownDownLine(Common.AccessTypes_callFirstId, entryClassname));
        dropdownElement.append(this.getDrownDownLine(Common.AccessTypes_dontCallId, entryClassname));
        dropdownElement.append(this.getDrownDownLine(Common.AccessTypes_allowedAccessId, entryClassname));
        dropdownElement.append(this.getDrownDownLine(Common.AccessTypes_noAccessId, entryClassname));
    },

    getDrownDownLine: function (accessTypeId, entryClassName) {
        var label = this.idToString(accessTypeId);
        return "<div class='dropdownItem " + entryClassName + "' name='" + accessTypeId + "'>" + label + "</div>";
    }
});