'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Email = require('../models/email');
var Phone = require('../models/phone');
var Cropper = require('../models/cropper');
var TypeView = require('../views/typeView');
var AccessView = require('../views/accessView');
var AwayView = require('../views/awayView');
var CropperView = require('../views/cropperView');
var GuestLineView = require('../views/guestLineView');
var MailDeliveryPreferenceView = require('../views/mailDeliveryPreferenceView');
var PackageDeliveryPreferenceView = require('../views/packageDeliveryPreferenceView');
var CommentView = require('../views/commentView');
var PeriodView = require('../views/periodView');
var EmailView = require('../views/emailView');
var PhoneView = require('../views/phoneView');
var PromptView = require('../views/promptView');

module.exports = Backbone.View.extend({

    //Model: PersonProfile (which is always based on 'Person' and will contain a CondoPersonLink for the link the person was loaded from)
    initialize: function (options) {
        console.log('Initializing PersonProfileView');
        if (options) {
            this.edit = options.edit;
        }

        //Subscriptions related to local changes
        this.listenTo(this.model.person, 'change:output', this.renderPersonOutput);
        this.listenTo(this.model.person, 'change:alias', this.renderAlias);
        this.listenTo(this.model.person, 'change:emailsString', this.renderEmails);
        this.listenTo(this.model.person, 'change:phonesString', this.renderPhones);
        this.listenTo(this.model.person, 'change:awaysString', this.renderAways);
        this.listenTo(this.model.person, 'change:photosString', this.renderPhotos);

        //Set menu
        Common.MainMenuEntries.setSelected('');
    },

    events: {
        "click #editName": "clickedEditName",
        "click #saveName": "clickedSaveName",
        "click #cancelName": "clickedCancelName",
        //Title
        "click #titleMenu": "openTitleMenu",
        "click .dropdownTitleItem": "clickedTitleItem",

        "click #editAlias": "clickedEditAlias",
        "click #saveAlias": "clickedSaveAlias",
        "click #cancelAlias": "clickedCancelAlias",

        "click #editEmail": "clickedEditEmail",
        "click #saveEmail": "clickedSaveEmail",
        "click #cancelEmail": "clickedCancelEmail",

        "click #editPhone": "clickedEditPhone",
        "click #savePhone": "clickedSavePhone",
        "click #cancelPhone": "clickedCancelPhone",

        "click #editType": "clickedEditType",
        "click #saveType": "clickedSaveType",
        "click #cancelType": "clickedCancelType",
        "click .personLink": "clickedPersonTypeLink",

        "click #editAccess": "clickedEditAccess",
        "click #saveAccess": "clickedSaveAccess",
        "click #cancelAccess": "clickedCancelAccess",

        "click #editAway": "clickedEditAway",
        "click #saveAway": "clickedSaveAway",
        "click #cancelAway": "clickedCancelAway",

        "click #editPhoto": "clickedEditPhoto",
        "click #savePhoto": "clickedSavePhoto",
        "click #cancelPhoto": "clickedCancelPhoto",
        "click .imageDelete": "clickedDeletePhoto",
        "submit #photoUploadForm": "userSubmitProfilePhoto",
        "change #fileToUpload": "changedFileToUpload",

        "click #editMailDeliveryPreference": "clickedEditMailDeliveryPreference",
        "click #saveMailDeliveryPreference": "clickedSaveMailDeliveryPreference",
        "click #cancelMailDeliveryPreference": "clickedCancelMailDeliveryPreference",

        "click #editPackageDeliveryPreference": "clickedEditPackageDeliveryPreference",
        "click #savePackageDeliveryPreference": "clickedSavePackageDeliveryPreference",
        "click #cancelPackageDeliveryPreference": "clickedCancelPackageDeliveryPreference",

        "click #editComment": "clickedEditComment",
        "click #saveComment": "clickedSaveComment",
        "click #cancelComment": "clickedCancelComment",

        "click #editPeriod": "clickedEditPeriod",
        "click #savePeriod": "clickedSavePeriod",
        "click #cancelPeriod": "clickedCancelPeriod"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#personProfile-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template(this.model.person.attributes));
        $(function () {
            //Render data
            self.renderVisibility();
            self.renderPageTitle();
            self.renderComments();
            self.renderMailDeliveryPreference();
            self.renderPackageDeliveryPreference();
            self.renderPeriod();
            self.renderAways();
            self.renderPhotos();
            self.renderEmails();
            self.renderPhones();
            self.renderAddGuest();

            //Safeguard to show ALL fields for editing in case name type change 
            if (self.model.person.get('middlename')) {
                self.$('#middlename').removeClass('hidden');
            }
            if (self.model.person.get('company')) {
                self.$('#company').removeClass('hidden');
            }

            //Load existing data
            self.model.Guests.forEach(function (condoPersonLink) {
                self.addGuest(condoPersonLink);
            });
            self.model.Types.forEach(function (type) {
                self.addType(type);
            });
            self.model.Accesss.forEach(function (access) {
                self.addAccess(access);
            });
            self.model.Aways.forEach(function (away) {
                self.addAway(away);
            });

            //Subscriptions related to slow-loading data from server
            self.listenTo(self.model.Guests, 'add', self.addedGuestOnPerson);
            self.listenTo(self.model, 'change:isAlsoOwnerOrTenant', self.renderVisibility);
            self.listenTo(self.model, 'change:isNeitherOwnerOrTenant', self.renderVisibility);
            self.listenTo(self.model, 'change:title', self.renderPageTitle);
            self.listenTo(self.model, 'change:comments', self.renderComments);
            self.listenTo(self.model, 'change:mailDeliveryPreference', self.renderMailDeliveryPreference);
            self.listenTo(self.model, 'change:packageDeliveryPreference', self.renderPackageDeliveryPreference);
            self.listenTo(self.model, 'change:period', self.renderPeriod);
            self.listenTo(self.model.Types, 'add', self.addType);
            self.listenTo(self.model.Accesss, 'add', self.addAccess);
            self.listenTo(self.model.Comments, 'add', self.addComment);
            self.listenTo(self.model.Periods, 'add', self.addPeriod);
            self.listenTo(self.model.Aways, 'add', self.addAway);
            self.listenTo(self.model.Emails, 'add', self.addEmail);
            self.listenTo(self.model.Phones, 'add', self.addPhone);
            self.listenTo(self.model.MailDeliveryPreferences, 'add', self.addMailDeliveryPreference);
            self.listenTo(self.model.PackageDeliveryPreferences, 'add', self.addPackageDeliveryPreference);

            if (self.edit) {
                if (self.edit === 'email') {
                    self.clickedEditEmail();
                    self.$('#emailHeader').addClass('red');
                }
            }
        });
        return this;
    },

    renderEmails: function () {
        this.$('#emails').html(this.model.person.getFormattedEmails());
    },

    renderPhones: function () {
        this.$('#phones').html(this.model.person.getFormattedPhones());
    },

    renderAlias: function (person) {
        this.$("#alias").html(person.get('alias'));
    },

    renderPersonOutput: function (person) {
        this.$("#output").html(person.get('output'));
    },

    renderPhotos: function (person) {
        this.$('#photos').html(this.model.person.getFormattedPhotos());
    },

    renderAways: function (person) {
        this.$('#aways').html(this.model.person.getFormattedAways());
    },

    renderPageTitle: function () {
        $('#pageTitle').html(this.model.get('title'));
    },

    renderComments: function () {
        this.$('#comments').html(this.model.get('comments'));
    },

    renderMailDeliveryPreference: function () {
        this.$('#mailDeliveryPreference').html(this.model.get('mailDeliveryPreference'));
    },

    renderPackageDeliveryPreference: function () {
        this.$('#packageDeliveryPreference').html(this.model.get('packageDeliveryPreference'));
    },

    renderPeriod: function () {
        this.$('#period').html(this.model.get('period'));
    },

    renderVisibility: function () {
        //Fields only visible to owners/tenants
        if (this.model.get('isAlsoOwnerOrTenant')) {
            this.$('#aliasRow').removeClass('hidden');
            this.$('#emailRow').removeClass('hidden');
            this.$('#phoneRow').removeClass('hidden');
            this.$('#awayRow').removeClass('hidden');
            this.$('#mailDeliveryPreferenceRow').removeClass('hidden');
            this.$('#packageDeliveryPreferenceRow').removeClass('hidden');
            this.$('#guestRow').removeClass('hidden');

            this.$('#periodRow').addClass('hidden');
            this.$('#middlename').removeClass('hidden');
            this.$('#company').addClass('hidden');
        }
        else {
            this.$('#periodRow').removeClass('hidden');
            this.$('#middlename').addClass('hidden');
            this.$('#company').removeClass('hidden');

            this.$('#aliasRow').addClass('hidden');
            this.$('#emailRow').addClass('hidden');
            this.$('#phoneRow').addClass('hidden');
            this.$('#awayRow').addClass('hidden');
            this.$('#mailDeliveryPreferenceRow').addClass('hidden');
            this.$('#packageDeliveryPreferenceRow').addClass('hidden');
            this.$('#guestRow').addClass('hidden');
        }

        //Field visible if at least one link is for non-owner-tenant
        if (this.model.get('isNeitherOwnerOrTenant ')) {
            this.$('#editAccess').addClass('hidden');
        }
        else {
            this.$('#editAccess').removeClass('hidden');
        }
    },

    renderAddGuest: function () {
        if (this.model.get('isAlsoOwnerOrTenant')) {
            var view = new GuestLineView({ model: null, hostPerson: this.model.person, guestCollection: this.model.Guests, isFirst: true });
            this.$("#addGuest").html(view.render().el);
        }
    },


    /////////////////////////////////////
    // Edit: name
    /////////////////////////////////////
    clickedEditName: function (e, a) {
        e.preventDefault();
        this.$("#viewNameRow").addClass('hidden');
        this.$("#editNameRow").removeClass('hidden');

        //Fill fields
        this.$("#titleLabel").html(this.model.person.get('title'));
        this.$("#lastname").val(this.model.person.get('lastname'));
        this.$("#firstname").val(this.model.person.get('firstname'));
        this.$("#middlename").val(this.model.person.get('middlename'));
        this.$("#company").val(this.model.person.get('company'));
    },

    clickedCancelName: function (e) {
        e.preventDefault();
        this.$("#viewNameRow").removeClass('hidden');
        this.$("#editNameRow").addClass('hidden');
    },

    clickedSaveName: function (e) {
        e.preventDefault();
        this.$("#viewNameRow").removeClass('hidden');
        this.$("#editNameRow").addClass('hidden');

        //Apply to model
        var title = this.$("#titleLabel").html();
        if (title == "Title") {
            title = "";
        }
        this.model.person.set('title', title);
        this.model.person.set('lastname', this.$("#lastname").val());
        this.model.person.set('firstname', this.$("#firstname").val());
        this.model.person.set('middlename', this.$("#middlename").val());
        this.model.person.set('company', this.$("#company").val());
        this.model.person.save();
    },

    openTitleMenu: function (e) {
        if (this.$('#titleMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#titleMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },
    clickedTitleItem: function (e) {
        e.preventDefault();
        this.$("#titleLabel").html(e.target.innerText);
    },


    /////////////////////////////////////
    // Edit: alias
    /////////////////////////////////////
    clickedEditAlias: function (e, a) {
        e.preventDefault();
        this.$("#viewAliasRow").addClass('hidden');
        this.$("#editAliasRow").removeClass('hidden');

        //Fill fields
        this.$("#aliasInput").val(this.model.person.get('alias'));
    },

    clickedCancelAlias: function (e) {
        e.preventDefault();
        this.$("#viewAliasRow").removeClass('hidden');
        this.$("#editAliasRow").addClass('hidden');
    },

    clickedSaveAlias: function (e) {
        e.preventDefault();
        this.$("#viewAliasRow").removeClass('hidden');
        this.$("#editAliasRow").addClass('hidden');

        //Apply to model
        this.model.person.set('alias', this.$("#aliasInput").val());
        this.model.person.save();
    },


    /////////////////////////////////////
    // Edit: email
    /////////////////////////////////////
    clickedEditEmail: function (e, a) {
        if (e) {
            e.preventDefault();            
        }
        this.model.editEmail();
        this.$("#viewEmailRow").addClass('hidden');
        this.$("#editEmailRow").removeClass('hidden');
    },

    clickedCancelEmail: function (e) {
        e.preventDefault();
        this.$("#viewEmailRow").removeClass('hidden');
        this.$("#editEmailRow").addClass('hidden');
    },

    clickedSaveEmail: function (e) {
        e.preventDefault();
        this.model.saveEmail();
        this.$("#viewEmailRow").removeClass('hidden');
        this.$("#editEmailRow").addClass('hidden');
        this.$('#emailHeader').removeClass('red');
    },

    changedEmails: function (person) {
        this.$('#emails').html(this.model.person.getFormattedEmails());
    },

    addEmail: function (email) {
        var view = new EmailView({ model: email });
        this.$("#emailList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: phone
    /////////////////////////////////////
    clickedEditPhone: function (e, a) {
        e.preventDefault();
        this.model.editPhone();
        this.$("#viewPhoneRow").addClass('hidden');
        this.$("#editPhoneRow").removeClass('hidden');
    },

    clickedCancelPhone: function (e) {
        e.preventDefault();
        this.$("#viewPhoneRow").removeClass('hidden');
        this.$("#editPhoneRow").addClass('hidden');
    },

    clickedSavePhone: function (e) {
        e.preventDefault();
        this.model.savePhone();
        this.$("#viewPhoneRow").removeClass('hidden');
        this.$("#editPhoneRow").addClass('hidden');
    },

    addPhone: function (phone) {
        var view = new PhoneView({ model: phone });
        this.$("#phoneList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: type 
    /////////////////////////////////////
    clickedEditType: function (e, a) {
        e.preventDefault();
        this.model.editType();
        this.$("#viewTypeRow").addClass('hidden');
        this.$("#editTypeRow").removeClass('hidden');
    },

    clickedCancelType: function (e) {
        e.preventDefault();
        this.model.cancelTypeEdit();
        this.$("#viewTypeRow").removeClass('hidden');
        this.$("#editTypeRow").addClass('hidden');
    },

    clickedSaveType: function (e) {
        e.preventDefault();
        if (this.model.saveType() === false) {
            var view = new PromptView({ message: 'Its not allowed to delete the last owner/tenant of a condo.<br><br>Alternatively delete the entire condo or add another owner/tenant before deleting this one', header: 'Deletion rejected' });
            Common.appView.requestDialog(view);
        }
        this.$("#viewTypeRow").removeClass('hidden');
        this.$("#editTypeRow").addClass('hidden');
    },

    addType: function (type) {
        var isFirst = false;
        if (this.$("#typeList").children().length === 0) {
            isFirst = true;
        }
        var view = new TypeView({ model: type, isFirst: isFirst, existing: true });
        this.$("#typeList").append(view.render().el);
    },

    clickedPersonTypeLink: function (e) {
        e.preventDefault();
        var personId = e.target.getAttribute('name');
        Common.Router.navigate("#person/" + personId, { trigger: true });
    },


    /////////////////////////////////////
    // Edit: access 
    /////////////////////////////////////
    clickedEditAccess: function (e, a) {
        e.preventDefault();
        this.model.editAccess();
        this.$("#viewAccessRow").addClass('hidden');
        this.$("#editAccessRow").removeClass('hidden');
    },

    clickedCancelAccess: function (e) {
        e.preventDefault();
        this.model.cancelAccessEdit();
        this.$("#viewAccessRow").removeClass('hidden');
        this.$("#editAccessRow").addClass('hidden');
    },

    clickedSaveAccess: function (e) {
        e.preventDefault();
        this.model.saveAccess();
        this.$("#viewAccessRow").removeClass('hidden');
        this.$("#editAccessRow").addClass('hidden');
    },

    addAccess: function (access) {
        var view = new AccessView({ model: access });
        this.$("#accessList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: away
    /////////////////////////////////////
    clickedEditAway: function (e, a) {
        e.preventDefault();
        this.model.editAway();
        this.$("#viewAwayRow").addClass('hidden');
        this.$("#editAwayRow").removeClass('hidden');
    },

    clickedCancelAway: function (e) {
        e.preventDefault();
        this.$("#viewAwayRow").removeClass('hidden');
        this.$("#editAwayRow").addClass('hidden');
    },

    clickedSaveAway: function (e) {
        e.preventDefault();
        this.model.saveAway();
        this.$("#viewAwayRow").removeClass('hidden');
        this.$("#editAwayRow").addClass('hidden');
    },

    addAway: function (away) {
        var view = new AwayView({ model: away });
        this.$("#awayList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: photo
    /////////////////////////////////////
    clickedEditPhoto: function (e, a) {
        e.preventDefault();
        this.$("#viewPhotoRow").addClass('hidden');
        this.$("#editPhotoRow").removeClass('hidden');

        //Fill fields
        this.deletedProfilePhoto = null;
        if (this.model.person.get('photos').length == 0) {
            this.$(".imageEditInstructions").html("Add");
            this.$("#delete").addClass('hidden');
            this.$("#editProfileImg").addClass('imgProfilePlaceholder');
        }
        else {
            this.$(".imageEditInstructions").html("Replace");
            this.$("#delete").removeClass('hidden');
            this.$("#editProfileImg").removeClass('imgProfilePlaceholder');
            this.$("#editProfileImg").attr("src", Common.apiFolder + "person/" + this.model.person.get('photos')[0].path);
        }
    },

    clickedCancelPhoto: function (e) {
        e.preventDefault();
        this.$("#viewPhotoRow").removeClass('hidden');
        this.$("#editPhotoRow").addClass('hidden');
    },

    clickedSavePhoto: function (e) {
        e.preventDefault();
        this.$("#viewPhotoRow").removeClass('hidden');
        this.$("#editPhotoRow").addClass('hidden');

        if (this.deletedProfilePhoto) {
            this.deleteProfilePhoto(this.deletedProfilePhoto);
            return;
        }
        return;
    },

    deleteProfilePhoto: function (jsonPath) {
        //Submit image to PHP (server)
        var url = Common.apiFolder + "person/photo.php/" + this.model.person.get('id');
        var token = sessionStorage.getItem("csrfToken");
        if (token == null) {
            token = '';
        }
        $.ajax({
            url: url, // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            headers: {
                "X-Csrf-Token": token
            },
            cache: false,
            contentType: false,
            processData: false,
            data: jsonPath,
            type: 'delete',
            success: function (php_script_response) {
                //PHP has updated the person's model
            }
        });
    },

    userSubmitProfilePhoto: function (e) {
        e.preventDefault();  //prevent form from submitting
        return;
    },

    clickedDeletePhoto: function (e) {
        e.preventDefault();
        this.$("#editProfileImg").addClass('imgProfilePlaceholder');
        this.deletedProfilePhoto = JSON.stringify(this.model.person.get('photos')[0]);
    },


    /////////////////////////////////////
    // Edit: photo - cropper handling
    /////////////////////////////////////
    showCropper: function (sourceImage) {
        this.cropper = new Cropper({ host: this, person: this.model.person, sourceImage: sourceImage });
        this.cropperView = new CropperView({ model: this.cropper });
        Common.appView.requestDialog(this.cropperView);
    },

    //Is called from Cropper object when it disposes
    closedCropper: function () {
        this.$("#viewPhotoRow").removeClass('hidden');
        this.$("#editPhotoRow").addClass('hidden');
        this.closeCropper();
    },

    closeCropper: function () {
        if (this.cropperView) {
            Common.appView.closeDialog();
            this.cropperView.destroy();
            this.cropperView = null;
            this.cropper;
        }
    },

    changedFileToUpload: function (e) {
        var self = this;
        var file_data = $('#fileToUpload').prop('files')[0];

        //Read file & start cropper once read is completed
        var reader = new FileReader();
        reader.onload = function (e) {
            self.showCropper(e.target.result);
        };
        reader.readAsDataURL(file_data);
        e.preventDefault();
        e.stopPropagation();
    },



    /////////////////////////////////////
    // Edit: MailDeliveryPreference
    /////////////////////////////////////
    clickedEditMailDeliveryPreference: function (e, a) {
        e.preventDefault();
        this.model.recalcDeliveryPreferenceCollections();
        this.$("#viewMailDeliveryPreferenceRow").addClass('hidden');
        this.$("#editMailDeliveryPreferenceRow").removeClass('hidden');
    },

    clickedCancelMailDeliveryPreference: function (e) {
        e.preventDefault();
        this.$("#viewMailDeliveryPreferenceRow").removeClass('hidden');
        this.$("#editMailDeliveryPreferenceRow").addClass('hidden');
    },

    clickedSaveMailDeliveryPreference: function (e) {
        e.preventDefault();
        this.model.saveMailDeliveryPreference();
        this.$("#viewMailDeliveryPreferenceRow").removeClass('hidden');
        this.$("#editMailDeliveryPreferenceRow").addClass('hidden');
    },

    addMailDeliveryPreference: function (mailDeliveryPreference) {
        var isFirst = false;
        if (this.$("#mailDeliveryPreferenceList").children.length == 0) {
            isFirst = true;
        }
        var view = new MailDeliveryPreferenceView({ model: mailDeliveryPreference, isFirst: isFirst });
        this.$("#mailDeliveryPreferenceList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: PackageDeliveryPreference
    /////////////////////////////////////
    clickedEditPackageDeliveryPreference: function (e, a) {
        e.preventDefault();
        this.model.recalcDeliveryPreferenceCollections();
        this.$("#viewPackageDeliveryPreferenceRow").addClass('hidden');
        this.$("#editPackageDeliveryPreferenceRow").removeClass('hidden');
    },

    clickedCancelPackageDeliveryPreference: function (e) {
        e.preventDefault();
        this.$("#viewPackageDeliveryPreferenceRow").removeClass('hidden');
        this.$("#editPackageDeliveryPreferenceRow").addClass('hidden');
    },

    clickedSavePackageDeliveryPreference: function (e) {
        e.preventDefault();
        this.model.savePackageDeliveryPreference();
        this.$("#viewPackageDeliveryPreferenceRow").removeClass('hidden');
        this.$("#editPackageDeliveryPreferenceRow").addClass('hidden');
    },

    addPackageDeliveryPreference: function (packageDeliveryPreference) {
        var isFirst = false;
        if (this.$("#packageDeliveryPreferenceList").children.length == 0) {
            isFirst = true;
        }
        var view = new PackageDeliveryPreferenceView({ model: packageDeliveryPreference, isFirst: isFirst });
        this.$("#packageDeliveryPreferenceList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: Comment
    /////////////////////////////////////
    clickedEditComment: function (e, a) {
        e.preventDefault();
        this.model.recalcDeliveryPreferenceCollections();
        this.$("#viewCommentRow").addClass('hidden');
        this.$("#editCommentRow").removeClass('hidden');
    },

    clickedCancelComment: function (e) {
        e.preventDefault();
        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
    },

    clickedSaveComment: function (e) {
        e.preventDefault();
        this.model.saveComment();
        this.$("#viewCommentRow").removeClass('hidden');
        this.$("#editCommentRow").addClass('hidden');
    },

    addComment: function (comment) {
        var isFirst = false;
        if (this.$("#commentList").children.length === 1) {
            isFirst = true;
        }
        var view = new CommentView({ model: comment, isFirst: isFirst });
        this.$("#commentList").append(view.render().el);
    },


    /////////////////////////////////////
    // Edit: period
    /////////////////////////////////////
    clickedEditPeriod: function (e, a) {
        e.preventDefault();
        this.$("#viewPeriodRow").addClass('hidden');
        this.$("#editPeriodRow").removeClass('hidden');
    },

    clickedCancelPeriod: function () {
        e.preventDefault();
        this.$("#viewPeriodRow").removeClass('hidden');
        this.$("#editPeriodRow").addClass('hidden');
    },

    clickedSavePeriod: function (e) {
        e.preventDefault();
        this.$("#viewPeriodRow").removeClass('hidden');
        this.$("#editPeriodRow").addClass('hidden');
        this.model.savePeriod();
    },

    addPeriod: function (period) {
        var isFirst = false;
        if (this.$("#periodList").children.length === 1) {
            isFirst = true;
        }
        var view = new PeriodView({ model: period, isFirst: isFirst });
        this.$("#periodList").append(view.render().el);
    },


    /////////////////////////////////////
    // Guest
    /////////////////////////////////////
    addedGuestOnPerson: function (condoPersonLink) {
        this.addGuest(condoPersonLink);
    },

    addGuest: function (condoPersonLink) {
        var isFirst = false;
        if (this.$("#guestList").children.length === 0) {
            isFirst = true;
        }
        var view = new GuestLineView({ model: condoPersonLink, guestCollection: this.model.Guests, isFirst: isFirst });
        this.$("#guestList").append(view.render().el);
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.closeCropper();
        if (this.addTypeView) {
            this.addTypeView.destroy();
        }
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});