'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Condo = require('../models/condo');
var CondoPersonLink = require('../models/condoPersonLink');
var Person = require('../models/person');
var SmallPersonView = require('../views/smallPersonView');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        label: '',
        errorMessage: '',
        isCreating: false
    },

    urlRoot: '',

    initialize: function (options) {
        this.personViews = [];
        this.persons = [];
        if (options.host) {
            this.host = options.host;
        }
    },

    clearPersons: function () {
        this.personViews.forEach(function (view) {
            view.destroy();
        });
        this.trigger('clearedPersons');
        this.persons = [];
        this.personViews = [];
        this.addPerson();
    },

    addPerson: function () {
        var model = new Person();
        var view = new SmallPersonView({ model: model });
        this.listenTo(model, 'change:isEmpty', this.changedIsEmpty);

        this.persons.push(model);
        this.personViews.push(view);
        this.trigger('addedPerson', view);
    },

    changedIsEmpty: function (e, a) {
        var emptyPersonExist = this.persons.some(person => person.get('isEmpty') === true);
        if (!emptyPersonExist) {
            this.addPerson();
        }
    },

    tryCreate: function () {
        //Validation
        if (this.get('label') == '') {
            this.set('errorMessage', "A unit number or name is required");
            return;
        }
        var validPersonExist = this.persons.some(person => person.get('isEmpty') === false);
        if (!validPersonExist) {
            this.set('errorMessage', "At least one person must be associated with a condo/suite");
            return;
        }
        if (this.get('isCreating')) {
            this.set('errorMessage', "Condo/suite is still being creating...");
            return;
        }

        //Create condo
        this.set('isCreating', true);
        var self = this;
        this.condo = new Condo();
        this.condo.set('label', this.get('label'));
        this.condo.set('buildingId', this.get('buildingId'));
        Common.Condos.add(this.condo);
        this.condo.save(null, {
            success: function () {

                //Create person(s)
                self.persons.forEach(function (person) {
                    if (self.get('isCreating')) {
                        if (person.get('isEmpty') === false) {
                            person.create(self.personCreated, self.failedCreatingPerson, self);
                        }
                    }
                });
            },
            error: function () {
                self.rollback();
            }
        });
    },

    personCreated: function (person, self) {
        //Create link to condo
        if (self.get('isCreating')) {
            var link = new CondoPersonLink({
                condoPersonLinkTypeId: person.get('condoPersonLinkTypeId'),
                condoId: self.condo.get('id'),
                personId: person.get('id'),
                isResident: true,
                star: false,
                accessTypeId: Common.AccessTypes_allowedAccessId
            });
            Common.CondoPersonLinks.add(link);
            link.save(null, {
                error: function (e, a) {
                    self.rollback();
                },
                success: function () {
                    person.set('newCondoLink', link);

                    //Are we done creating this entry?
                    var validNoneCreatedPersonExist = self.persons.some(function (person) {
                        return person.get('isEmpty') === false && person.get('id') === '' && person.has('newCondoLink') === false;
                    });
                    if (!validNoneCreatedPersonExist) {
                        if (self.host && self.host.createdCondo && self.condo && self.get('isCreating') === true) {
                            self.host.createdCondo(self.condo);
                        }
                        self.set('isCreating', false);
                    }
                }
            });
        }
    },

    failedCreatingPerson: function (persons, self) {
        self.rollback();
    },

    rollback: function () {
        //Roll back
        this.persons.forEach(function (person) {
            var link = Common.CondoPersonLinks.findWhere({ personId: person.get('id') });
            if (link) {
                link.destroy();
            }
            person.destroy();
        });
        this.condo.destroy();
        this.condo = null;
        this.set('isCreating', false);
        this.clearPersons();
    },
});  