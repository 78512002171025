﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var CondoCreate = require('../models/condoCreate');
var CondoThumbView = require('../views/condoThumbView');
var CondoCreateView = require('../views/condoCreateView');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SearchCondoView');

        //Set building link (required for creation)
        var currentBuilding = Common.Buildings.findWhere({ isActive: true });
        if (currentBuilding) {
            this.model.set('buildingId', currentBuilding.get('id'));
        }
        this.renderInterval_ms = 10;
        this.renderChunks = 40;
        this.maxRenderCount = 400;
        this.listenTo(Common.Condos, "reset", this.resetCondosEvent);
        this.views = [];

        //Set menu
        Common.MainMenuEntries.setSelected('condos');
    },

    events: {
        "click #add": "clickedAdd",

        //Model: user input --> update mmodel
        "blur #searchInput": "inputBlur",
        "focus #searchInput": "inputFocus"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#searchCondo-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        //Run render code once DOM has loaded...
        var self = this;
        $(function () {
            $('#pageTitle').html('Condos / suites');
            self.activate();
            self.model.load();
            self.renderIndex = 0;
            self.lazyRender();

            var activeBuilding = Common.Buildings.getActive();
            if (activeBuilding && activeBuilding.get('accountTypeId') == Common.Accounts_propertyManagerId) {
                self.$('#add').removeClass('hidden');
            }
            else {
                self.$('#add').addClass('hidden');
            }
        });
        return this;
    },

    lazyRender: function () {
        var self = this;

        //Stop when all models are rendered
        if (this.renderIndex >= Common.Condos.models.length) {
            this.cancelLazyRender();
            return;
        }

        //Re-render condo's once they are synced
        if (Common.Condos.models.length === 0) {
            this.listenTo(Common.Condos, "sync", this.syncCondosEvent);
        }

        var i;
        for (i = 0; i < this.renderChunks; i++) {
            if (this.renderIndex >= Common.Condos.models.length || this.renderIndex > this.maxRenderCount) {
                return;
            }

            //Render next model
            var model = Common.Condos.models[self.renderIndex];
            self.renderIndex++;
            var view = new CondoThumbView({ model: model, isNew: false });
            this.views.push(view);
            self.$("#condoThumbList").append(view.render().el);
        }

        //Schedule next timer
        if (this.renderIndex >= Common.Condos.models.length || this.renderIndex > this.maxRenderCount) {
            this.cancelLazyRender();
            return;
        }
        this.renderTimer = setTimeout(function () { self.lazyRender(); }, this.renderInterval_ms);
    },

    cancelLazyRender: function () {
        if (this.renderTimer) {
            clearTimeout(this.renderTimer);
            this.renderTimer = null;
        }
    },


    activate: function () {
        var self = this;
        self.$('input').focus();
        setTimeout(function () {
            self.$('input').focus();
        }, 200);
    },

    clearAllViews: function () {
        this.$("#condoThumbList").html('');
        this.views.forEach(function (view) {
            view.destroy();
        });
        this.views = [];
        this.renderIndex = 0;
    },


    /////////////////////////////////////
    // User input 
    /////////////////////////////////////
    inputFocus: function () {
        var self = this;
        this.$('input:eq(0)').off();
        this.$('input:eq(0)').on('input', function () {
            self.inputChangedLabel();
        });
    },

    inputBlur: function () {
        this.$('input:eq(0)').off();
        this.inputChangedLabel();
        this.inputChangedLabel();
    },


    /////////////////////////////////////
    // User input --> model
    /////////////////////////////////////
    inputChangedLabel: function () {
        var val = this.$('#searchInput').val();
        this.model.setFilter(val);
    },


    /////////////////////////////////////
    // User actions (buttons)
    /////////////////////////////////////
    tryCreate: function (e) {
        e.preventDefault(); // Don't let this button submit the form
        this.inputChangedLabel();
        this.model.tryCreate();
    },

    cancel: function (e) {
        event.preventDefault(); // Don't let this button submit the form
        Common.appView.closeDialog();
    },

    clickedAdd: function (e) {
        e.preventDefault();
        var model = new CondoCreate({ host: this });
        var view = new CondoCreateView({ model: model });
        Common.appView.requestDialog(view);
    },


    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    resetCondosEvent: function () {
        this.listenTo(Common.Condos, "sync", this.syncCondosEvent);
        this.clearAllViews();
    },

    syncCondosEvent: function () {
        this.lazyRender();
        this.stopListening(Common.Condos, "sync");
    },

    changedErrorMessage: function () {
        this.$('#errorMessage').html(this.model.get('errorMessage'));
    },

    createdCondo: function (condo) {
        var view = new CondoThumbView({ model: condo, isNew: true });
        this.views.push(view);
        this.$("#condoThumbList").prepend(view.render().el);
    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.cancelLazyRender();
        this.$('input:eq(0)').off();
        this.remove();

        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});