'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing CheckinNotificationView');

        this.allowed = options.allowed;
    },

    events: {
        "click": "clicked",
        "click #closeButton": "userClickedClose",
        "click #hostProfile": "clickedHostProfile",
        "click #logAndNotifyButton": "clickedLogAndNotify"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#checkinNotification-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.$('#header').html(self.model.get('guestName') + " (" + self.model.get('condoLabel') + ")");
            if (self.allowed == true) {
                self.$("#allowed").removeClass('hidden');
                self.$("#denied").addClass('hidden');
            }
            else {
                self.$("#allowed").addClass('hidden');
                self.$("#denied").removeClass('hidden');
            }
            if (!self.model.get('canHostBeNotified')) {
                self.$('#hostNoficiationMissing').removeClass('hidden');
                self.$('#message').addClass('hidden');
            }

            //Handling 'enter' & ESC keypress
            $(self.el).keydown(function (e) {
                if (e.keyCode == 13) {
                    self.hitEnterKey(e);
                    return false;
                }
            });
            $(self.el).keyup(function (e) {
                if (e.keyCode == 27) {
                    self.hitEscKey(e);
                    return false;
                }
            });
            self.$('#logAndNotifyButton').focus();
        });
        return this;
    },


    /////////////////////////////////////
    // User input
    /////////////////////////////////////
    hitEnterKey: function (e) {
        this.clickedLogAndNotify(e);
    },

    hitEscKey: function (e) {
        this.userClickedClose(e);
    },

    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    },

    clickedHostProfile: function (e) {
        e.preventDefault();
        Common.Router.navigate("#person/" + this.model.hostCondoPersonLink.id + "/email", { trigger: true });
        Common.appView.closeDialog();
    },

    clickedLogAndNotify: function (e) {
        e.preventDefault();
        this.model.notifyHost();
        Common.appView.closeDialog();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    userClickedClose: function (e) {
        e.preventDefault();
        Common.appView.closeDialog();
    },

    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});