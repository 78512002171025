﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Checkin = require('../models/checkin');

module.exports = Backbone.View.extend({

    //Model: Type 
    //(contains 'Person' object directly as this.model.person)
    //(will slow-load the collection this.model.person.get('Condos') and this.model.person.get('CondoPersonLinks'))
    initialize: function (options) {
        console.log('Initializing TypeView=' + this.cid);

        this.isFirst = options.isFirst;

        if (options.existing) {
            this.listenTo(this.model, 'remove', this.removeType);
            this.listenTo(this.model.collection, 'reset', this.destroy);
        }
        this.listenTo(this.model, 'change:isPrimaryConfigurable', this.renderPrimaryVisibility);
        this.listenTo(this.model, 'change:isPrimary', this.renderIsPrimary);
        this.listenTo(this.model, 'change:condoLabel', this.changedCondoLabel);
        this.listenTo(this.model, 'change:condoId', this.changedCondoId);
        this.listenTo(this.model, 'change:condoPersonLinkTypeId', this.changedCondoPersonLinkTypeId);
        this.listenTo(this.model, 'change:edit', this.changedEdit);
        this.listenTo(this.model, 'change:star', this.renderStar);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "click .personTypeItem": "userChangedCondoPersonLinkType",
        "click .condoItem": "userChangedCondo",

        "click #personTypeMenu": "openPersonTypeMenu",
        "click #condoMenu": "openCondoMenu",

        "click .hostMenu": "openHostMenu",
        "click .hostItem": "userChangedHost",

        "click #isResidentToggle": "clickIsResident",
        "click #isPrimaryToggle": "clickIsPrimary",
        "click #star": "clickStar",
        "click #hostNameView": "clickHostname",
        "click #checkin": "clickCheckin",
        "click #condoLabelView": "clickedCondoLabel"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    render: function () {
        //Two rendering modes:
        if (this.model.person.get('isAlsoOwnerOrTenant') == true) {
            //For new persons 
            //For existing persons who has CondoPersonLinks that are neither staff nor guest
            //- will allow setting ANY type, but will not give option to choose host
            this.template = _.template($('#type-template').html());
        }
        else {
            //For persons who ONLY has role of Guest or Staff. This view will enable selection of host, but limite Type-selection to Staff/Guest
            this.template = _.template($('#typeGuestStaff-template').html());
        }
        $(this.el).html(this.template());

        var self = this;
        $(function () {
            self.buildHostComboBox();
            //Set IsResident
            self.$("#isResident").prop('checked', self.model.get('isResident'));

            self.renderStar();
            self.renderIsPrimary();
            self.renderPrimaryVisibility();

            //Edit output for 'view' mode
            self.renderCondoLabel();
            self.renderCondoPersonLinkType();
            self.$("#labelTypeView").html(Common.CondoPersonLinkTypes.getNameFromId(self.model.get('condoPersonLinkTypeId')));

            //Separator line: hidden always if first
            if (self.isFirst) {
                self.$('#profileLineSeparator').hide();
            }

            //Set visibility
            self.setVisibility();
        });
        return this;
    },

    renderIsPrimary: function () {
        this.$("#isPrimary").prop('checked', this.model.get('isPrimary'));
    },

    renderPrimaryVisibility: function () {
        //Visibility for 'view' mode
        if (this.model.get('isPrimaryConfigurable')) {
            this.$(".isPrimaryConfigurable").removeClass('hiddenKeepSize');
            if (this.model.get('isPrimary')) {
                this.$("#isPrimaryView").show();
            }
            else {
                this.$("#isPrimaryView").hide();
            }
        }
        else {
            this.$(".isPrimaryConfigurable").addClass('hiddenKeepSize');
            this.$("#isPrimaryView").hide();
        }
    },

    renderCondoPersonLinkType: function () {
        this.controlVisibilityIsResidentToggle();

        //Set in UI
        this.$("#personTypeLabel").html(Common.CondoPersonLinkTypes.getNameFromId(this.model.get('condoPersonLinkTypeId')));

        //Close menu
        this.$("#personTypeMenuDropdown").fadeOut(100);
    },

    buildHostComboBox: function () {
        //Clear existing entries
        this.$("#hostMenuDropdown").html('');
        this.$("#hostLabel").html('Host');

        //Build dropdown entries
        var self = this;
        var condoId = this.model.get('condoId');
        Common.CondoPersonLinks.models.forEach(function (condoPersonLink) {
            if (condoPersonLink.get('condoId') == condoId) {
                if (condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId || condoPersonLink.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_tenantId) {
                    var person = Common.Persons.findWhere({ id: condoPersonLink.get('personId') });
                    if (person) {
                        var entry = "<div class='dropdownItem hostItem' name='" + condoPersonLink.get('id') + "'>" + person.get('output') + "</div>";
                        self.$("#hostMenuDropdown").append(entry);

                        //Set first owner/resident as default host
                        if (!self.model.get('hostCondoPersonLinkId')) {
                            self.model.set('hostCondoPersonLinkId', condoPersonLink.get('id'));
                        }

                        //Fill UI with name of host
                        if (condoPersonLink.get('id') == self.model.get('hostCondoPersonLinkId')) {
                            var hostLabel = person.get('output');
                            self.$("#hostLabel").html(hostLabel);
                            self.$("#hostNameView").html(hostLabel);
                        }
                    }
                }
            }
        });
    },

    renderCondoLabel: function () {
        this.$("#condoLabel").html(this.model.get('condoLabel'));
        this.$("#condoLabelView").html(this.model.get('condoLabel'));
    },

    buildCondoComboBox: function () {
        //Set current type in UI
        this.$("#condoLabel").html(this.model.get('condoLabel'));

        //Build dropdown entries
        var self = this;
        Common.Condos.models.forEach(function (condo) {
            var entry = "<div class='dropdownItem condoItem' name='" + condo.get('id') + "'>" + condo.get('label') + "</div>";
            self.$("#condoMenuDropdown").append(entry);
        });
    },

    renderStar: function () {
        if (this.model.get('star') == true) {
            this.$('#star').addClass('starred');
            this.$('#star').removeClass('unStarred');
        }
        else {
            this.$('#star').removeClass('starred');
            this.$('#star').addClass('unStarred');
        }
    },

    changedCondoLabel: function (model) {
        this.renderCondoLabel();
    },

    changedCondoId: function (model) {
        this.model.set('hostCondoPersonLinkId', '');
        this.render();
    },
    changedEdit: function (model) {
        this.setVisibility();
    },

    setVisibility: function () {
        //Visible for 'isNewType' mode
        if (this.model.get('isNewType')) {
            this.$("#typeView").addClass('hidden');
            if (this.model.get('edit')) {
                this.controlVisibilityIsResidentToggle();
                this.$("#typeEdit").removeClass('hidden');
                if (this.model.get('condoId')) {
                    this.$("#details").removeClass('hidden');
                }
                else {
                    this.$("#details").addClass('hidden');
                }
            }
            else {
                this.$("#typeEdit").addClass('hidden');
            }
        }
        else {
            //This is an existing CondoPersonLink being rendered (via the model 'Type')
            if (this.model.get('edit')) {
                //Edit...
                this.controlVisibilityIsResidentToggle();
                this.$("#typeEdit").removeClass('hidden');
                this.$("#typeView").addClass('hidden');
                this.$("#details").removeClass('hidden');
            }
            else {
                //View...
                this.$("#typeView").removeClass('hidden');
                this.$("#typeEdit").addClass('hidden');
            }
        }

        //Visibility for 'view' mode
        if (this.model.condoPersonLink && this.model.condoPersonLink.get('isResident') == true) {
            this.$("#isResidentView").show();
        }
        else {
            this.$("#isResidentView").hide();
        }
    },

    changedCondoPersonLinkTypeId: function (model) {
        //Set new defaults
        if (this.model.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId || this.model.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_tenantId) {
            this.model.set('isResident', true);
        }
        else {
            this.model.set('isResident', false);
        }
        this.model.set('isPrimary', false);
        this.renderCondoPersonLinkType();
    },

    controlVisibilityIsResidentToggle: function () {
        if (this.model.get('condoPersonLinkTypeId') == Common.CondoPersonLinkTypes_ownerId && this.model.get('condoId')) {
            this.$("#isResidentToggle").removeClass('hiddenKeepSize');
        }
        else {
            this.$("#isResidentToggle").addClass('hiddenKeepSize');
        }
    },


    /////////////////////////////////////
    // Menu
    /////////////////////////////////////
    openPersonTypeMenu: function (e) {
        if (this.$('#personTypeMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#personTypeMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    openCondoMenu: function (e) {
        this.buildCondoComboBox();
        if (this.$('#condoMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#condoMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },


    openHostMenu: function (e) {
        if (this.$('#hostMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            this.$("#hostMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();
        }
    },

    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedCondoPersonLinkType: function (e) {
        var condoPersonLinkTypeId = e.currentTarget.getAttribute('name');
        this.model.set('condoPersonLinkTypeId', condoPersonLinkTypeId);
        e.preventDefault();
        e.stopPropagation();
    },

    userChangedCondo: function (e) {
        var condoId = e.currentTarget.getAttribute('name');
        this.model.setCondoId(condoId);
        e.preventDefault();
        e.stopPropagation();
    },

    clickIsResident: function (e) {
        e.preventDefault();
        //Toggle the checkbox manually (doing it on events made all events go to the first person in the list)
        var newValue = !this.$("#isResident").prop('checked');
        this.$("#isResident").prop('checked', newValue);
        this.model.set('isResident', this.$("#isResident").prop('checked'));
    },

    clickIsPrimary: function (e) {
        e.preventDefault();
        //Toggle the checkbox manually (doing it on events made all events go to the first person in the list)
        var newValue = !this.$("#isPrimary").prop('checked');
        this.$("#isPrimary").prop('checked', newValue);
        this.model.set('isPrimary', this.$("#isPrimary").prop('checked'));
    },

    clickStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.get('star') == false) {
            this.model.set('star', true);
        }
        else {
            this.model.set('star', false);
        }
        this.model.save();
    },

    clickHostname: function (e) {
        e.preventDefault();
        Common.Router.navigate("#person/" + this.model.get('hostCondoPersonLinkId'), { trigger: true });
    },

    clickCheckin: function (e) {
        e.preventDefault();
        var checkin = new Checkin({
            condoPersonLink: this.model.condoPersonLink
        });
        checkin.tryCheckin();
    },

    clickedCondoLabel: function (e) {
        e.preventDefault();
        Common.Router.navigate("#condo/" + this.model.get('condoId'), { trigger: true });
    },

    userChangedHost: function (e) {
        e.preventDefault();
        var hostCondoPersonLinkId = e.currentTarget.getAttribute('name');
        var hostLabel = e.currentTarget.innerText;
        this.model.set('hostCondoPersonLinkId', hostCondoPersonLinkId);
        this.$("#hostLabel").html(hostLabel);
    },


    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.model.markForDeletion();
        this.$("#typeEdit").addClass('hidden');
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    removeType: function (type) {
        this.destroy();
    },
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});