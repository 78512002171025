﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var SelectBuildingEntryView = require('../views/selectBuildingEntryView');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SelectBuildingView');

        this.listenTo(Common.Buildings, 'sync', this.buildingSync);

        Common.Buildings.fetch();
    },

    template: _.template($('#selectBuilding-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        $(this.el).html(this.template());
        $(function () {
            $('#pageTitle').html('Select Building');
        });
        return this;
    },

    buildingSync: function (e, args) {
        //Clear existing list
        $('.selectBuilding-list').empty();

        //Add each entry
        Common.Buildings.models.forEach(function (building) {
            var view = new SelectBuildingEntryView({ model: building });
            $('.selectBuilding-list').append(view.render().el);
        });
    },

    destroy: function () {
        this.remove();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});