﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Account = require('../models/account');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing LoginView');
    },

    events: {
        "click #loginButton": "login",
        "click #createAccount": "createAccount",
    },

    //Cache the template function for a single item
    template: _.template($('#login-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        $(this.el).html(this.template());
        $(function () {
            $('#pageTitle').html('Login');
        });
        return this;
    },

    login: function (e) {
        e.preventDefault(); // Don't let this button submit the form            
        $('#serverError').text("").hide();// Hide any servers on a new submit

        var url = Common.apiFolder + 'account/login.php';
        console.log('Loggin in... ');
        var formValues = {
            email: $('#inputEmail').val(),
            password: $('#inputPassword').val()
        };

        $.ajax({
            url: url,
            type: 'POST',
            dataType: "json",
            data: formValues,
            error: function (r, e) {
                Common.Accounts.logout();
                $('#serverError').text("Invalid login").show();
            },
            success: function (e) {
                try {
                    var response = e;
                    if (response.token) {
                        Common.Router.navigate("#", { trigger: true });

                        //To allow fetching accounts from the server, we need to set the session token. This is done next as the token is returned by the server:
                        var existing = Common.Accounts.findWhere({ "id": response.id });
                        if (existing) {
                            existing.set(response);
                        }
                        else {
                            var account = new Account();
                            Common.Accounts.add(account);
                            account.set(response);
                        }

                        //Hide errors
                        $('#serverErrorr').text("").hide();
                    }
                    else {
                        $('#serverError').text("Invalid login").show();
                    }
                }
                catch (e) {
                    $('#serverError').text("Invalid login").show();
                }
            },
        });
    },

    createAccount: function (event) {
        Common.Router.navigate("#createAccount", { trigger: true });
    },

    destroy: function () {
        this.remove();

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});