'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing CheckinView');
    },

    events: {
        "click": "clicked",
        "click #allowedButton": "userClickedAllowed",
        "click #rejectedButton": "userClickedRejected",
        "click #noAnswerButton": "userClickedNoAnswer",
        "click #cancelButton": "userClickedCancel"
    },

    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#checkin-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.$('#header').html(self.model.get('hostName') + " (" + self.model.get('condoLabel') + ")");

            //Handling 'enter' & ESC keypress
            $(self.el).keydown(function (e) {
                if (e.keyCode == 13) {
                    self.hitEnterKey(e);
                    return false;
                }
            });
            $(self.el).keyup(function (e) {
                if (e.keyCode == 27) {
                    self.hitEscKey(e);
                    return false;
                }
            });
            self.$('#allowedButton').focus();

            //TODO: show/hide based on accessType

        });
        return this;
    },


    /////////////////////////////////////
    // User input
    /////////////////////////////////////
    hitEnterKey: function (e) {
        this.userClickedAllowed(e);
    },

    hitEscKey: function (e) {
        this.userClickedCancel(e);
    },

    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    },
    userClickedAllowed: function (e) {
        e.preventDefault();
        this.model.hostApproved();
    },

    userClickedRejected: function (e) {
        e.preventDefault();
        this.model.rejectedByHost();
    },

    userClickedNoAnswer: function (e) {
        e.preventDefault();
        this.model.rejectedNoAnswer();
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    userClickedCancel: function (e) {
        e.preventDefault();
        Common.appView.closeDialog();
    },

    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});