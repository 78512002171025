﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var GuestLineView = require('../views/guestLineView');

module.exports = Backbone.View.extend({

    //model: condoPersonLink (for host)
    initialize: function (options) {
        console.log('Initializing CreateGuestView=' + this.cid);
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#createGuest-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());
        var self = this;
        $(function () {
            var hostCondoPersonLink = self.model.getHostCondoPersonLink();
            $('#pageTitle').html("Add guest: " + hostCondoPersonLink.get('label') + " " + hostCondoPersonLink.get('person').get('output'));
            var view = new GuestLineView({ hostPerson: self.model, createGuestView: true, isFirst: true });
            self.$('#guestLine').html(view.render().el);
            return this;
        });
        return this;
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});