﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var Common = require('../common');
var Croppie = require("croppie");

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
    },

    initialize: function (options) {
        this.listenTo(this, 'change:sourceUrl', this.changedSourceUrl);
        this.person = options.person;
        this.host = options.host;
        this.sourceImage = options.sourceImage;
        this.orientation = 1;
    },

    setElem: function (elem) {
        this.elem = elem;
        this.croppie = new Croppie(elem, {
            viewport: { width: 600, height: 600 }
        });
        this.croppie.bind({ url: this.sourceImage });
    },

    rotate: function () {
        switch (this.orientation) {
            case 1:
                this.orientation = 6;
                break;
            case 6:
                this.orientation = 3;
                break;
            case 3:
                this.orientation = 8;
                break;
            case 8:
                this.orientation = 1;
                break;
        }
        if (this.croppie) {
            this.croppie.destroy();
            this.croppie = new Croppie(this.elem, {
                enableOrientation: true,
                viewport: { width: 600, height: 600 }
            });
            this.croppie.bind({ url: this.sourceImage, orientation: this.orientation });
        }
    },

    setResult: function (options) {
        var self = this;
        if (this.croppie) {
            this.croppie.result(options).then(function (resp) {
                self.completedCrop(resp);
            });
        }
    },

    completedCrop: function (resp) {
        var self = this;
        //Save cropped image
        var url = Common.apiFolder + "person/photo.php/" + self.person.get('id');
        var token = sessionStorage.getItem("csrfToken");
        if (token == null) {
            token = '';
        }
        $.ajax({
            url: url,
            type: "POST",
            headers: {
                "X-Csrf-Token": token
            },
            data: { "image": resp },
            success: function (data) {
                if (data.error) {
                    console.log(data.error);
                }
                else if (data.path) {
                    self.person.addPhoto(data.path);
                    self.destroy();
                }
            },
            complete: function (e) {
            },
            error: function (e) {
            }
        });
    },

    destroy: function () {
        if (this.croppie) {
            this.croppie.destroy();
            this.croppie = null;
            this.host.closedCropper();
        }
    }
});