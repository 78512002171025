﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Person = require('../models/person');

module.exports = Backbone.Collection.extend({
    model: Person,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'person/index.php'
});