'use strict';
var Backbone = require('backbone');
var Common = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
    },

    initialize: function (options) {

        //Store originals
        this.condoPersonLink = options.condoPersonLink;

        //Lookup person
        this.person = Common.Persons.findWhere({ id: this.condoPersonLink.get('personId') });

        //Lookup host
        if (this.condoPersonLink.get('hostCondoPersonLinkId')) {
            this.hostCondoPersonLink = Common.CondoPersonLinks.findWhere({ id: this.condoPersonLink.get('hostCondoPersonLinkId') });
            if (this.hostCondoPersonLink) {
                this.hostPerson = Common.Persons.findWhere({ id: this.hostCondoPersonLink.get('personId') });
            }
        }

        //Lookup condo
        if (this.condoPersonLink.get('condoId')) {
            this.condo = Common.Condos.findWhere({ id: this.condoPersonLink.get('condoId') });
        }

        //Setup properties
        this.setName();
        this.setHostName();
        this.setCondoLabel();
    },

    setName: function () {
        this.set('output', this.person.get('output'));
    },

    setHostName: function () {
        this.set('hostOutput', this.hostPerson.get('output'));
    },

    setCondoLabel: function () {
        this.set('condoLabel', this.condo.get('label'));
    }
});