﻿'use strict';
var Backbone = require('backbone');
var Away = require('../models/away');

module.exports = Backbone.Collection.extend({
    model: Away,

    initialize: function () {
        this.listenTo(this, 'change:leave', this.changedLeave);
    },

    changedLeave: function (away) {
        var emptyEntries;
        if (away.get('leave')) {
            //Make sure we always have one free away entry
            emptyEntries = this.filter(function (e) { return !e.get('leave'); });
            if (emptyEntries.length == 0) {
                var awayModel = new this.model();
                this.add(awayModel);
            }
        }
        else {
            //If clearing out an away entry, remove it if there are other empty entries
            emptyEntries = this.filter(function (e) { return !e.get('leave'); });
            if (emptyEntries.length > 1) {
                this.remove(away);
            }
        }
    }
});