'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
require("croppie/croppie.css");

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing CropperView');
    },

    events: {
        "click": "clicked",

        "click #rotate": "clickedRotate",
        "click #cancelCropper": "clickedCancel",
        "click #saveCropper": "clickedSave"
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#cropper-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        var self = this;
        $(this.el).html(this.template());
        $(function () {
            var elem = self.$('#cropper').get(0);
            self.model.setElem(elem);
        });
        return this;
    },

    /////////////////////////////////////
    // User input
    /////////////////////////////////////
    clicked: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    },

    clickedRotate: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.model.rotate();
    },


    /////////////////////////////////////
    // Edit: photo
    /////////////////////////////////////
    clickedCancel: function (e) {
        e.preventDefault();
        this.destroy();
    },

    clickedSave: function (e) {
        e.preventDefault();
        //Create cropped image
        this.model.setResult({
            type: 'canvas',
            size: 'viewport',
            format: 'jpeg',
            quality: 0.8
        });
    },

    changedFileToUpload: function (e) {
        var self = this;
        var file_data = $('#fileToUpload').prop('files')[0];

        //Read file & start cropper once read is completed
        var reader = new FileReader();
        reader.onload = function (e) {
            self.model.set('sourceUrl', e.target.result);
        };
        reader.readAsDataURL(file_data);
    },


    /////////////////////////////////////
    // Dispose
    /////////////////////////////////////
    destroy: function () {
        this.model.destroy();
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});