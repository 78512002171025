﻿'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        email: '',
        comment: ''
    },

    reset: function () {
        this.set('email', '');
        this.set('comment   ', '');
    },

    isValid: function () {
        return this.get('email') !== '';
    }
});