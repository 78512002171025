'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Util = require('../util/util');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing LogTodayView');

        this.listenTo(Common.SearchLogMatches, 'change:isMatch', this.changeIsmatch);
        this.listenTo(Common.SearchLogMatches, 'change:id', this.changedId);
        this.listenTo(Common.SearchLogMatches, 'change:datetime', this.changedDatetime);
        this.listenTo(Common.SearchLogMatches, 'change:comment', this.changedComment);
        this.listenTo(Common.SearchLogMatches, 'remove', this.removedSearchLogMatch);

        this.listenTo(Common.Logs, 'change:star', this.changedStar);
    },

    tagName: 'div',
    className: 'box todayCell click',

    events: {
        "click #star": "clickedStar",
        "click ": "clickedEntry"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#logToday-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.renderLogType();
            self.renderComment();
        });
        return this;
    },

    renderLogType: function () {
        this.$('#header').html(this.getLogTypeStringFromId(this.model.get('logTypeId')));
    },

    renderComment: function () {
        var comment = this.model.get('comment');
        var commentHtml = Util.jsonToBr(comment);
        this.$('#todayNote').html(commentHtml);
    },

    getLogTypeStringFromId: function (logTypeId) {
        switch (logTypeId) {
            case "1":
            case "2":
            case "3":
                return 'Profile';
            case "5":
            case "6":
                return 'Mail';
            case "7":
            case "8":
                return 'Package';
            case "9":
            case "10":
            case "11":
                return 'Event';
            case "13":
            case "14":
            case "15":
                return 'Away';
            case "20":
            case "21":
            case "22":
            case "23":
            case "24":
                return 'Checkin';
            case "100":
                return 'Info';
            case "101":
                return 'Incident';
            case "102":
                return 'Leak';
            case "103":
                return 'Conctractor';
            case "104":
                return 'Window washing';
            case "105":
                return 'Maintenance';
            default:
                return "";
        }
    },

    hideAnimation() {
        var self = this;
        $(this.el).animate(
            {
                'opacity': 0,
                'height': 0,
                'padding-top': 0,
                'padding-bottom': 0,
                'min-height': 0,
                'margin-bottom': -8,
                'border-top-width': 0,
                'border-bottom-width': 0
            }, 500, function () {
                self.destroy();
            });
    },


    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    changedStar: function (m) {
        if (m === this.model) {
            if (m.get('star') == false) {
                this.hideAnimation();
            }
        }
    },

    changedComment: function (m) {
        if (m === this.model) {
            this.$('#todayNote').html(this.model.get('comment'));
        }
    },


    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.hideAnimation();
        this.model.set('star', false);
        this.model.save();
    },

    clickedEntry: function (e) {
        e.preventDefault();
        e.stopPropagation();
        Common.Router.navigate("#log/" + this.model.get('id'), { trigger: true });
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});