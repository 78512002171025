'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

var LogLineView = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing LogLineView');

        this.listenTo(this.model, 'change:isHighlighted', this.changeIsHighlighted);
        this.listenTo(this.model, 'change:isSelected', this.changeIsSelected);
        this.listenTo(this.model, 'change:isMatch', this.changeIsmatch);
        this.listenTo(this.model, 'change:id', this.changedId);
        this.listenTo(this.model, 'change:datetime', this.changedDatetime);
        this.listenTo(this.model, 'change:comment', this.changedComment);
        this.listenTo(this.model, 'remove', this.destroy);

        this.listenTo(this.model.log, 'change:id', this.changedId);
        this.listenTo(this.model.log, 'change:star', this.changedStar);
        this.listenTo(this.model.log, 'change:datetime', this.changedDatetime);
    },

    tagName: 'div',
    className: 'rTableRow click',

    events: {
        "click ": "clickedEntry",
        "mouseover ": "requestHighlight",
        "mouseleave": "releaseHighlight",
        "click #star": "clickedStar",
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#logLine-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            self.renderId();
            self.renderLogType();
            self.renderStar();
            self.renderDateTime();
            $(self.el).attr('name', self.model.log.get('serverTimestamp'));
        });
        return this;
    },

    renderStar: function () {
        if (this.model.log.get('star') == true) {
            this.$('#star').addClass('starred');
            this.$('#star').removeClass('unStarred');
        }
        else {
            this.$('#star').removeClass('starred');
            this.$('#star').addClass('unStarred');
        }
    },

    renderId: function () {
        this.$('#id').html(this.model.get('id'));
    },

    renderDateTime() {
        var dateTime = this.model.get('datetime');
        if (dateTime.length > 6) {
            var startPos = dateTime.length - 3;
            var amPm = dateTime.substring(startPos, startPos + 3);
            dateTime = dateTime.substring(1, dateTime.length - 6).concat(amPm);
        }
        this.$('#datetime').html(dateTime);
    },

    renderLogType: function () {
        this.$('#type').html(this.getLogTypeStringFromId(this.model.get('logTypeId')));
    },

    getLogTypeStringFromId: function (logTypeId) {
        switch (logTypeId) {
            case "1":
            case "2":
            case "3":
                return 'Profile';
            case "5":
            case "6":
                return 'Mail';
            case "7":
            case "8":
                return 'Package';
            case "9":
            case "10":
            case "11":
                return 'Event';
            case "13":
            case "14":
            case "15":
                return 'Away';
            case "20":
            case "21":
            case "22":
            case "23":
            case "24":
                return 'Checkin';
            case "100":
                return 'Info';
            case "101":
                return 'Incident';
            case "102":
                return 'Leak';
            case "103":
                return 'Conctractor';
            case "104":
                return 'Window washing';
            case "105":
                return 'Maintenance';
            default:
                return "";
        }
    },


    /////////////////////////////////////
    // Model changes
    /////////////////////////////////////
    changeIsHighlighted: function (searchLogMatch) {
        if (searchLogMatch == this.model) {
            if (this.model.get('isHighlighted') == true) {
                $(this.el).addClass('boxHighlight');
            }
            else {
                $(this.el).removeClass('boxHighlight');
            }
        }
    },

    changeIsSelected: function (searchLogMatch) {
        if (searchLogMatch == this.model) {
            Common.Router.navigate("#log/" + this.model.log.get('id'), { trigger: true });
        }
    },

    changedId: function () {
        this.renderId();
    },

    changedStar: function () {
        this.renderStar();
    },

    changedDatetime: function () {
        this.renderDateTime();
    },

    changedLogTypeId: function () {
        this.renderLogType();
    },

    changedComment: function () {
        this.$('#comment').html(this.model.get('comment'));
    },

    changeIsmatch: function () {
        if (this.model.get('isMatch') === true) {
            $(this.el).removeClass('hidden');
        }
        else {
            $(this.el).addClass('hidden');
        }
    },

    /////////////////////////////////////
    // User actions
    /////////////////////////////////////
    clickedEntry: function (e) {
        e.preventDefault();
        this.model.set('isSelected', true);
    },

    clickedStar: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.model.log.get('star') == false) {
            this.model.log.set('star', true);
        }
        else {
            this.model.log.set('star', false);
        }
        this.model.log.save();
    },

    requestHighlight: function () {
        this.model.set('isHighlighted', true);
    },

    releaseHighlight: function () {
        this.model.set('isHighlighted', false);
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});
Common.LogLineView = LogLineView;
module.exports = LogLineView;