﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var CondoPErsonLink = require('../common');

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        note: '',
        condoLabel: '',
        condoId: '',
        condoPersonLinkTypeId: ''
    },

    initialize: function (options) {
        this.set('condoPersonLinkTypeId', Common.CondoPersonLinkTypes_ownerId);
        if (options.condoPersonLink) {
            //Lookup condo based on data passed in
            this.setCondoId(options.condoPersonLink.get('condoId'));
            if (this.condo) {
                this.condoPersonLink = Common.CondoPersonLinks.findWhere({ id: options.condoPersonLink.id });
                this.set('condoPersonLinkTypeId', this.condoPersonLink.get('condoPersonLinkTypeId'));
                this.set('note', this.condoPersonLink.get('packageDeliveryPreference'));
            }
        }
        else {
            this.setCondoId();
        }
        this.person = options.person;
    },

    setCondoId: function (condoId) {
        this.condo = Common.Condos.findWhere({ id: condoId });
        if (this.condo) {
            this.set('condoLabel', this.condo.get('label'));
            this.set('condoId', this.condo.get('id'));
        }
        else {
            this.set('condoLabel', 'Add condo');
            this.set('condoId', '');
        }
    },

    save: function () {
        //Error handling
        if (!this.get('person')) {
            return;
        }

        //Save
        this.condoPersonLink.set('packageDeliveryPreference', this.get('note'));
        this.condoPersonLink.save(null, {
            success: function () {
                Common.Persons.fetch();
                Common.CondoPersonLinks.fetch();
            }
        });
    }
});