﻿'use strict';
var Backbone = require('backbone');
var Common = require('../common');
var Account = require('../models/account');

module.exports = Backbone.Collection.extend({
    model: Account,

    //Point to REST API page on server (need to implement GET,POST,PUT,DELETE)
    url: Common.apiFolder + 'account/index.php',

    initialize: function () {
        this.listenTo(this, 'change:firstname', this.changedFirstname);
        this.listenTo(this, 'change:lastname', this.changedLastname);
        this.listenTo(this, 'change:loggedIn', this.changedLoggedIn);
        this.listenTo(this, 'sync', this.synced);
    },

    changedFirstname: function (account) {
        account.createInitials();
        account.buildEmailSignature();
    },

    changedLastname: function (account) {
        account.createInitials();
        account.buildEmailSignature();
    },

    changedLoggedIn: function (account) {
        if (account.get('loggedIn') == true) {
            this.loggedIn(account);
        }
        else {
            if (Common.LoggedInUser && account.get('id') === Common.LoggedInUser.get('id')) {
                this.logout();
            }
        }
    },

    synced: function () {
        if (!Common.LoggedInUser) {
            var loggedInUser = this.findWhere({ loggedIn: true });
            if (!loggedInUser) {
                loggedInUser = this.findWhere({ loggedIn: "1" });
            }
            if (loggedInUser) {
                this.loggedIn(loggedInUser);
            }
        }
    },

    loggedIn: function (loggedInUser) {
        Common.dataSetupRedirector.resetBuilding();
        Common.LoggedInUser = loggedInUser;
        sessionStorage.setItem("csrfToken", loggedInUser.get('token'));

        //Keep session alive by polling server
        this.keepAliveCallback();
        var self = this;
        this.fetch({
            success: function () {
                Common.dataSetupRedirector.resetBuilding();
                Common.Buildings.fetch({
                    success: function () {
                        Common.Condos.fetch({
                            success: function () {
                                Common.Persons.fetch();
                                Common.CondoPersonLinks.fetch();
                            },
                        });
                    },
                });
            },
        });
    },

    logout: function () {
        var loggedInUser = this.findWhere({ loggedIn: true });
        if (!loggedInUser) {
            loggedInUser = this.findWhere({ loggedIn: "1" });
        }
        if (loggedInUser) {
            loggedInUser.save({ "token": "", "loggedIn": "0" });//setting token empty will log the user out on the server
        }

        if (Common.keepAliveTimer) {
            clearTimeout(Common.keepAliveTimer);
            Common.keepAliveTimer = null;
        }

        Common.dataSetupRedirector.resetBuilding();
        Common.LoggedInUser = null;
        sessionStorage.setItem("csrfToken", "");
        this.reset();
        Common.Buildings.reset();
        Common.Condos.reset();
        Common.Router.navigate("#login", { trigger: true });
    },

    keepAliveCallback: function () {
        var self = this;
        this.fetch();

        //Keep session alive by polling server
        Common.keepAliveTimer = setTimeout(function () {
            self.keepAliveCallback();
        }, 1000000);
    }
});