'use strict';
var Backbone = require('backbone');
var MainMenuEntry = require('../models/mainMenuEntry');

module.exports = Backbone.Collection.extend({
    model: MainMenuEntry,

    url: '',

    setSelected: function (tag) {
        this.models.forEach(function (mainMenuEntry) {
            if (mainMenuEntry.get('tag') == tag) {
                mainMenuEntry.set('isSelected', true);
            }
            else {
                mainMenuEntry.set('isSelected', false);
            }
        });
    }
});