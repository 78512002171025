﻿'use strict';
var Backbone = require('backbone');
var Phone = require('../models/phone');

module.exports = Backbone.Collection.extend({
    model: Phone,

    initialize: function () {
        this.listenTo(this, 'change:number', this.changedNumber);
    },

    changedNumber: function (phone) {
        var emptyEntries;
        if (phone.get('number')) {
            //Make sure we always have one free phone entry
            emptyEntries = this.filter(function (e) { return !e.get('number'); });
            if (emptyEntries.length == 0) {
                var phoneModel = new this.model();
                this.add(phoneModel);
            }
        }
        else {
            //If clearing out an phone entry, remove it if there are other empty entries
            emptyEntries = this.filter(function (e) { return !e.get('number'); });
            if (emptyEntries.length > 1) {
                this.remove(phone);
            }
        }
    }
});