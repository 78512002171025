﻿'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function (options) {
        console.log('Initializing PackageDeliveryPreferenceView=' + this.cid);

        this.isFirst = options.isFirst;
        this.isMultiCondo = options.isMultiCondo;
        this.isFirstMultiCondoEntry = options.isFirstMultiCondoEntry;

        this.listenTo(this.model, 'remove', this.destroy);
        this.listenTo(this.model.collection, 'reset', this.destroy);
    },

    events: {
        //User trigger: 
        "click #delete": "clickedDelete",
        "change #note": "userChangedNote"
    },


    /////////////////////////////////////
    // Render
    /////////////////////////////////////
    template: _.template($('#deliveryPreference-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template(this.model.attributes));
        var self = this;
        $(function () {
            if (self.model.get('isMultiCondo') == true) {
                self.$(".multiCondoHeader").removeClass('hidden');
                if (self.model.get('isFirstMultiCondoEntry') == false) {
                    self.$("#profileLineSeparator").removeClass('hidden');
                }
            }

            //Separator line: hidden always if first
            if (self.isFirst) {
                self.$('#profileLineSeparator').hide();
            }
        });
        return this;
    },


    /////////////////////////////////////
    // Userinput --> model
    /////////////////////////////////////
    userChangedNote: function () {
        this.model.set('note', this.$("#note").val());
    },


    /////////////////////////////////////
    // Delete
    /////////////////////////////////////
    clickedDelete: function (e) {
        e.preventDefault();
        this.$("#note").val('');
        this.model.set('note', '');
    },


    /////////////////////////////////////
    // Destroy
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});