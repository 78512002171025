'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing AccountView');

        this.listenTo(Common.Accounts, 'change:loggedIn', this.changedLoggedIn);
        this.listenTo(Common.Accounts, 'sync', this.accountsSync);

        //Events to open/close menu
        /* Anything that gets to the document
           will hide the dropdown */
        $(document).click(function () {
            $(".dropdown").fadeOut(100);
        });
    },
    accountAllEvents: function (e, a) {
        console.log("account: " + e);
    },
    events: {
        "click #logoutButton": "logout",
        "click #profileButton": "editAccount",
        "click #changePasswordButton": "changePasssword",
        "click #accountMenu": "openMenu",
        "click #trashButton": "clickedTrash"
    },

    //Cache the template function for a single item
    template: _.template($('#account-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        $(this.el).html(this.template());
        var self = this;
        $(function () {
            if (self.model) {
                self.renderName();
                $(self.el).show();
            }
            else {
                $(self.el).hide();
            }
        });
        return this;
    },

    renderName: function () {
        if (this.model) {
            this.$("#accountLabel").html(this.model.get('name'));
        }
    },

    changedLoggedIn: function (account) {
        this.renderOrHide();
    },

    changedName: function (m) {
        this.renderName();
    },

    accountsSync: function () {
        this.renderOrHide();
    },

    renderOrHide: function () {
        if (Common.LoggedInUser) {
            this.model = Common.LoggedInUser;
            this.listenTo(this.model, 'change:name', this.changedName);
            this.render();
        } else if (this.model) {
            this.stopListening(this.model);
            this.model = null;
            this.render();
        }
    },

    logout: function (e) {
        e.preventDefault();
        Common.Accounts.logout();
    },

    editAccount: function (e) {
        e.preventDefault();
        Common.Router.navigate("#editAccount", { trigger: true });
    },
    changePasssword: function (e) {
        e.preventDefault();
        Common.Router.navigate("#changePassword", { trigger: true });
    },

    openMenu: function (e) {
        if ($('#accountMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            $("#accountMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();

            //Position the menu under the owner label
            var inputElement = this.$("#accountMenu");
            var x = inputElement.offset().left + inputElement.width() - 10 - this.$("#accountMenuDropdown").width();
            var y = inputElement.offset().top + inputElement.height();
            this.$("#accountMenuDropdown").offset({ left: x, top: y });
        }
    },

    clickedTrash: function (e) {
        e.preventDefault();
        Common.Router.navigate("#trash", { trigger: true });
    },

    destroy: function () {
        if (this.model) {
            this.stopListening(this.model);
        }
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});