﻿'use strict';
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var Condo = require('../models/condo');
var CondoPersonLink = require('../models/condoPersonLink');
var Util = require('../util/util');

//USAGE:
//-------------------------
//This object maintain reference to 'CondoPersonLinks' collection of CondoPersonLink
//When this collection is updated, the 'Person' object will raise a 'reset' event for 'CondoPersonLink', followed by an 'Add' event for each newly added CondoPersonLink
//This means subscriber must clear UI/models when a 'reset' event is received and must add new UI/models for each 'add' event
//A collection of 'Condos' this user is associated with is maintained
//
//NOTE: it was too expensive in event overhead to maintain a list of Guests for each person. Hence that functionality is implemented in a separate collection 'Guests'
//
//Users should consider subscribing to the following events to guarantee correct data after creation of element
// - THIS.Condos: 'reset'
// - THIS.Condos: 'add'
// - THIS.Condos: 'remove'
// - THIS.CondoPersonLinks: 'reset'
// - THIS.CondoPersonLinks: 'add'
// - THIS.CondoPersonLinks: 'remove'
// - THIS.CondoPersonLink: 'change:condo'    (on individual condoPersonLink element)

module.exports = Backbone.Model.extend({
    //Default attributes 
    defaults: {
        title: '',
        firstname: '',
        lastname: '',
        middlename: '',
        company: '',
        alias: '',
        condoSearchString: '',
        emailsString: '[]',
        phonesString: '[]',
        photosString: '[]',
        awaysString: '[]',
        mailDeliveryPreference: '',
        packageDeliveryPreference: '',
        isEmpty: true,

        //Local properties
        emails: [],
        phones: [],
        photos: [],
        aways: [],

        isMultiCondo: false,
        isAlsoOwnerOrTenant: false,
        output: '', //Title+Lastname+Middle+LastName (formal notation)
        formal: ''  //Title+Lastname
    },

    urlRoot: Common.apiFolder + 'person/index.php',

    validation: {
        name: {
            required: true
        }
    },

    initialize: function () {
        this.CondoPersonLinkList = Backbone.Collection.extend({
            model: Common.CondoPersonLink,
            url: Common.apiFolder + 'condoPersonLink/index.php'
        });
        this.CondoList = Backbone.Collection.extend({
            model: Common.Condo,
            url: Common.apiFolder + 'condo/index.php'
        });
        this.CondoPersonLinks = new this.CondoPersonLinkList();
        this.Condos = new this.CondoList();

        this.syncedCondoPersonLinks();
        this.changedEmailsString();
        this.changedPhonesString();
        this.changedPhotosString();
        this.changedAwaysString();

        this.listenTo(Common.CondoPersonLinks, 'sync', this.syncedCondoPersonLinks);
        this.listenTo(Common.CondoPersonLinks, 'add', this.addedCondoPersonLink);
        this.listenTo(Common.CondoPersonLinks, 'remove', this.removedCondoPersonLink);

        this.listenTo(this, 'add', this.personAddOrSync);
        this.listenTo(this, 'sync', this.personAddOrSync);
        this.listenTo(this, 'change:emailsString', this.changedEmailsString);
        this.listenTo(this, 'change:phonesString', this.changedPhonesString);
        this.listenTo(this, 'change:photosString', this.changedPhotosString);
        this.listenTo(this, 'change:awaysString', this.changedAwaysString);
        this.listenTo(this, 'change:firstname', this.changedNameOrCompany);
        this.listenTo(this, 'change:lastname', this.changedNameOrCompany);
        this.listenTo(this, 'change:company', this.changedNameOrCompany);
        this.listenTo(this.CondoPersonLinks, "change:isOwnerOrTenant", this.recalcIsAlsoOwnerOrTenant);
    },

    getServerAttributes: function () {
        var attrs = _.clone(this.attributes);
        attrs = this.trimNonServerAttributes(attrs);
        return attrs;
    },

    trimNonServerAttributes: function (attrs) {
        delete attrs.Condos;
        delete attrs.CondoPersonLinks;
        return attrs;
    },

    personAddOrSync: function (e, a) {
        var title = this.get('title');
        var last = (this.get('lastname') == "") ? "" : " " + this.get('lastname');
        var first = (this.get('firstname') == "") ? "" : ", " + this.get('firstname');
        var middle = (this.get('middlename') == "") ? "" : " " + this.get('middlename');
        var company = (this.get('company') == "") ? "" : " - " + this.get('company');
        this.set('output', title + last + first + middle + company);
        this.set('formal', title + last + company);
        this.changedEmailsString();
        this.changedPhonesString();
        this.changedPhotosString();
        this.changedAwaysString();
    },

    changedNameOrCompany: function () {
        var isNameOrCompanySet = this.get('firstname') !== '' || this.get('lastname') !== '' || this.get('company') !== '';
        if (isNameOrCompanySet) {
            this.set('isEmpty', false);
        }
        else {
            this.set('isEmpty', true);
        }
    },

    getHostCondoPersonLink: function () {
        var ownersOrTenants = this.CondoPersonLinks.where({ isOwnerOrTenant: true });
        if (ownersOrTenants.length === 1) {
            return ownersOrTenants[0];
        }
        if (ownersOrTenants.length > 1) {
            var primary = this.CondoPersonLinks.findWhere({ isOwnerOrTenant: true, isPrimary: true });
            if (primary) {
                return primary;
            }
            return ownersOrTenants[0];
        }
        return null;
    },

    /////////////////////////////////////
    // Collection: CondoPersonLinks
    /////////////////////////////////////
    syncedCondoPersonLinks: function () {
        this.CondoPersonLinks.reset();
        this.Condos.reset();
        this.set('isMultiCondo', false);
        var self = this;
        Common.CondoPersonLinks.forEach(function (condoPersonLink) {
            self.addedCondoPersonLink(condoPersonLink);
        });
        this.recalcIsAlsoOwnerOrTenant();
    },

    addedCondoPersonLink: function (condoPersonLink) {
        if (condoPersonLink.get('personId') == this.id) {
            this.CondoPersonLinks.add(condoPersonLink);

            var condo = condoPersonLink.get('condo');
            if (condo) {
                this.tryAddCondo(condo);
            }
            else {
                this.listenTo(condoPersonLink, "change:condo", this.changedCondoOnCondoPersonLink);
            }
        }
    },

    removedCondoPersonLink: function (condoPersonLink) {
        if (condoPersonLink.get('personId') == this.id) {
            this.CondoPersonLinks.remove(condoPersonLink);

            var otherLinkForSameCondo = this.CondoPersonLinks.findWhere({ condoId: condoPersonLink.condoId });
            if (!otherLinkForSameCondo) {
                var condo = condoPersonLink.get('condo');
                if (condo) {
                    this.removeCondo(condo);
                }
            }
        }
    },

    changedCondoOnCondoPersonLink: function (condoPersonLink, condo) {
        if (this.CondoPersonLinks.findWhere({ id: condoPersonLink.id })) {
            this.tryAddCondo(condo);
        }
    },

    recalcIsAlsoOwnerOrTenant: function () {
        this.set('isAlsoOwnerOrTenant', this.CondoPersonLinks.length === 0 || this.CondoPersonLinks.where({ isOwnerOrTenant: true }).length > 0);
    },

    /////////////////////////////////////
    // Collection: Condos
    /////////////////////////////////////
    tryAddCondo: function (condo) {
        var existingCondo = this.Condos.findWhere({ id: condo.id });
        if (!existingCondo) {
            this.addCondo(condo);
        }
    },

    addCondo: function (condo) {
        var self = this;
        this.Condos.add(condo);
        this.listenTo(condo, "change:label", this.changedCondoLabel);
        this.recalcCondoSearchString();
        this.set('isMultiCondo', this.Condos.length > 1);
    },

    removeCondo: function (condo) {
        this.Condos.remove(condo);
        this.stopListening(condo);
        this.recalcCondoSearchString();
        this.set('isMultiCondo', this.Condos.length > 1);
    },

    changedCondoLabel: function (condo, label) {
        this.recalcCondoSearchString();
    },


    /////////////////////////////////////
    // Helpers
    /////////////////////////////////////
    recalcCondoSearchString: function () {
        var str = '';
        this.CondoPersonLinks.forEach(function (condoPersonLink) {
            var condo = Common.Condos.findWhere({ id: condoPersonLink.get('condoId') });
            if (condo) {
                if (str !== '') {
                    str += ", ";
                }
                str += condo.get('label');
            }
        });
        this.set('condoSearchString', str);
    },

    changedEmailsString: function () {
        var emailString = this.get('emailsString');
        if (!emailString) {
            emailString = "[]";
        }
        this.set('emails', JSON.parse(emailString));
    },

    changedPhonesString: function () {
        var phoneString = this.get('phonesString');
        if (!phoneString) {
            phoneString = "[]";
        }
        this.set('phones', JSON.parse(phoneString));
    },

    //Call this ONLY when adding a photo that is already on the server
    addPhoto: function (photoId) {
        var photoObj = [{ 'path': photoId }];
        var photosString = JSON.stringify(photoObj);
        this.set('photosString', photosString);
    },

    changedPhotosString: function () {
        var photoString = this.get('photosString');
        if (!photoString) {
            photoString = "[]";
        }
        this.set('photos', JSON.parse(photoString));
    },

    changedAwaysString: function () {
        var awayString = this.get('awaysString');
        if (!awayString) {
            awayString = "[]";
        }
        var aways = JSON.parse(awayString);
        this.set('aways', aways);
    },


    /////////////////////////////////////
    // Return HTML
    /////////////////////////////////////
    getFormattedEmails: function () {
        //Return HTML formatted links broken into lines, and with links for email
        var result = '';
        var self = this;
        var emails = this.get('emails');
        if (Array.isArray(emails)) {
            emails.forEach(function (email) {
                if (result != '') {
                    result += "<br>";
                }
                result += "<a href='mailto:" + email.email + "'>" + email.email + "</a>";
                if (email.comment) {
                    result += " (" + email.comment + ")";
                }
            });
        }
        return result;
    },

    getFormattedPhones: function () {
        //Return HTML formatted links broken into lines, and with linksto phone number
        var result = '';
        var self = this;
        var phones = this.get('phones');
        if (Array.isArray(phones)) {
            phones.forEach(function (phone) {
                if (result != '') {
                    result += "<br>";
                }
                result += "<a href='tel:" + phone.number + "'>" + phone.number + "</a>";
                if (phone.comment) {
                    result += " (" + phone.comment + ")";
                }
            });
        }
        return result;
    },

    getFormattedPhotos: function () {
        //Return HTML formatted photos 
        var result = '';
        var self = this;
        var photos = this.get('photos');
        if (Array.isArray(photos)) {
            photos.forEach(function (photo) {
                result += "<img class='personProfileImg' src='" + Common.apiFolder + "person/" + photo.path + "' />";
            });
            if (!result) {
                result = "<img class='personProfileImg imgProfilePlaceholder' />";
            }
        }
        return result;
    },

    getFormattedAways: function () {
        //Return HTML formatted photos 
        var result = '';
        var self = this;
        var aways = this.get('aways');
        if (Array.isArray(aways)) {
            aways.forEach(function (away) {
                if (result) {
                    result += "<hr id='profileLineSeparator'>";
                }
                result += "<div class='personProfilePeriod'>" + away.leave + " - " + away.back + "</div>";
                if (away.comment) {
                    result += "<div class='personProfileComment'>" + Util.jsonToBr(away.comment) + "</div>";
                }
            });
        }
        return result;
    },

    getFormattedNotes: function (key) {
        var result = '';
        var self = this;
        var multiCondo = false;
        this.CondoPersonLinks.forEach(function (condoPersonLink) {
            var value = '';
            if (key == 'mailDeliveryPreference') {
                value = condoPersonLink.get('mailDeliveryPreference');
            }
            else if (key == 'packageDeliveryPreference') {
                value = condoPersonLink.get('packageDeliveryPreference');
            }
            else if (key == 'comment') {
                value = condoPersonLink.get('comment');
            }
            if (value) {
                if (result != '') {
                    result += "<div class='clear'/><hr id='profileLineSeparator' class='doubleTopMargin'>";
                    multiCondo = true;
                }
                result += "<div class='multiCondoHeader hidden'>" + condoPersonLink.get('label') + "</div><div>" + Util.jsonToBr(value) + "</div>";
            }
        });
        if (multiCondo == true) {
            result = result.replace(/'multiCondoHeader hidden'/g, "'multiCondoHeader'");
        }
        return result;
    },

    getFormattedPeriods: function () {
        var result = '';
        var self = this;
        var multiCondo = false;
        this.CondoPersonLinks.forEach(function (condoPersonLink) {
            if (condoPersonLink.get('startDate') || condoPersonLink.get('endDate')) {
                if (result) {
                    result += "<hr id='profileLineSeparator'>";
                    multiCondo = true;
                }
                result += "<div class='multiCondoHeader hidden'>" + condoPersonLink.get('label') + "</div><div>" + condoPersonLink.get('startDate') + " - " + condoPersonLink.get('endDate') + "</div>";
            }
        });
        if (multiCondo == true) {
            result = result.replace(/'multiCondoHeader hidden'/g, "'multiCondoHeader'");
        }
        return result;
    },


    /////////////////////////////////////
    // Create & save
    /////////////////////////////////////
    create: function (successCallback, errorCallback, caller) {
        var self = this;
        Common.Persons.add(this);
        this.save(null, {
            success: function () {
                if (successCallback) {
                    successCallback(self, caller);
                }
            },
            error: function () {
                if (errorCallback) {
                    errorCallback(self, caller);
                }
            }
        });
    },

    save: function (attrs, options) {
        options || (options = {});
        attrs || (attrs = _.clone(this.attributes));

        // Filter the data to send to the server (remove the objects created for internal logic)
        attrs = this.trimNonServerAttributes(attrs);

        options.data = JSON.stringify(attrs);

        // Proxy the call to the original save function
        return Backbone.Model.prototype.save.call(this, attrs, options);
    }
});