﻿'use strict';
var Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    defaults: {
        deleteMessage: ''
    },

    initialize: function (options) {
        this.host = options.host;
        this.set('deleteMessage', options.deleteMessage);
    },

    allowDeletion: function () {
        this.host.delete();
    }
});