'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing SearchAccessView');

        this.listenTo(Common.SearchPersonExMatches, 'change:isSelected', this.changeMatchIsSelected);

        //Get fresh person data to search from
        Common.Persons.fetch();
        Common.CondoPersonLinks.fetch();

        //Set menu
        Common.MainMenuEntries.setSelected('access');
    },

    events: {
        "focus input": "inputFocus",
    },


    /////////////////////////////////////
    // Rendering
    /////////////////////////////////////
    template: _.template($('#searchAccess-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),
    render: function () {
        $(this.el).html(this.template());

        var self = this;
        $(function () {
            $('#pageTitle').html('Lookup guest / resident');
            self.activate();
        });
        return this;
    },

    activate: function () {
        var self = this;
        self.$('input').focus();
        setTimeout(function () {
            self.$('input').focus();
        }, 200);
    },

    /////////////////////////////////////
    // Model events
    /////////////////////////////////////
    changeMatchIsSelected: function (searchPersonExMatch) {
        if (searchPersonExMatch.get('isSelected') == true) {

            //We only need the trigger, so the event is reset
            searchPersonExMatch.set('isSelected', false);

            //Close the search panel
            if (Common.searchPersonView) {
                Common.searchPersonView.unload('0');
            }

            //Load profile for selection
            //TODO
        }
    },


    /////////////////////////////////////
    // User events on view
    /////////////////////////////////////
    inputFocus: function (eventName, args) {
        //Make sure we work on fresh data
        Common.searchPersonView.reloadDataFromServer();

        //Direct search result to follow this input field
        this.waitForSearchPersonView();
    },

    waitForSearchPersonView() {
        var condoPersonLinkTypeIds = [Common.CondoPersonLinkTypes_ownerId, Common.CondoPersonLinkTypes_tenantId, Common.CondoPersonLinkTypes_guestId, Common.CondoPersonLinkTypes_staffId, Common.CondoPersonLinkTypes_formerResidentId, Common.CondoPersonLinkTypes_deceasedId];
        if (Common.searchPersonView) {
            Common.searchPersonView.load(this, '0', 'PersonProfileLineView', '#personProfileLine-list', condoPersonLinkTypeIds);
        }
        else {
            setTimeout(this.waitForSearchPersonView, 200);
        }

    },


    /////////////////////////////////////
    // Disposal
    /////////////////////////////////////
    destroy: function () {
        this.remove();
        if (Common.searchPersonView) {
            Common.searchPersonView.unload('0');
        }

        // Remove the validation binding
        // See: http://thedersen.com/projects/backbone-validation/#using-form-model-validation/unbinding
        Backbone.Validation.unbind(this);
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});