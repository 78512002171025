'use strict';
var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');
var Common = require('../common');
var BuildingMenuEntryView = require('../views/buildingMenuEntryView');

module.exports = Backbone.View.extend({

    initialize: function () {
        console.log('Initializing BuildingView');

        this.listenTo(Common.Buildings, 'change:isActive', this.render);
        this.listenTo(Common.Buildings, 'change:name', this.renderBuildingName);
        this.listenTo(Common.Buildings, 'sync', this.render);
        this.listenTo(Common.Buildings, 'reset', this.render);
        this.listenTo(Common.Accounts, 'change:loggedIn', this.changedLoggedIn);
        this.listenTo(Common.Accounts, 'sync', this.accountsSynced);

        //Events to open/close menu
        /* Anything that gets to the document
           will hide the dropdown */
        $(document).click(function () {
            $(".dropdown").fadeOut(100);
        });
    },

    events: {
        "click #buildingMenu": "openMenu",
        "click #editButton": "editBuilding",
        "click #addButton": "addBuilding",
        "click #trashButton": "clickedTrash"
    },

    //Cache the template function for a single item
    template: _.template($('#building-template').html(), { interpolate: /\{\{(.+?)\}\}/g }),

    render: function () {
        var self = this;
        $(function () {
            var activeBuilding = Common.Buildings.findWhere({ "isActive": true });
            if (activeBuilding) {
                if (self.model != activeBuilding) {
                    $(self.el).html(self.template());
                    self.model = activeBuilding;
                    self.renderBuildingName();

                    //Add each entry
                    self.renderBuildingList();
                    self.listenTo(Common.Buildings, "add", self.renderBuildingList);
                }
                $(self.el).show();
            }
            else {
                $(self.el).hide();
            }
            self.renderMenuItemsTiedToLoggedInUser();
        });
        return this;
    },

    renderBuildingList: function () {
        var self = this;
        this.$('.selectBuildingMenu-list').empty();
        if (Common.Buildings.models.length > 1) {
            Common.Buildings.models.forEach(function (building) {
                var view = new BuildingMenuEntryView({ model: building });
                self.$('.selectBuildingMenu-list').append(view.render().el);

            });
            this.$('#menuDivider').removeClass('hidden');
        }
        else {
            this.$('#menuDivider').addClass('hidden');
        }
    },

    renderMenuItemsTiedToLoggedInUser: function () {
        //Show options only available to property manager accounts (users who created the account them selves)
        if (Common.LoggedInUser && Common.LoggedInUser.get('accountTypeId') == Common.Accounts_propertyManagerId) {
            this.$('#propertyManagerAccountFeatures').removeClass('hidden');
        }
        else {
            this.$('#propertyManagerAccountFeatures').addClass('hidden');
        }

        //Show options only available to property managers of THIS building (users who have been given PropertyManager permissions for this building)
        var activeBuilding = Common.Buildings.getActive();
        if (activeBuilding && activeBuilding.get('accountTypeId') == Common.Accounts_propertyManagerId) {
            this.$('#propertyManagerBuildingFeatures').removeClass('hidden');
        }
        else {
            this.$('#propertyManagerBuildingFeatures').addClass('hidden');
        }
    },

    renderBuildingName: function () {
        this.$("#buildingLabel").html(this.model.get('name'));
    },

    //Building menu will be used to select building.
    openMenu: function (e) {
        if ($('#buildingMenuDropdown:visible').length == 0) {
            $(".dropdown").fadeOut(100);
            $("#buildingMenuDropdown").fadeIn(100);
            e.preventDefault();
            e.stopPropagation();

            //Position the menu under the owner label
            var inputElement = this.$("#buildingMenu");
            var x = inputElement.offset().left + inputElement.width() - 10 - this.$("#buildingMenuDropdown").width();
            var y = inputElement.offset().top + inputElement.height();
            this.$("#buildingMenuDropdown").offset({ left: x, top: y });
        }
    },

    editBuilding: function (e) {
        e.preventDefault();
        Common.Router.navigate("#editBuilding", { trigger: true });
    },

    addBuilding: function (e) {
        e.preventDefault();
        Common.Router.navigate("#createBuilding", { trigger: true });
    },

    changedLoggedIn: function (e, a) {
        this.renderMenuItemsTiedToLoggedInUser();
    },

    accountsSynced: function () {
        this.renderMenuItemsTiedToLoggedInUser();
    },

    clickedTrash: function (e) {
        e.preventDefault();
        Common.Router.navigate("#trash", { trigger: true });
    },

    destroy: function () {
        this.remove();
        return Backbone.View.prototype.remove.apply(this, arguments);
    }
});